import {LocalizedTextDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {CoiCreateIncidentLocationActionTypes, StationWithMainLocationDto} from '../reducer';
import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentLocationProps} from '../coiCreateIncidentLocation';
import {CoiAssignCoiButton, IOSSwitch} from '../../common';
import {DeleteOutline} from '@mui/icons-material';
import {Box, IconButton, Tooltip} from '@mui/material';
import {CoiTargetType, TargetType} from '../coiAssignModal';
import DeleteIconTwo from 'src/icons/DeleteIconTwo';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

export const PtCarElement = ({
  props,
  handleCoiAssignModalTarget,
}: {
  props: CoiCreateIncidentLocationProps;
  handleCoiAssignModalTarget: (target: CoiTargetType | undefined, targetIndex: number) => void;
}) => {
  const {i18n} = useTranslation();

  const fullName = handleTranslationObject(
    i18n,
    props.coiCreateIncidentLocationState.ptcar?.name as LocalizedTextDto[],
  );
  const truncatedName = fullName.length > 8 ? `${fullName.substring(0, 8)}...` : fullName;
  return (
    <Box
      sx={{
        padding: '10px',
        paddingLeft: '15px',
        display: 'grid',
        gridTemplateColumns: 'repeat(2,15%) repeat(2, 160px) auto',
        gap: '10px',
        backgroundColor: 'white',
        marginTop: '10px',
        marginBottom: '10px',
        minHeight: '48px',
        position: 'relative',
        border: 'solid 1px rgba(207, 216, 220, 1)',
        borderRadius: '4px',
        '&::before': {
          content: '""',
          width: '5px',
          borderRadius: '0px 10px 10px 0px',
          backgroundColor: 'rgba(0, 59, 140, 1)',
          position: 'absolute',
          left: '0',
          height: '36px',
          top: '12px',
          visibility: props.coiCreateIncidentLocationState.ptcar?.mainLocation ? 'visible' : 'hidden',
        },
      }}
    >
      <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
        <IOSSwitch
          checked={props.coiCreateIncidentLocationState.ptcar?.mainLocation}
          onChange={() => {
            props.coiCreateIncidentLocationDispatch({
              type: CoiCreateIncidentLocationActionTypes.CHANGE_MAIN_LOCATION,
              payload: props.coiCreateIncidentLocationState.trains.length,
            });
          }}
        />
      </div>
      <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
        <Tooltip title={fullName}>
          <p style={{whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100px', margin: 0}}>{truncatedName}</p>
        </Tooltip>
      </div>
      <div style={{display: 'grid', justifyItems: 'start', alignItems: 'center'}}>
        <p style={{whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100px', margin: 0}}>
          {props.coiCreateIncidentLocationState.ptcar?.numberOfPlatforms}
        </p>
      </div>
      <div style={{display: 'grid', justifyItems: 'end', alignItems: 'center'}}></div>
      <div style={{display: 'grid', justifyItems: 'end', alignItems: 'center'}}>
        <div style={{display: 'flex', justifyContent: 'flex-end', gap: '24px'}}>
          <CoiAssignCoiButton
            onClick={() => {
              handleCoiAssignModalTarget(
                {
                  type: TargetType.PT_CAR,
                  payload: props.coiCreateIncidentLocationState.ptcar as StationWithMainLocationDto,
                },
                0,
              );
            }}
          >
            {props.coiCreateIncidentLocationState.ptcar?.assignedCoi === undefined ? 'Assign COI' : 'Edit COI'}
          </CoiAssignCoiButton>
          <IconButton
            onClick={() => {
              props.coiCreateIncidentLocationDispatch({
                type: CoiCreateIncidentLocationActionTypes.REMOVE_PTCAR,
              });
            }}
          >
            <DeleteIconTwo />{' '}
          </IconButton>
        </div>
      </div>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        {props.coiCreateIncidentLocationState.ptcar && props.coiCreateIncidentLocationState.ptcar.assignedCoi && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1,15%) auto 1fr',
              padding: '10px',
              paddingLeft: '15px',
              gap: '10px',
              minHeight: '48px',
            }}
          >
            <Box></Box>
            <Box>
              <PersonIcon />{' '}
              {props.coiCreateIncidentLocationState.ptcar &&
                props.coiCreateIncidentLocationState.ptcar.assignedCoi.name}
            </Box>
            <div style={{display: 'flex', justifyContent: 'flex-end', gap: '24px'}}>
              <span style={{display: 'flex', gap: '5px'}}>
                <LocalPhoneOutlinedIcon color={'info'} />
                <a
                  href={`dica:${
                    props.coiCreateIncidentLocationState.ptcar &&
                    props.coiCreateIncidentLocationState.ptcar.assignedCoi.phoneNumber?.replace('+', '000')
                  }`}
                  style={{justifySelf: 'end', textDecoration: 'none'}}
                >
                  {props.coiCreateIncidentLocationState.ptcar &&
                    props.coiCreateIncidentLocationState.ptcar.assignedCoi.phoneNumber}
                </a>
              </span>
            </div>
          </Box>
        )}
        {props.coiCreateIncidentLocationState.ptcar &&
          props.coiCreateIncidentLocationState.ptcar.assistants &&
          props.coiCreateIncidentLocationState.ptcar &&
          props.coiCreateIncidentLocationState.ptcar.assistants.length > 0 &&
          props.coiCreateIncidentLocationState.ptcar &&
          props.coiCreateIncidentLocationState.ptcar.assistants.map(el => (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(1,15%) auto 1fr',
                padding: '10px',
                paddingLeft: '15px',
                gap: '10px',
                minHeight: '48px',
              }}
            >
              <Box></Box>
              <Box>
                <PeopleAltOutlinedIcon /> {el.name}
              </Box>
              <div style={{display: 'flex', justifyContent: 'flex-end', gap: '24px'}}>
                <span style={{display: 'flex', gap: '5px'}}>
                  <LocalPhoneOutlinedIcon color={'info'} />
                  <a
                    href={`dica:${el.phoneNumber?.replace('+', '000')}`}
                    style={{justifySelf: 'end', textDecoration: 'none'}}
                  >
                    {el.phoneNumber}
                  </a>
                </span>
              </div>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
