import {CoiBg} from '../../../coiBG';
import {useTranslation} from 'react-i18next';

export const ShowEmptyState = () => {
  const {t} = useTranslation();

  return (
    <div style={{height: '220px', display: 'grid', placeItems: 'center'}}>
      <div style={{display: 'grid', placeItems: 'center', gap: '18px'}}>
        <CoiBg />
        <p>{t('coi.add-location-information')}</p>
      </div>
    </div>
  );
};
