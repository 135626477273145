import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Paragraph from 'antd/lib/typography/Paragraph';
import Button from '@mui/material/Button'





export const TimeOutDialog: React.FC<{open: boolean, close: React.Dispatch<boolean>}> = ({open, close}) => {
    return(

        <Dialog
            open={open}
            data-testid='time-out-snackbar'
        >
            <div style={{display: 'grid', gridTemplateRows: 'auto 1fr auto', padding: '8px'}}>
                <DialogTitle style={{padding: '8px'}}>Server error response 404</DialogTitle>
                <Paragraph
                    style={{padding: '8px'}}
                >
                    The operation could not be completed in time.

                    Please check your network connection, or try again later.
                </Paragraph>
                <Button data-testid='close-button' variant="contained" onClick={() => close(false)}>Close</Button>
            </div>
        </Dialog>
    )
}