import {ReasonDto} from 'src/services/models';
import {cachePaths} from './cachePaths';
import {TaxiDto} from 'src/services/models/taxi-dto';

export const setToLocal = (res: object[]) => {
  localStorage.setItem(cachePaths.reasons.path, JSON.stringify(res[0]));
  localStorage.setItem(cachePaths.districts.path, JSON.stringify(res[1]));
  localStorage.setItem(cachePaths.stations.path, JSON.stringify(res[2]));
  localStorage.setItem(cachePaths.disabilities.path, JSON.stringify(res[3]));
  localStorage.setItem(cachePaths.age_categories.path, JSON.stringify(res[4]));
  localStorage.setItem(cachePaths.reasons_reservations.path, JSON.stringify(res[5]));
  localStorage.setItem(cachePaths.taxi_companies.path, JSON.stringify(res[6]));
};

export const getCachedData = (
  setReasons: React.Dispatch<React.SetStateAction<{}>>,
  setDistricts: React.Dispatch<React.SetStateAction<{}>>,
  setStations: React.Dispatch<React.SetStateAction<{}>>,
  setDisabilities: React.Dispatch<React.SetStateAction<{}>>,
  setAgeCategories: React.Dispatch<React.SetStateAction<{}>>,
  setReservationRefusalReasons: React.Dispatch<React.SetStateAction<ReasonDto[]>>,
  setTaxiCompanies: React.Dispatch<React.SetStateAction<TaxiDto[]>>,
) => {
  setReasons(JSON.parse(localStorage.getItem(cachePaths.reasons.path) || JSON.stringify({})).data);
  setDistricts(JSON.parse(localStorage.getItem(cachePaths.districts.path) || JSON.stringify({})).data);
  setStations(JSON.parse(localStorage.getItem(cachePaths.stations.path) || JSON.stringify({})).data);
  setDisabilities(JSON.parse(localStorage.getItem(cachePaths.disabilities.path) || JSON.stringify({})).data);
  setAgeCategories(JSON.parse(localStorage.getItem(cachePaths.age_categories.path) || JSON.stringify({})).data);
  setReservationRefusalReasons(
    JSON.parse(localStorage.getItem(cachePaths.reasons_reservations.path) || JSON.stringify({})).data,
  );
  setTaxiCompanies(JSON.parse(localStorage.getItem(cachePaths.taxi_companies.path) || JSON.stringify({})).data);
};
