import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentStore} from '../../coi.createIncident';
import {CoiCreateIncidentInformationActionTypes} from './reducer';
import {Chip, FormControl, InputLabel, MenuItem, Select, SxProps, TextareaAutosize, Theme} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {IncidentType} from '../../coiTypes';
import {useCoiCreateIncidentContext} from 'src/context/coi/coi.createIncident.store';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {coiCreateIncidentInformationDisabledValidations} from '../../coiUtils/coiCreateIncidentInformation';
import {useMemo} from 'react';
import {IncidentTypeDto} from 'src/services/models/incident-type-dto';

export interface CoiCreateIncidentInformationProps extends CoiCreateIncidentStore {}

const chipStyle: SxProps<Theme> = {
  border: 'solid 1px darkgrey',
  borderRadius: '5px',
  fontWeight: 'bold',
};

const selectedChipStyle: SxProps<Theme> = {
  ...chipStyle,
  border: 'solid 1px white',
  backgroundColor: '#EFF4F9',
};

export const CoiCreateIncidentInformation = ({
  coiCreateIncidentInformationState,
  coiCreateIncidentInformationDispatch,
}: CoiCreateIncidentInformationProps) => {
  const {t, i18n} = useTranslation();

  const coiStore = useCoiCreateIncidentContext();

  //Menu Items
  // Catalog ---

  const getCatalogItems = useMemo(() => {
    return coiStore.referenceData
      .filter(d => d.type === coiCreateIncidentInformationState.type)
      .map(d => (
        <MenuItem key={d.id} value={d.id}>
          {handleTranslationObject(i18n, d.names)}
        </MenuItem>
      ));
  }, [coiStore.referenceData, coiCreateIncidentInformationState.type, i18n]);

  // -----------

  // Subcatalog ---

  const getSubCatalogItems = useMemo(() => {
    const selectedCatalog = coiStore.referenceData.find(d => d.id === coiCreateIncidentInformationState.catalogId);
    return selectedCatalog?.subCatalogs.map(d => (
      <MenuItem key={d.id} value={d.id}>
        {handleTranslationObject(i18n, d.names)}
      </MenuItem>
    ));
  }, [coiStore.referenceData, coiCreateIncidentInformationState.catalogId, i18n]);

  //---------------

  // IncindentTypes

  const getIncidentTypes = useMemo(() => {
    return Object.keys(IncidentTypeDto).map(el => (
      <Chip
        key={el}
        variant="outlined"
        sx={coiCreateIncidentInformationState.type === el ? {...selectedChipStyle} : {...chipStyle}}
        onClick={() => {
          coiCreateIncidentInformationDispatch({
            type: CoiCreateIncidentInformationActionTypes.TYPE,
            payload: el as IncidentTypeDto,
          });
        }}
        label={el.toLocaleLowerCase()}
        icon={coiCreateIncidentInformationState.type === el ? <CheckIcon /> : <></>}
      />
    ));
  }, [coiCreateIncidentInformationState.type]);

  return (
    <div
      style={{
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <p style={{margin: '0', fontWeight: 'bold', fontSize: '22px', marginBottom: '12px'}}>
        {t('coi.incident-information')}
      </p>
      <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '12px', marginBottom: '12px'}}>
        <p style={{margin: '0', marginBottom: '12px'}}>{t('coi.incident-type')} :</p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${Object.keys(IncidentTypeDto).length}, 1fr)`,
            gap: '4px',
          }}
        >
          {getIncidentTypes}
        </div>
      </div>
      <FormControl fullWidth sx={{marginBottom: '12px'}}>
        <InputLabel id="incident-catalog">{t('coi.catalog')}</InputLabel>
        <Select
          labelId="incident-catalog"
          label={t('coi.catalog')}
          value={coiCreateIncidentInformationState.catalogId}
          error={
            coiCreateIncidentInformationState.catalogId.length === 0 &&
            !coiCreateIncidentInformationDisabledValidations.catalog(coiStore, coiCreateIncidentInformationState)
          }
          disabled={coiCreateIncidentInformationDisabledValidations.catalog(
            coiStore,
            coiCreateIncidentInformationState,
          )}
          onChange={e => {
            coiCreateIncidentInformationDispatch({
              type: CoiCreateIncidentInformationActionTypes.CATALOG,
              payload: e.target.value,
            });
          }}
        >
          {getCatalogItems}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{marginBottom: '12px'}}>
        <InputLabel id="incident-subcatalog">{t('coi.subcatalog')}</InputLabel>
        <Select
          labelId="incident-subcatalog"
          value={coiCreateIncidentInformationState.subCatalogId}
          label={t('coi.subcatalog')}
          error={
            coiCreateIncidentInformationState.subCatalogId.length === 0 &&
            !coiCreateIncidentInformationDisabledValidations.subCatalog(coiStore, coiCreateIncidentInformationState)
          }
          disabled={coiCreateIncidentInformationDisabledValidations.subCatalog(
            coiStore,
            coiCreateIncidentInformationState,
          )}
          onChange={e => {
            coiCreateIncidentInformationDispatch({
              type: CoiCreateIncidentInformationActionTypes.SUBCATALOG,
              payload: e.target.value,
            });
          }}
        >
          {getSubCatalogItems}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextareaAutosize
          required={coiCreateIncidentInformationState.type === IncidentTypeDto.Other}
          style={{
            minHeight: '200px',
            borderRadius: '4px',
            padding: '8px',
            borderColor: `${
              coiCreateIncidentInformationState.type === IncidentTypeDto.Other &&
              coiCreateIncidentInformationState.extraInfo.length <= 0
                ? 'red'
                : 'lightgrey'
            }`,
          }}
          placeholder={t('coi.extra-info')}
          onChange={e =>
            coiCreateIncidentInformationDispatch({
              type: CoiCreateIncidentInformationActionTypes.EXTRA_INFO,
              payload: e.target.value,
            })
          }
        />
      </FormControl>
    </div>
  );
};
