import {Box, CircularProgress} from '@mui/material';
import React, {useState, useContext, useEffect} from 'react';
import {IncidentDetailsDto} from 'src/services/models/incident-details-dto';
import useDebounce from 'src/hooks/useDebounce';
import useHandleCall from 'src/hooks/useHandleCall';
import {ChatMessageDtoPaginatedListResponseDto} from 'src/services/models/chat-message-dto-paginated-list-response-dto';
import {getChats} from '../../data';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {ChatMessagesList} from './chatMessageList';
import {ChatSearch} from './coiChatSearch';
import {ChatMessageDto} from 'src/services/models/chat-message-dto';

export enum MessageFilter {
  ALL = 'Show all messages',
  AUTO = 'Only automatic messages',
}

export const CoiChatView: React.FC<{incident: IncidentDetailsDto | undefined}> = ({incident}) => {
  if (!incident) {
    return <p>No Incident</p>;
  }

  const {variables} = useContext(EnvironmentContext);

  // Fetch
  const {executeCall, errorCall, loadingCall} = useHandleCall<ChatMessageDtoPaginatedListResponseDto>();

  // Search Input
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  useEffect(() => {
    handleNewSearch();
  }, [debouncedSearchValue]);

  // Filter
  const [selectedOption, setSelectedOption] = useState<MessageFilter>(MessageFilter.ALL);

  const handleFilterChange = async (option: MessageFilter) => {
    setSelectedOption(option);
    await fetchChatMessages(false, searchValue, option === MessageFilter.AUTO);
  };

  const handleNewSearch = async () => {
    await fetchChatMessages(false, searchValue, selectedOption === MessageFilter.AUTO);
  };

  const [chats, setChats] = useState<ChatMessageDtoPaginatedListResponseDto | null>(null);

  const fetchChatMessages = async (useMoreResults: boolean, filter: string, autoGenerated: boolean) => {
    if (incident.id) {
      const token = useMoreResults && chats?.moreResultsToken;
      const messages = await executeCall(
        getChats(variables.BASE_ENDPOINT, {
          autoGenerated: autoGenerated ? autoGenerated : null,
          incidentId: incident.id,
          moreResultsToken: token ? token : null,
          searchQuery: filter,
        }),
      );
      if (messages && messages.totalItems === chats?.totalItems) {
        return;
      }
      if (useMoreResults) {
        //Append
        return messages;
      } else {
        //Set first ones
        setChats(messages);
      }
    }
  };

  const appendMoreResults = async () => {
    const newChats: ChatMessageDtoPaginatedListResponseDto | null | undefined = await fetchChatMessages(
      true,
      searchValue,
      selectedOption === MessageFilter.AUTO,
    );
    const newMoreResultsToken = newChats?.moreResultsToken ? newChats.moreResultsToken : null;

    if (newChats && chats && newChats.items) {
      setChats(prevChats => ({
        ...prevChats,
        items: [...(prevChats?.items || []), ...(newChats.items as ChatMessageDto[])],
        moreResultsToken: newMoreResultsToken,
        totalItems: (prevChats?.totalItems || 0) + (newChats?.totalItems || 0),
      }));
    }
  };

  useEffect(() => {
    fetchChatMessages(false, searchValue, selectedOption === MessageFilter.AUTO);
  }, [incident.id]);

  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      handleNewSearch();
      const interval = setInterval(() => {
        handleNewSearch();
      }, 10000);
      return () => clearInterval(interval);
    }, 10000);

    return () => clearTimeout(initialTimeout);
  }, [incident.id]);

  return (
    <Box sx={{display: 'grid', gridTemplateRows: '40px 1fr', padding: 0, height: '100%', gap: '20px'}}>
      <Box
        sx={{
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          borderTop: 'solid 1px #a7cae4',
          borderBottom: 'solid 1px #a7cae4',
          borderRadius: '5px',
        }}
      >
        <ChatSearch
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          selectedOption={selectedOption}
          onFilterChange={handleFilterChange}
        />
      </Box>
      {loadingCall && chats?.items?.length === 0 && (
        <Box
          sx={{
            border: 'solid 1px #a7cae4',
            display: 'grid',
            placeItems: 'center',
            height: '100%',
            maxHeight: '70vh',
            borderRadius: '5px',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {errorCall && (
        <Box
          sx={{
            border: 'solid 1px #a7cae4',
            display: 'grid',
            placeItems: 'center',
            height: '100%',
            maxHeight: '70vh',
            borderRadius: '5px',
          }}
        >
          <b>An error occurred while fetching chats</b>
        </Box>
      )}
      {chats && (
        <ChatMessagesList
          chats={chats}
          appendMoreResults={appendMoreResults}
          incident={incident}
          handleNewSearch={handleNewSearch}
        />
      )}
    </Box>
  );
};
