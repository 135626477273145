import * as React from 'react';
import moment from 'moment';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {EnvironmentContext} from '../../../context/environment/environment.store';
import getMissions from 'src/services/GET/getMissions';
import {AxiosError} from 'axios';
import {NetworkContext} from '../../../context/network/network.store';
import {ModelError, ReservationTypeDto} from 'src/services/models';
import getMissionDetails from 'src/services/GET/getMissionDetails';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';
import {SettingsContext} from 'src/context/settings/settings.store';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {getPrintMission} from '../PrintStickers/PrintStickers.service';
import {AuthContext} from 'src/context/authentication/store.auth';
import {isFullDistrictSelected} from 'src/utils/verifyIfStationsSelected';
import {MissionDto} from 'src/services/models/mission-dto';

export enum DateDirection {
  NEXT = 'NEXT',
  PREV = 'PREV',
  RESET = 'RESET',
}

export interface IMissionFilter {
  reservationType: ReservationTypeDto | MissionTypeDto.Stickering | undefined;
  completed: MissionStatusDto.Completed | undefined;
  stickerMissions: MissionTypeDto.Stickering | undefined;
  started: MissionStatusDto.Started | undefined;
  cancelled: MissionStatusDto.Stopped | undefined;
  fullAssistance: boolean;
  lightAssistance: boolean;
}

//Link filter logic to view

interface IMissionContext {
  selectedDate: string;
  missions: MissionDetailDto[];
  selectedMission: MissionDetailDto;
  selectedMissionDetails: MissionDetailDto | undefined;
  loadingData: boolean;
  loadingDetailData: boolean;
  missionFilter: IMissionFilter;
  filteredMissions: MissionDetailDto[];
  hasFilter: boolean;
  setFilteredMissions: React.Dispatch<React.SetStateAction<MissionDetailDto[]>>;
  setSelectedMissionDetails: React.Dispatch<MissionDetailDto | undefined>;
  handleDateChange: (direction: DateDirection, amount: number) => void;
  handleStationChange: (districtName: string, stationIds: string) => void;
  handleSelectMission: (index: string) => void;
  handleFilterChange: (filter: IMissionFilter) => void;
  handleFilterReset: () => void;
  clearOnDelete: () => void;
  clearMissions: () => void;
  refetch: () => void;
  getMissionDetailsFromBackend: (_selectedMission: MissionDetailDto) => Promise<void>;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  resetMissionStore: () => void;
}

type LocalStorageMissionFilter = {
  id: string;
  filter: IMissionFilter;
};

export const defaultMissionFilter = JSON.stringify({
  reservationType: undefined,
  completed: MissionStatusDto.Completed,
  stickerMissions: MissionTypeDto.Stickering,
  started: undefined,
  cancelled: MissionStatusDto.Stopped,
  fullAssistance: true,
  lightAssistance: true
});

const MissionContext = React.createContext({} as IMissionContext);

const MissionProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {variables} = React.useContext(EnvironmentContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);
  const {doResetOfMissions, setDoResetOfMissions} = React.useContext(SettingsContext);
  const {userData} = React.useContext(AuthContext);

  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()).format('yyyy-MM-DD'));
  const [missions, setMissions] = React.useState([] as MissionDetailDto[]);
  const [selectedMission, setSelectedMission] = React.useState({} as MissionDetailDto);
  const [selectedMissionDetails, setSelectedMissionDetails] = React.useState<MissionDetailDto | undefined>(undefined);
  const [loadingData, setLoadingData] = React.useState(false);
  const [loadingDetailData, setLoadingDetailData] = React.useState(false);

  const [missionFilter, setMissionFilter] = React.useState<IMissionFilter>({
    reservationType: undefined,
    completed: MissionStatusDto.Completed,
    stickerMissions: MissionTypeDto.Stickering,
    started: undefined,
    cancelled: MissionStatusDto.Stopped,
    fullAssistance: true,
    lightAssistance: true
  });
  const [hasFilter, setHasFilter] = React.useState(true);
  const [filteredMissions, setFilteredMissions] = React.useState([] as MissionDetailDto[]);

  const [savedDistricts, setSavedDistricts] = React.useState(localStorage.getItem('districtId') || '');
  const [savedStations, setSavedStations] = React.useState(localStorage.getItem('selectedStations'));

  React.useEffect(() => {
    localStorage.setItem(localStorageKeys.SELECTED_FETCH_DATE, selectedDate);
    const init = async () => {
      await getMissionsFromBackend(savedStations, savedDistricts, selectedDate, false, getLocalMissionFilter());
    };

    handleLocalMissionFilter();

    init();
  }, []);

  const resetMissionStore = () => {
    setMissions([]);
    setFilteredMissions([]);
  };

  const handleLocalMissionFilter = () => {
    const filter = localStorage.getItem(localStorageKeys.MISSION_FILTER);

    const defaultFilter: LocalStorageMissionFilter = {
      id: userData.b_employeeid,
      filter: missionFilter,
    };

    if (filter === null) {
      localStorage.setItem(localStorageKeys.MISSION_FILTER, JSON.stringify(defaultFilter));
      return;
    }

    const parsedFilter: LocalStorageMissionFilter = JSON.parse(filter);
    if (parsedFilter.id === userData.b_employeeid) {
      setMissionFilter(parsedFilter.filter);
      return;
    } else {
      localStorage.setItem(localStorageKeys.MISSION_FILTER, JSON.stringify(defaultFilter));
    }
  };

  const getLocalMissionFilter = (): IMissionFilter => {
    const filter = localStorage.getItem(localStorageKeys.MISSION_FILTER);

    if (filter === null) {
      return {
        reservationType: undefined,
        completed: MissionStatusDto.Completed,
        stickerMissions: MissionTypeDto.Stickering,
        started: undefined,
        cancelled: MissionStatusDto.Stopped,
        fullAssistance: false,
        lightAssistance: false
      };
    } else {
      const parsed: LocalStorageMissionFilter = JSON.parse(filter);
      return parsed.filter;
    }
  };

  const adjustLocalMissionFilter = (newFilter: IMissionFilter) => {
    const localFilter: LocalStorageMissionFilter = {
      id: userData.b_employeeid,
      filter: newFilter,
    };

    localStorage.setItem(localStorageKeys.MISSION_FILTER, JSON.stringify(localFilter));
  };

  const clearMissions = () => {
    if (doResetOfMissions) {
      setSelectedMission({} as MissionDetailDto);
      setSelectedMissionDetails(undefined);
    } else {
      setDoResetOfMissions(true);
    }
  };

  const refetch = async () => {
    setSelectedMission({} as MissionDto);
    setSelectedMissionDetails(undefined);
    await getMissionsFromBackend(savedStations, savedDistricts, selectedDate, false, missionFilter);
  };

  const filterOnMissionType = (reserveType: Pick<IMissionFilter, 'reservationType'>, missions: MissionDetailDto[]) => {
    if (reserveType.reservationType === MissionTypeDto.Stickering) {
      return missions.filter(el => el.missionType === MissionTypeDto.Stickering);
    }

    if (reserveType.reservationType === ReservationTypeDto.Disabled) {
      return missions.filter(el => el.reservationType === ReservationTypeDto.Disabled);
    }

    if (reserveType.reservationType === ReservationTypeDto.Group) {
      return missions.filter(
        el => el.reservationType === ReservationTypeDto.Group && el.missionType !== MissionTypeDto.Stickering,
      );
    }
    return missions;
  };

  const filterMissions = (missions: MissionDetailDto[], filter: IMissionFilter, needsToFilter: boolean) => {
    let onReservation: MissionDetailDto[] = missions;

    const completed = onReservation.filter(el => el.status === MissionStatusDto.Completed);
    const started = onReservation.filter(
      el => el.status === MissionStatusDto.Started || el.status === MissionStatusDto.Stopped,
    );
    const cancelled = onReservation.filter(el => el.status === MissionStatusDto.Deleted);

    const others = onReservation.filter(el => {
      if (
        el.status !== MissionStatusDto.Completed &&
        el.status !== MissionStatusDto.Started &&
        el.status !== MissionStatusDto.Stopped &&
        el.status !== MissionStatusDto.Deleted
      ) {
        return true;
      }
      return false;
    });

    if (filter.completed && filter.started && filter.cancelled && !filter.fullAssistance && !filter.lightAssistance && filter.reservationType !== ReservationTypeDto.Disabled) {
      setFilteredMissions(filterOnMissionType({reservationType: filter.reservationType}, others));
      setMissions(missions);
      return;
    }

    let newReservatios: MissionDetailDto[] = others;

    if (!filter.completed) {
      newReservatios = [...newReservatios, ...completed];
    }

    if (!filter.started) {
      newReservatios = [...newReservatios, ...started];
    }

    if (!filter.cancelled) {
      newReservatios = [...newReservatios, ...cancelled];
    }

    if(filter.fullAssistance || filter.lightAssistance) {
      const filteredByAssistance = newReservatios.filter(el => {
        if (filter.fullAssistance && el?.traveler?.fullAssistances && el?.traveler?.fullAssistances > 0) {
          return true;
        } else if (filter.lightAssistance && el?.traveler?.fullAssistances === 0) {
            return true;
        } else {
          return false;
        }
      });

      newReservatios = filteredByAssistance;
    }

    if (!filter.lightAssistance) {
      const lightAssistance = newReservatios.filter(el => el?.traveler?.fullAssistances !== 0);
      newReservatios = lightAssistance;
    }

    if (!filter.fullAssistance) {
      const fullAssistance = newReservatios.filter(el => el?.traveler?.fullAssistances === 0);
      newReservatios = fullAssistance;
    }

    if(filter.reservationType === ReservationTypeDto.Disabled && !filter.lightAssistance && !filter.fullAssistance) {
      newReservatios = [];
    }

    setFilteredMissions(filterOnMissionType({reservationType: filter.reservationType}, newReservatios));
    setMissions(missions);
    return;
  };

  const getMissionsFromBackend = async (
    _savedStations: string | null,
    _savedDistricts: string | undefined,
    _selectedDate: string,
    comesFromSticker: boolean,
    _filter?: IMissionFilter,
  ) => {
    const missionPromis = new Promise(async (resolve, reject) => {
      if (_savedDistricts?.length === 0 && _savedStations?.length === 0) {
        setLoadingData(false);
        reject();
      }
      if (_savedStations) {
        setLoadingData(true);
        const result = await getMissions(variables.BASE_ENDPOINT, {
          date: _selectedDate,
          districtIds: [_savedDistricts ? _savedDistricts : ''],
          stationIds:
            _savedDistricts && _savedDistricts?.length > 0 && isFullDistrictSelected()
              ? undefined
              : _savedStations.split(','),
        })
          .then(res => {
            if (!res) {
              return false;
            }
            if (res?.selectedDate !== localStorage.getItem(localStorageKeys.SELECTED_FETCH_DATE)) {
              return false;
            }
            if (res && res.data) {
              filterMissions(res.data, _filter ? _filter : missionFilter, true);
              return true;
            } else {
              filterMissions([], _filter ? _filter : missionFilter, true);
              return true;
            }
          })
          .catch((err: AxiosError) => {
            filterMissions([], _filter ? _filter : missionFilter, true);
            if (err.response) {
              validateNetworkCall(err.response.status, err.response.data as ModelError);
              reject();
              return true;
            }
          });
        result && setLoadingData(false);
        resolve('');
      }
    });
    await missionPromis;
    if (comesFromSticker) {
      const today = moment().format('yyyy-MM-DD');
      localStorage.setItem(localStorageKeys.SELECTED_FETCH_DATE, today);
      setSelectedDate(today);
    }
  };

  const getMissionDetailsFromBackend = async (_selectedMission: MissionDetailDto) => {
    if (_selectedMission && _selectedMission.id) {
      setSelectedMissionDetails(undefined);
      setLoadingDetailData(true);
      await getMissionDetails(
        variables.BASE_ENDPOINT,
        _selectedMission.id,
        _selectedMission.reservationType
          ? (_selectedMission.reservationType as ReservationTypeDto)
          : ReservationTypeDto.Group,
      )
        .then(res => {
          if (res) {
            setSelectedMissionDetails(res);
          } else {
            setSelectedMissionDetails(undefined);
          }
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            validateNetworkCall(err.response.status, err.message as ModelError);
            setSelectedMissionDetails(undefined);
          }
        });
      setLoadingDetailData(false);
    }
  };

  const handleDateChange = async (direction: DateDirection, amount: number) => {
    setLoadingData(true);
    const currentDate = moment(selectedDate);
    const addOne = moment(currentDate).add(amount, 'days').format('yyyy-MM-DD');
    const subOne = moment(currentDate).subtract(amount, 'days').format('yyyy-MM-DD');
    if (direction === DateDirection.NEXT) {
      localStorage.setItem(localStorageKeys.SELECTED_FETCH_DATE, addOne);
      setSelectedDate(addOne);
      await getMissionsFromBackend(savedStations, savedDistricts, addOne, false, missionFilter);
    } else if (direction === DateDirection.PREV) {
      localStorage.setItem(localStorageKeys.SELECTED_FETCH_DATE, subOne);
      setSelectedDate(subOne);
      await getMissionsFromBackend(savedStations, savedDistricts, subOne, false, missionFilter);
    } else if (direction === DateDirection.RESET) {
      localStorage.setItem(localStorageKeys.SELECTED_FETCH_DATE, moment(new Date()).format('yyyy-MM-DD'));
      setSelectedDate(moment(new Date()).format('yyyy-MM-DD'));
    }
  };

  const handleStationChange = async (districtName: string, stationIds: string) => {
    setSavedDistricts(districtName);
    setSavedStations(stationIds.toString());
    await getMissionsFromBackend(stationIds.toString(), districtName, selectedDate, false);
  };

  const handleFilterChange = (filter: IMissionFilter) => {
    setHasFilter(true);
    setMissionFilter(filter);
    adjustLocalMissionFilter(filter);
    filterMissions(missions, filter, true);
  };

  const handleFilterReset = async () => {
    setHasFilter(true);
    setMissionFilter({
      reservationType: undefined,
      completed: MissionStatusDto.Completed,
      stickerMissions: MissionTypeDto.Stickering,
      started: undefined,
      cancelled: MissionStatusDto.Stopped,
      fullAssistance: true,
      lightAssistance: true
    });
    adjustLocalMissionFilter({
      reservationType: undefined,
      completed: MissionStatusDto.Completed,
      stickerMissions: MissionTypeDto.Stickering,
      started: undefined,
      cancelled: MissionStatusDto.Stopped,
      fullAssistance: true,
      lightAssistance: true
    });
    await getMissionsFromBackend(savedStations, savedDistricts, selectedDate, false, {
      reservationType: undefined,
      completed: MissionStatusDto.Completed,
      stickerMissions: MissionTypeDto.Stickering,
      started: undefined,
      cancelled: MissionStatusDto.Stopped,
      fullAssistance: true,
      lightAssistance: true
    });
  };

  const handleSelectMission = (index: string) => {
    const mission = missions.find(el => el.id === index);
    if (mission) {
      setSelectedMission(mission);
      getMissionDetailsFromBackend(mission);
    } else {
      setSelectedMission({} as MissionDetailDto);
    }
  };

  const clearOnDelete = async () => {
    setSelectedMission({});
    setSelectedMissionDetails(undefined);
    await getMissionsFromBackend(savedStations, savedDistricts, selectedDate, false);
  };

  return (
    <MissionContext.Provider
      value={{
        selectedDate,
        selectedMission,
        missions,
        loadingData,
        loadingDetailData,
        selectedMissionDetails,
        handleDateChange,
        handleStationChange,
        handleSelectMission,
        handleFilterChange,
        handleFilterReset,
        missionFilter,
        filteredMissions,
        hasFilter,
        clearOnDelete,
        clearMissions,
        refetch,
        getMissionDetailsFromBackend,
        setSelectedMissionDetails,
        setFilteredMissions,
        setSelectedDate,
        resetMissionStore,
      }}
    >
      {children}
    </MissionContext.Provider>
  );
};

export default MissionProvider;
export {MissionContext};
