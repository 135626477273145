import {AxiosError} from 'axios';
import React from 'react';
import {ModelError} from 'src/services/models';
import {SettingsDto} from 'src/services/models/settings-dto';
import {isDateInBetweenGivenDates} from 'src/utils/isDateInBetweenGivenDates';
import {EnvironmentContext} from '../environment/environment.store';
import {NetworkContext} from '../network/network.store';
import {settingsReducer, intialReducerState} from './settings.dispatch';
import {getSettings} from './settings.service';

type ISettings = {
  EXTERNAL_LINK_SUPPORT_SERVICE_NOW: string;
  SUSPENSIONS_FEATURE_FLAG: boolean;
  MISSIONS_FETCH_PAST_IN_DAYS: number;
  MISSIONS_FETCH_FUTURE_IN_DAYS: number;
  COI_FEATURE_FLAG: boolean;
  EXTERNAL_LINK_ATMS: string;
  RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME: number;
  RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME: number;
  RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME: number;
  RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME: number;
  prevRoute: string;
  doResetOfMissions: boolean;
  setDoResetOfMissions: React.Dispatch<boolean>;
  setPrevRoute: React.Dispatch<string>;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<boolean>;
};

export enum IKeys {
  EXTERNAL_LINK_SUPPORT_SERVICE_NOW = 'EXTERNAL_LINK_SUPPORT_SERVICENOW',
  SUSPENSIONS_FEATURE_FLAG = 'SUSPENSIONS_FEATURE_FLAG',
  MISSIONS_FETCH_PAST_IN_DAYS = 'MISSIONS_FETCH_PAST_IN_DAYS',
  MISSIONS_FETCH_FUTURE_IN_DAYS = 'MISSIONS_FETCH_FUTURE_IN_DAYS',
  COI_FEATURE_FLAG = 'COI_FEATURE_FLAG',
  EXTERNAL_LINK_ATMS = 'EXTERNAL_LINK_ATMS',
  RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME = 'RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME',
  RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME = 'RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME',
  RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME = 'RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME',
  RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME = 'RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME',
}

const initialState = {} as ISettings;
export const SettingsContext = React.createContext(initialState);

const SettingsProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {variables} = React.useContext(EnvironmentContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);

  const [settingsState, dispatchSettingsState] = React.useReducer(settingsReducer, intialReducerState);
  const [prevRoute, setPrevRoute] = React.useState('');
  const [doResetOfMissions, setDoResetOfMissions] = React.useState(true);
  const [isMenuOpen, setIsMenuOpen] = React.useState(true);

  React.useEffect(() => {
    getSettings(variables.BASE_ENDPOINT)
      .then((res: SettingsDto | undefined) => {
        if (res) {
          const defaultExternalLink = res.find(el => el.key === IKeys.EXTERNAL_LINK_SUPPORT_SERVICE_NOW)
            ?.defaultValue as string;
          const defaultSuspension = res.find(el => el.key === IKeys.SUSPENSIONS_FEATURE_FLAG)?.defaultValue as boolean;
          const defaultFetchPast = res.find(el => el.key === IKeys.MISSIONS_FETCH_PAST_IN_DAYS)?.defaultValue as number;
          const defaultFetchFuture = res.find(el => el.key === IKeys.MISSIONS_FETCH_FUTURE_IN_DAYS)
            ?.defaultValue as number;
          const defaultCoiFeatureFlag = res.find(el => el.key === IKeys.COI_FEATURE_FLAG)?.defaultValue as boolean;
          const externalLinkAtms = res.find(el => el.key === IKeys.EXTERNAL_LINK_ATMS)?.defaultValue as string;

          const fullMin = res.find(el => el.key === IKeys.RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME)
            ?.defaultValue as number;
          const fullMax = res.find(el => el.key === IKeys.RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME)
            ?.defaultValue as number;
          const lightMin = res.find(el => el.key === IKeys.RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME)
            ?.defaultValue as number;
          const lightMax = res.find(el => el.key === IKeys.RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME)
            ?.defaultValue as number;

          dispatchSettingsState({type: IKeys.EXTERNAL_LINK_SUPPORT_SERVICE_NOW, payload: defaultExternalLink});
          dispatchSettingsState({type: IKeys.SUSPENSIONS_FEATURE_FLAG, payload: defaultSuspension});
          dispatchSettingsState({type: IKeys.MISSIONS_FETCH_FUTURE_IN_DAYS, payload: defaultFetchFuture});
          dispatchSettingsState({type: IKeys.MISSIONS_FETCH_PAST_IN_DAYS, payload: defaultFetchPast});
          dispatchSettingsState({
            type: IKeys.COI_FEATURE_FLAG,
            payload: defaultCoiFeatureFlag,
          });
          dispatchSettingsState({type: IKeys.EXTERNAL_LINK_ATMS, payload: externalLinkAtms});

          dispatchSettingsState({type: 'FULLMIN', payload: fullMin});
          dispatchSettingsState({type: 'FULLMAX', payload: fullMax});
          dispatchSettingsState({type: 'LIGHTMIN', payload: lightMin});
          dispatchSettingsState({type: 'LIGHTMAX', payload: lightMax});

          res.forEach(setting => {
            if (setting.valueOverrides.length > 0) {
              setting.valueOverrides.forEach(override => {
                if (isDateInBetweenGivenDates(override.validFrom, override.validTo)) {
                  if (setting.key === 'RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME') {
                    //@ts-ignore
                    dispatchSettingsState({type: 'FULLMAX', payload: override.value});
                  }

                  if (setting.key === 'RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME') {
                    //@ts-ignore
                    dispatchSettingsState({type: 'FULLMIN', payload: override.value});
                  }

                  if (setting.key === 'RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME') {
                    //@ts-ignore
                    dispatchSettingsState({type: 'LIGHTMAX', payload: override.value});
                  }

                  if (setting.key === 'RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME') {
                    //@ts-ignore
                    dispatchSettingsState({type: 'LIGHTMIN', payload: override.value});
                  }

                  //@ts-ignore
                  dispatchSettingsState({type: setting.key, payload: override.value});
                }
              });
            }
          });
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
        }
      });
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        ...settingsState,
        prevRoute,
        setPrevRoute,
        doResetOfMissions,
        setDoResetOfMissions,
        isMenuOpen,
        setIsMenuOpen,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
