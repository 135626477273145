import React from 'react';

const Supervisor = () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.75 8.25C11.225 8.25 10.7812 8.06875 10.4187 7.70625C10.0562 7.34375 9.875 6.9 9.875 6.375C9.875 5.85 10.0562 5.40625 10.4187 5.04375C10.7812 4.68125 11.225 4.5 11.75 4.5C12.275 4.5 12.7187 4.68125 13.0812 5.04375C13.4437 5.40625 13.625 5.85 13.625 6.375C13.625 6.9 13.4437 7.34375 13.0812 7.70625C12.7187 8.06875 12.275 8.25 11.75 8.25ZM8 12V10.95C8 10.65 8.07812 10.3719 8.23437 10.1156C8.39062 9.85938 8.6125 9.675 8.9 9.5625C9.35 9.375 9.81562 9.23438 10.2969 9.14063C10.7781 9.04688 11.2625 9 11.75 9C12.2375 9 12.7219 9.04688 13.2031 9.14063C13.6844 9.23438 14.15 9.375 14.6 9.5625C14.8875 9.675 15.1094 9.85938 15.2656 10.1156C15.4219 10.3719 15.5 10.65 15.5 10.95V12H8ZM6.5 6C5.675 6 4.96875 5.70625 4.38125 5.11875C3.79375 4.53125 3.5 3.825 3.5 3C3.5 2.175 3.79375 1.46875 4.38125 0.88125C4.96875 0.29375 5.675 0 6.5 0C7.325 0 8.03125 0.29375 8.61875 0.88125C9.20625 1.46875 9.5 2.175 9.5 3C9.5 3.825 9.20625 4.53125 8.61875 5.11875C8.03125 5.70625 7.325 6 6.5 6ZM0.5 12V9.9C0.5 9.475 0.60625 9.08438 0.81875 8.72813C1.03125 8.37188 1.325 8.1 1.7 7.9125C2.45 7.5375 3.22812 7.25 4.03437 7.05C4.84062 6.85 5.6625 6.75 6.5 6.75C6.9375 6.75 7.375 6.7875 7.8125 6.8625C8.25 6.9375 8.6875 7.025 9.125 7.125L7.85 8.4C7.625 8.3375 7.4 8.29688 7.175 8.27813C6.95 8.25938 6.725 8.25 6.5 8.25C5.775 8.25 5.06562 8.3375 4.37187 8.5125C3.67812 8.6875 3.0125 8.9375 2.375 9.2625C2.25 9.325 2.15625 9.4125 2.09375 9.525C2.03125 9.6375 2 9.7625 2 9.9V10.5H6.5V12H0.5ZM6.5 4.5C6.9125 4.5 7.26562 4.35313 7.55937 4.05938C7.85312 3.76563 8 3.4125 8 3C8 2.5875 7.85312 2.23438 7.55937 1.94063C7.26562 1.64688 6.9125 1.5 6.5 1.5C6.0875 1.5 5.73437 1.64688 5.44062 1.94063C5.14687 2.23438 5 2.5875 5 3C5 3.4125 5.14687 3.76563 5.44062 4.05938C5.73437 4.35313 6.0875 4.5 6.5 4.5Z"
      fill="#333333"
    />
  </svg>
);

export default Supervisor;
