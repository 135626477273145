import { useContext, FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import {Phone} from '@mui/icons-material';

import { TrainDetailsDto } from 'src/services/models/train-details-dto';
import { EnvironmentContext } from 'src/context/environment/environment.store';
import { StatusItem } from 'src/components/StatusItem/StatusItem';
import { PresenceDto } from 'src/services/models/presence-dto';
import { FoodDto } from 'src/services/models/food-dto';
import { CoiEvacuationMethods } from './coiEvacuationMethods';
import { PtcarDetailsDto } from 'src/services/models/ptcar-details-dto';
import { AddressDetailsDto } from 'src/services/models/address-details-dto';
import { EvacuationDto } from 'src/services/models/evacuation-dto';
import { LocationDetailsDto } from 'src/services/models/location-details-dto';

interface CoiMissionDetailProps {
  locations: TrainDetailsDto[] | PtcarDetailsDto[] | AddressDetailsDto[];
  title: string;
  handleOpenPhoto: (missionId: string, photoId: string) => void;
  handleOpenEvacuationModal: (mission: LocationDetailsDto, evacuation: EvacuationDto) => void;
  // handleOpenCoiTarget: (targetIndex: number | null) => void;
}

export const CoiMissionDetail: FC<CoiMissionDetailProps> = ({ locations, title, handleOpenPhoto, handleOpenEvacuationModal }) => {
  const { t } = useTranslation();
  const { variables } = useContext(EnvironmentContext);

  return (
    <Box sx={{marginTop: '20px'}}>
      <Typography
        sx={{fontWeight: 'bold', fontSize: '18px', fontFamily: 'Arial, sans-serif', marginBottom: '10px'}}
      >
        {title}
      </Typography>
      <Box>
        {locations.map((location: TrainDetailsDto) => {
          const evacuationsTrains = location.evacuation?.filter(evacuation => evacuation.type === 'Train');
          const evacuationsBuses = location.evacuation?.filter(evacuation => evacuation.type === 'Bus');
          const evacuationsTaxis = location.evacuation?.filter(evacuation => evacuation.type === 'Taxi');
          const evacuationsFoot = location.evacuation?.filter(evacuation => evacuation.type === 'Foot');
          const evacuationsEmergencyVehicle = location.evacuation?.filter(evacuation => evacuation.type === 'EmergencyVehicle');
          const foodOnTrain = [...location?.food || []].sort((a,b) => {
            if (!!a.type && !!b.type) {
              return a.type > b.type ? 1 : b.type > a.type ? -1 : 0
            }
            return 0;
          });
          const photosOnTrain = location.photos;
          return (
          <Accordion
            key={location.id}
            elevation={0}
            sx={{
              border: '1px solid rgba(0, 105, 180, 0.3)',
              borderRadius: '4px',
              marginBottom: '10px',
              position: 'relative',
              '&::before': {
                backgroundColor: 'transparent',
              },
            }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`location-${location.id}-header`}
                aria-controls={`location-${location.id}-content`}
              >
                {location.mainLocation ? (
                  <Box sx={{
                    width: '8px',
                    height: '40px',
                    backgroundColor: '#003B8C',
                    borderRadius: '0 10px 10px 0',
                    top: '6px',
                    left: '0px',
                    position: 'absolute'
                  }} />
                ) : null}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '4px',
                    width: '100%',
                    paddingRight: '10px',
                  }}
                >
                  {location?.trainNumber ? (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        margin: '0 10px'
                      }}
                    >
                        <a
                          target="_blank"
                          href={`${variables.ATMS_BASE_URL}/trains/${location.trainNumber}/${location.departureDate}`}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: '#0069B4',
                              border: '1.5px solid #707070',
                              padding: '2px 4px',
                              borderRadius: '4px',
                            }}
                          >
                            {location.trainNumber}
                          </Typography>
                        </a>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{height: 'auto', paddingTop: '10px', backgroundColor: '#25336C'}}
                      />
                    </Box>
                  ) : null}
                  {location?.trainNumber ? (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        textWrap: 'pretty'
                      }}>
                        {location?.fromStation}
                      </Typography>
                      <Divider sx={{backgroundColor: 'grey', height: '2px', width: '20px'}} />
                      <Typography sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        textWrap: 'pretty'
                      }}>
                        {'toStation' in location ? location.toStation : ''}
                      </Typography>
                    </Box>
                  ) : null}
                  {'name' in location ? <Typography sx={{fontWeight: 'bold'}}>{location.name}</Typography> : null}
                  {'address' in location ? <Typography sx={{fontWeight: 'bold'}}>{location.address}</Typography> : null}
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      minWidth: '175px',
                      justifySelf: 'flex-end',
                      marginLeft: 'auto'
                    }}
                  >
                    {'status' in location && (
                      <StatusItem
                        label={(location.status as string) || ''}
                        color={location.status ? '#C27710' : 'grey'}
                      />
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        border: '1.5px solid black',
                        borderRadius: '8px',
                        padding: '2px 8px',
                        marginLeft: '10px',
                        minWidth: '100px',
                        maxHeight: '28px'
                      }}
                      // onClick={() => handleOpenCoiTarget(0)}
                    >
                      <AssignmentIndOutlinedIcon sx={{fontSize: '18px', color: 'black'}} />
                      <Typography sx={{fontWeight: 'bold', fontSize: '14px'}}>{t('coi.incident-edit-coi')}</Typography>
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0 0 10px 0' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', backgroundColor: '#EFF4F9', padding: '20px 20px 20px 90px' }}>
                  {location.employeeName ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{display: 'flex', gap: '10px'}}>
                        <PersonIcon sx={{fontSize: '16px'}} />
                        <Typography sx={{fontWeight: 700, fontSize: '14px'}}>{location.employeeName}</Typography>
                      </Box>
                      {location.employeePhoneNumber ? (
                        <a
                          href={`dica:${location.employeePhoneNumber?.replace('+', '000')}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#066CB5',
                            textDecoration: 'none',
                          }}
                        >
                          <Phone sx={{marginRight: '10px', fontSize: '20px'}} />
                          <Typography sx={{fontWeight: 700, fontSize: '14px'}}>
                            {location.employeePhoneNumber}
                          </Typography>
                        </a>
                      ) : null}
                    </Box>
                  ) : null}
                  {location.assignments?.map((assignment) => (
                    <Box
                      key={`assigments-employee-${assignment.employeeId}`}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                      }}
                    >
                      <Box sx={{display: 'flex', gap: '10px'}}>
                        <SupervisorAccountOutlinedIcon sx={{fontSize: '16px'}} />
                        <Typography sx={{fontWeight: 700, fontSize: '14px'}}>{assignment.employeeName}</Typography>
                      </Box>
                      {assignment.employeePhoneNumber ? (
                        <a
                          href={`dica:${assignment.employeePhoneNumber?.replace('+', '000')}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#066CB5',
                            textDecoration: 'none',
                          }}
                        >
                          <Phone sx={{marginRight: '10px', fontSize: '20px'}} />
                          <Typography sx={{fontWeight: 700, fontSize: '14px'}}>
                            {assignment.employeePhoneNumber}
                          </Typography>
                        </a>
                      ) : null}
                    </Box>
                  ))}
                </Box>
                <Box sx={{
                  borderRadius: '4px',
                  marginBottom: '10px',
                  padding: '15px 20px',
                }}>
                  <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                    {t('coi.incident-presences')}
                  </Typography>
                  <Table size='small'>
                    <TableBody>
                      {!!location.coiArrived ? (
                        <TableRow sx={{ padding: '10px 0px' }}>
                          <TableCell sx={{ fontWeight: 'bold' }}>COI</TableCell>
                          <TableCell align='right'>{moment(location.coiArrived.arrivedAtExactLocation).format('HHumm')}</TableCell>
                        </TableRow>
                      ) : null}
                      {location.presences?.filter((p: PresenceDto) => !!p.arrivalAt).map(presence => (
                        <TableRow key={`presence-${presence.type}`}>
                          <TableCell sx={{ fontWeight: 'bold' }}>{presence.type}</TableCell>
                          <TableCell align='right'>{moment(presence.arrivalAt).format('HHumm')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <CoiEvacuationMethods
                  evacuationsTrains={evacuationsTrains}
                  evacuationsBuses={evacuationsBuses}
                  evacuationsTaxis={evacuationsTaxis}
                  evacuationsFoot={evacuationsFoot}
                  evacuationsEmergencyVehicle={evacuationsEmergencyVehicle}
                  handleOpenEvacuationModal={(evacuation: EvacuationDto) => handleOpenEvacuationModal(location, evacuation)}
                />
                {!!foodOnTrain?.length ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px', padding: '15px 20px' }}>
                    <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                      {t('coi.incident-food')}
                    </Typography>
                    <Table size='small'>
                      <TableBody>
                        {foodOnTrain?.map((food: FoodDto) => (
                          <TableRow key={`detail-food-${food.type}`}>
                            <TableCell>{food.type}</TableCell>
                            <TableCell align='right'>{food.quantity}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                ) : null}
                {!!photosOnTrain?.length ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px', padding: '15px 20px' }}>
                    <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                      {t('coi.incident-photos')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
                      {photosOnTrain?.map(photo => (
                        <Button
                          key={`${location.missionId}-${photo.id}`}
                          sx={{border: 'none', backgroundColor: 'transparent'}}
                          onClick={() => photo?.id && location.missionId && handleOpenPhoto(location.missionId, photo.id)}
                        >
                          <img src={`data:image/jpeg;base64,${photo?.thumbnail?.base64Content}`} alt={`photo-${photo.id || ''}`} width='50px' />
                        </Button>
                      ))}
                    </Box>
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Box>
    </Box>
  );
};
