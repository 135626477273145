import moment from 'moment';
import {FullVerticalLine, SemiVerticalLine, ExtendedFullVerticalLine, ExtendedSemiVerticalLine} from './styles';

export const getVerticalLine = (isEndOfLine: boolean, isFullStop: boolean, isExtended: boolean) => {
  if (isEndOfLine) {
    return null;
  }

  if (isFullStop) {
    if (isExtended) {
      return <ExtendedFullVerticalLine />;
    }
    return <FullVerticalLine />;
  } else {
    if (isExtended) {
      return <ExtendedSemiVerticalLine />;
    }
    return <SemiVerticalLine />;
  }
};

export const getBackGroundColor = (isFullStop: boolean, isSticker: boolean, isEndOfLine: boolean) => {
  if (isFullStop || isSticker) {
    return '#EFF4F9';
  }

  if (!isEndOfLine && !isFullStop) {
    return 'white';
  }

  return '#EFF4F9';
};

export const hasAssistance = (assistance: boolean | undefined) => {
  if (assistance === undefined) {
    return '';
  } else if (assistance) {
    return 'check-in.btn-yes';
  }

  return 'check-in.btn-no';
};

export const getTime = (time: Date | undefined) => {
  if (time) {
    return moment(time).format('HH:mm');
  } else {
    return '';
  }
};
