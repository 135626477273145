//@ts-nocheck
import React, {useContext} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import {alpha, styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react';
import EmptyListIcon from 'src/components/icons/EmptyList';
import {colors} from 'src/utils/hexCode';
import MissionItem from 'src/components/coordinator/MissionItem/MissionItem';
import {SettingsContext} from 'src/context/settings/settings.store';
import {Navigate, useLocation} from 'react-router-dom';
import {PlusIcon} from 'src/components/icons';

import {emptyList, LeftScreenContainer, loaderScreen, RightScreenContainer} from './MissionOverview.styles';
import '../ReservationFilter/ReservationFilter.scss';

import {useTranslation} from 'react-i18next';

import DateRange from '../../../components/DateRangeSection/DateRange';
import ReservationFilter from '../ReservationFilter';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {ReservationTypeDto} from 'src/services/models';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {DateDirection, MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';
import ReloadIcon from 'src/components/images/ic_refresh.svg';
import {mapDataToAssignment} from './missions.util';
import MissionDetailsNew from '../MissionDetailsNew/MissionDetailsNew';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {Button, Fab, Popover} from '@mui/material';
import {SelectStationComponent} from 'src/utils/selectStation';

const Search = styled('div')(({theme}) => ({
  position: 'relative',
  borderRadius: 14,
  backgroundColor: alpha(colors.black_opaque, 0.65),
  '&:hover': {
    backgroundColor: alpha(colors.black_opaque, 0.85),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: '#fff',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '14ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const fabStyle = {
  position: 'fixed',
  bottom: 8,
  zIndex: 1,
  marginLeft: '15px',
  width: '350px',
};

export default function MissionOverview({
  isAssignScreen,
  isNotAssignScreen,
}: {
  isAssignScreen: boolean;
  isNotAssignScreen: boolean;
}) {
  const [selectedIndex, setSelectedIndex] = useState<null | string>(null);
  const [mappedData, setMappedData] = useState([] as MissionDetailDto[]);
  const {t} = useTranslation();
  const [valueToSearch, setValueToSearch] = useState('');
  const {variables} = useContext(EnvironmentContext);

  const {handleDateChange, loadingData} = React.useContext(MissionContext);

  const onLeftClickHandle = () => {
    handleDateChange(DateDirection.PREV, 1);
  };

  const onRightClickHandle = () => {
    handleDateChange(DateDirection.NEXT, 1);
  };

  const {
    filteredMissions,
    missions,
    handleSelectMission,
    selectedMissionDetails,
    loadingDetailData,
    missionFilter,
    clearMissions,
    refetch,
  } = React.useContext(MissionContext);

  const location = useLocation();

  const setActive = (index: string) => {
    setSelectedIndex(index);
    handleSelectMission(index);
  };

  const [show, setShow] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => setShow(false), 1 * 100);

    return () => {
      clearTimeout(timer);
    };
  }, [show, setShow, loadingData]);

  useEffect(() => {
    clearMissions();
    setShow(true);
    const data = mapDataToAssignment(filteredMissions, isAssignScreen ? isAssignScreen : false);
    setMappedData(data);
  }, [isAssignScreen, isNotAssignScreen, location, missionFilter, missions]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValueToSearch(event.target.value);
  };

  const showDetailDataScreen = () => {
    if (loadingDetailData && selectedMissionDetails === undefined) {
      return (
        <Box style={{height: '85%'}} alignItems="center" justifyContent="center" display="flex">
          <Box style={{color: colors.grey_7}}>
            <Box alignItems="center" justifyContent="center" display="flex">
              <CircularProgress style={{color: colors.nbms_dark_blue}} />
            </Box>
          </Box>
        </Box>
      );
    }
    if (selectedMissionDetails !== undefined && loadingDetailData === false) {
      return <MissionDetailsNew isAssigned={isAssignScreen} />;
    }

    if (selectedMissionDetails === undefined && loadingDetailData === false) {
      return (
        <Box style={{height: '85%'}} alignItems="center" justifyContent="center" display="flex">
          <Box style={{color: colors.grey_7}}>
            <Box alignItems="center" justifyContent="center" display="flex">
              <EmptyListIcon style={emptyList} />
            </Box>
            <Typography paragraph>{t('mission-overview.box-select-mission')}</Typography>
          </Box>
        </Box>
      );
    }
  };

  const filterAndMapMissions = (data: MissionDetailDto[]) => {
    return data
      .filter(el =>
        valueToSearch.length > 0
          ? el.journey?.stationName?.toLocaleUpperCase().includes(valueToSearch.toLocaleUpperCase())
          : true,
      )
      .sort((prev, next) => {
        if (new Date(prev.journey?.time).getTime() === new Date(next.journey?.time).getTime()) {
          return prev.status?.localeCompare(next.status);
        }
        return new Date(prev.journey?.time).getTime() > new Date(next.journey?.time).getTime() ? 1 : -1;
      })
      .map(item => {
        return (
          <MissionItem
            id={item.id}
            dataItem={item}
            isPMR={item.reservationType === ReservationTypeDto.Disabled}
            isAtDeparture={item.missionType === MissionTypeDto.Departure}
            isAtArrival={item.missionType === MissionTypeDto.Arrival}
            handleClick={setActive}
            selectedindex={selectedIndex}
            isAssigned={isAssignScreen}
          />
        );
      });
  };

  if (localStorage.getItem('selectedStations')?.length === 0) {
    return <SelectStationComponent />;
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `${variables.USE_PMR_3 ? '400px 1fr' : '470px 1fr'}`,
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
      }}
    >
      <LeftScreenContainer>
        <Box>
          <Grid container>
            <Grid item xs={2}>
              <div
                style={{display: 'grid', placeItems: 'center', paddingTop: '5px'}}
                onClick={() => {
                  refetch();
                }}
              >
                <div>
                  <span>
                    <img src={ReloadIcon} className="img-style" alt="img" style={{color: 'blue'}} />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={8}>
              <DateRange onRightClicked={onRightClickHandle} onLeftClicked={onLeftClickHandle} />
            </Grid>
            <Grid item xs={2}>
              <ReservationFilter />
            </Grid>
          </Grid>
        </Box>
        <List style={show || loadingData ? loaderScreen : {paddingBottom: 120, paddingTop: 0}} autoFocus={true}>
          {show || loadingData ? (
            <Box>
              <CircularProgress style={{color: colors.nbms_dark_blue}} />
            </Box>
          ) : (
            <>{filterAndMapMissions(mappedData)}</>
          )}
        </List>
      </LeftScreenContainer>
      {!variables.USE_PMR_3 && <AddMissionButtons handleChange={handleChange} />}
      <RightScreenContainer>{showDetailDataScreen()}</RightScreenContainer>
      <AddMissionButtons handleChange={handleChange} />
    </Box>
  );
}
//

const AddMissionButtons = ({
  handleChange,
}: {
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}) => {
  const [isOpen, setOpen] = useState(false);
  const [fabInputRef, setInputRef] = useState('');
  const [doRedirect, setDoRedirect] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');
  const location = useLocation();
  const {setPrevRoute} = React.useContext(SettingsContext);
  const {variables} = React.useContext(EnvironmentContext);

  const {t} = useTranslation();

  const redirect = (p: string) => {
    setPrevRoute(location.pathname);
    setRedirectPath(p);
    setDoRedirect(true);
  };

  return (
    <Box sx={fabStyle} style={!variables.USE_PMR_3 ? {display: 'grid', gridTemplateColumns: '1fr 1fr'} : {}}>
      {!variables.USE_PMR_3 && (
        <Box>
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            style={{borderRadius: 16}}
            onClick={e => {
              setInputRef(e.currentTarget);
              setOpen(true);
            }}
          >
            <PlusIcon sx={{mr: 1}} />
            <Box
              sx={{mr: 1}}
              aria-describedby="fab_input_ref"
              onClick={e => {
                setInputRef(e.currentTarget);
                setOpen(true);
              }}
            >
              {t('mission-overview.box-add-mission')}
            </Box>
          </Fab>
          <Popover
            open={isOpen}
            id="fab_input_ref"
            anchorEl={fabInputRef}
            onClose={() => setOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'top',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 200,
                height: 100,
              }}
            >
              <Box
                onClick={() => redirect('/last-minute-reservation-pmr')}
                style={{height: 50, width: '100%', textDecoration: 'none'}}
              >
                <Button
                  style={{
                    height: 50,
                    backgroundColor: '#ffd046',
                    width: '100%',
                    color: colors.nbms_dark_blue,
                    fontWeight: 700,
                    fontSize: 16,
                  }}
                >
                  {t('mission-overview.box-button-PMR')}
                </Button>
              </Box>
              <Box
                style={{height: 50, width: '100%', textDecoration: 'none'}}
                onClick={() => redirect('/last-minute-reservation-grobo')}
              >
                <Button
                  style={{
                    height: 50,
                    backgroundColor: '#00a8a9',
                    width: '100%',
                    color: colors.nbms_dark_blue,
                    fontWeight: 700,
                    fontSize: 16,
                  }}
                >
                  {t('mission-overview.box-button-Grobo')}
                </Button>
              </Box>
            </Box>
          </Popover>
          {doRedirect && <Navigate to={redirectPath} />}
        </Box>
      )}

      <Search>
        <SearchIconWrapper>
          <SearchIcon style={{color: '#fff'}} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={t('create-grobo-mission.search')}
          inputProps={{'aria-label': 'search'}}
          onChange={handleChange}
        />
      </Search>
    </Box>
  );
};
