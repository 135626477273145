import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Grid,
  Chip
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BlindOutlinedIcon from '@mui/icons-material/BlindOutlined';
import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined';

import {FormAction, FormActionKind, FormState} from '../../formState';
import { AssistanceTypeDto } from "src/services/models/assistance-type-dto";

export const AssistanceTypes: React.FC<{state: FormState; dispatch: React.Dispatch<FormAction>}> = ({
  state, dispatch
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ padding: '0px 25px', margin: '15px 0px' }}>
      <Typography sx={{ marginBottom: '15px', fontWeight: 'bold' }}>{t('pmr.search.assistance-types')}</Typography>
      <Grid container gap={1}>
        <Grid item key={'all'}>
          <Chip
            label="All"
            variant="outlined"
            onClick={() => dispatch({ type: FormActionKind.ASSISTANCE, payload: 'all' })}
            icon={state.assistance === 'all' ? <CheckIcon sx={{ width: '18px' }} /> : <></>}
            onDelete={state.assistance === 'all' ? () => dispatch({ type: FormActionKind.ASSISTANCE, payload: '' }) : undefined}
            deleteIcon={<CloseOutlinedIcon sx={{ marginLeft: '4px', width: '18px', color: 'darkgrey' }} />}
            sx={{
              border: 'solid 1.5px darkgrey',
              borderRadius: '5px',
              fontWeight: 'bold',
              padding: '4px 8px',
              ...(state.assistance === 'all' ? { border: 'solid 1px white', backgroundColor: '#EFF4F9' } : {})
            }}
          />
        </Grid>
        {Object.values(AssistanceTypeDto).map(el => (
          <Grid item key={el}>
            <Chip
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {el === "Full" ? <AccessibleOutlinedIcon sx={{ width: '18px', marginRight: '4px', color: 'darkorange' }} /> : null}
                  {el === "Light" ? <BlindOutlinedIcon sx={{ width: '18px', marginRight: '4px', color: 'green' }} /> : null}
                  {t(`${el.toLowerCase()}-assistance`)}
                </Box>
              }
              variant="outlined"
              onClick={() => dispatch({ type: FormActionKind.ASSISTANCE, payload: el })}
              icon={state.assistance === el ? <CheckIcon sx={{ width: '18px' }} /> : <></>}
              onDelete={state.assistance === el ? () => dispatch({ type: FormActionKind.ASSISTANCE, payload: '' }) : undefined}
              deleteIcon={<CloseOutlinedIcon sx={{ marginLeft: '4px', width: '18px', color: 'darkgrey' }} />}
              sx={{
                border: 'solid 1.5px darkgrey',
                borderRadius: '5px',
                fontWeight: 'bold',
                padding: '4px 6px',
                ...(state.assistance === el ? {border: 'solid 1px white', backgroundColor: '#EFF4F9'} : {})
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
