import axios from "axios";
import { ActiveTrainDto, TrainDto } from "src/services/models";

async function getActiveTrains(baseUrl: string, trainNumber?: number, maxResults?: number): Promise<ActiveTrainDto[]> {
    const queryParams = new URLSearchParams();
  
    if (trainNumber !== undefined) {
      queryParams.append('trainNumber', trainNumber.toString());
    }
  
    if (maxResults !== undefined) {
      queryParams.append('maxResults', maxResults.toString());
    }
  
    const url = `${baseUrl}/api/trains/active?${queryParams.toString()}`;
  
    try {
      const response = await axios.get<ActiveTrainDto[]>(url);
      return response.data;
    } catch (error) {
      return [];
    }
}

async function getTrain(baseUrl: string, trainNumber: number): Promise<TrainDto> {

  
    const url = `${baseUrl}/api/trains/${trainNumber}`;
  
    try {
      const response = await axios.get<TrainDto>(url);
      return response.data;
    } catch (error) {
      return {} as TrainDto;
    }
}



export {
    getActiveTrains,
    getTrain
}