import React from 'react';
import FormElements from '../components/search';
import {Box, CircularProgress, Paper} from '@mui/material';
import {Stack} from '@mui/system';

import {AdvancedSelection} from '../types';
import {FormActionKind, FormState, formReducer, initialFormState} from '../formState';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import {useTranslation} from 'react-i18next';

const SEARCH_STATE_LS = 'DICOS_RESERVATION_SEARCH_STATE';

const getInitialFormState = () => {
  const hasLocalSearchState = localStorage.getItem(SEARCH_STATE_LS);
  if (hasLocalSearchState) {
    return JSON.parse(hasLocalSearchState) as FormState;
  } else {
    return initialFormState;
  }
};

const isAdvancedFilled = () => {
  const r = getInitialFormState();
  if (r.reservation !== undefined && r.reservation.length !== 0) return true;
  if (r.train !== undefined && r.train.length !== 0) return true;
  if (r.station !== undefined && r.station.length !== 0) return true;

  return false;
};

const getInitialAdvancedSelection = (): AdvancedSelection => {
  const r = getInitialFormState();
  if (r.reservation !== undefined && r.reservation.length !== 0) return 'RESERVATIONS';
  if (r.train !== undefined && r.train.length !== 0) return 'TRAIN';
  if (r.station !== undefined && r.station.length !== 0) return 'STATIONS';
  return 'STATIONS';
};

export const Search = () => {
  const [state, dispatch] = React.useReducer(formReducer, getInitialFormState());
  const [isAdvancedSearch, setIsAdvancedSearch] = React.useState(isAdvancedFilled());

  const [advancedSelection, setAdvancedSelection] = React.useState<AdvancedSelection>(getInitialAdvancedSelection());
  const {isSearching, searchedReservations} = React.useContext(ReservationsContext);
  const [disableSearch, setDisableSearch] = React.useState(false);

  //Disable search if no arrival or departure is selected when station is filled
  React.useEffect(() => {
    if (state.station && state.station.length > 0) {
      if (state.arrival || state.departure) {
        return setDisableSearch(false);
      }
      return setDisableSearch(true);
    }
    return setDisableSearch(false);
  }, [state]);

  //Apply state to localstorage for
  React.useEffect(() => {
    localStorage.setItem(SEARCH_STATE_LS, JSON.stringify(state));
  }, [state]);

  const clearForm = () => {
    dispatch({type: FormActionKind.RESET, payload: null});
    localStorage.setItem(SEARCH_STATE_LS, JSON.stringify(initialFormState));
  };

  const {t} = useTranslation();

  return (
    <>
      <Paper elevation={3} sx={{width: '100%', marginBottom: '25px'}}>
        <Stack>
          <FormElements.DateRange state={state} dispatch={dispatch} />
          <FormElements.OwnerAndClient state={state} dispatch={dispatch} />
          <FormElements.ReservationStatus state={state} dispatch={dispatch} />
          <FormElements.AssistanceTypes state={state} dispatch={dispatch} />
          <FormElements.AdvancedSearch
            state={state}
            dispatch={dispatch}
            isAdvancedSearch={isAdvancedSearch}
            advancedSelection={advancedSelection}
            setAdvancedSelection={setAdvancedSelection}
            disableSearch={disableSearch}
          />
          <FormElements.Buttons
            clearForm={clearForm}
            disableSearch={disableSearch}
            searchState={state}
            setIsAdvancedSearch={setIsAdvancedSearch}
            isAdvancedSearch={isAdvancedSearch}
          />
        </Stack>
      </Paper>
      <h4 style={{marginBottom: '25px'}}>{searchedReservations.length + ' ' + t('pmr.search.results')}</h4>
      {isSearching && (
        <Box sx={{display: 'grid', gridTemplateColumns: '1fr', placeItems: 'center', height: '250px'}}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
