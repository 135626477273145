import React from 'react';
import {Box, Stack} from '@mui/material';
import {ReservationItemTitel} from './ReservationItemTitle';
import {TravelItem} from './TravelItem';
import {generateKey} from 'src/utils/generateKey';
import Refusal from './refusal';
import {ReservationsContext} from 'src/context/reservations/reservations.store';
import {DisabledReservationDto} from 'src/services/models/disabled-reservation-dto';
import {SubPmrRoutes} from '../Pmr';
import moment from 'moment';
import {Button, MenuItem, Paper, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import {Navigate, useLocation} from 'react-router-dom';
import {SettingsContext} from 'src/context/settings/settings.store';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {ReservationClientAndStatus} from './ReservationClientAndStatus';
import {finalizedHideIfCompliedToSelection} from '../util';
import {AssistanceType} from 'src/services/models/assistance-type';

export const ReservationItem: React.FC<{reservation: DisabledReservationDto; ind: number; variant: SubPmrRoutes}> = ({
  reservation,
  ind,
  variant,
}) => {
  const {
    focusedReservation,
    isDeclineViewVisible,
    setIsDeclineViewVisible,
    cancelRefusalModal,
    setCancelRefusalModal,
    setFocusedReservation,
  } = React.useContext(ReservationsContext);

  const [isChangeRouteViewVisible, setIsChangeRouteViewVisible] = React.useState(false);
  const district = localStorage.getItem(localStorageKeys.SELECED_STATIONS)?.split(',') || '';

  const handleChangeRoute = () => {
    setFocusedReservation(ind);
    setIsChangeRouteViewVisible(true);
  };

  const getFirstDate = () => {
    if (
      reservation.travels &&
      reservation.travels[0] &&
      reservation.travels[0].journeys &&
      reservation.travels[0].journeys[0]
    ) {
      return moment(reservation.travels[0].journeys[0].departureTime).format('DD.MM.YYYY');
    }
    return '';
  };

  const handleValidateRoute = () => {
    return null;
  };

  const allDisbaledAssistanceTypes =
    reservation.travels && reservation.travels.map(el => el.traveler?.disableds?.map(d => d.assistanceType));

  const getItem = () => {
    return (
      <>
        <Stack
          spacing={1}
          sx={{
            marginBottom: '18px',
            marginTop: '18px',
            padding: '5px',
          }}
        >
          <ReservationItemTitel
            reservation={reservation}
            isDeclineViewVisible={isDeclineViewVisible && focusedReservation === ind}
            setIsDeclineViewVisible={setIsDeclineViewVisible}
            ind={ind}
            focusedReservation={focusedReservation}
            variant={variant}
            handleChangeRoute={handleChangeRoute}
            isChangeRoutView={isChangeRouteViewVisible}
            setIsChangeRouteViewVisible={setIsChangeRouteViewVisible}
            handleValidateRoute={handleValidateRoute}
          />
          {variant === SubPmrRoutes.SEARCH && <ReservationClientAndStatus reservation={reservation} />}
          {isDeclineViewVisible && focusedReservation === ind && <Refusal.Banner />}
          {reservation.travels &&
            reservation.travels.map((el, id) => (
              <TravelItem
                selectedIndex={ind}
                travel={el}
                key={generateKey(id.toString())}
                position={id}
                variant={variant}
                formattedCommonDate={getFirstDate()}
                isChangeRoute={isChangeRouteViewVisible}
              />
            ))}
          {isDeclineViewVisible && focusedReservation === ind && <Refusal.Form id={reservation.id || ''} />}
          {isChangeRouteViewVisible && focusedReservation === ind && (
            <ChangeRouteForm
              reservation={reservation.id}
              assistance={allDisbaledAssistanceTypes?.flat().includes(AssistanceType.Full) ? 'Full' : 'Light'}
            />
          )}
        </Stack>
      </>
    );
  };

  if (focusedReservation === ind || focusedReservation === null) {
    return getItem();
  } else {
    return <Box sx={{backgroundColor: 'white', opacity: '0.2', pointerEvents: 'none'}}>{getItem()}</Box>;
  }
};

const ChangeRouteForm = ({
  reservation,
  assistance,
}: {
  reservation: string | null | undefined;
  assistance: 'Full' | 'Light';
}) => {
  const {t} = useTranslation();

  const {refusalData, setRefusalData, reservations, focusedReservation} = React.useContext(ReservationsContext);
  const {reservationRefusalReasons} = React.useContext(CacheContext);
  const {i18n} = useTranslation();
  const {setPrevRoute} = React.useContext(SettingsContext);

  const location = useLocation();
  const [refuseComment, setRefuseComment] = React.useState(refusalData.comment || '');
  const [redirectUrl, setRedirectUrl] = React.useState('');

  const handleChangeRouteDataComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRefuseComment(e.target.value);
  };

  const handleChangeRouteReason = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRefusalData = refusalData;
    newRefusalData.reasonId = e.target.value;
    setRefusalData(() => ({...newRefusalData}));
  };

  const handleChangeRoute = () => {
    const newRefuslaData = refusalData;
    newRefuslaData.comment = refuseComment;

    setPrevRoute(location.pathname);
    setRedirectUrl(
      `/pmr/change-route/${reservation}?reasondId=${newRefuslaData.reasonId}&comment=${encodeURI(
        newRefuslaData.comment,
      )}&assistanceType=${encodeURIComponent(assistance)}`,
    );
  };

  const isSubmittable = (): boolean => {
    const {affectedStations, reasonId} = refusalData;

    if (!reasonId) {
      return false;
    }

    return true;
  };

  return (
    <Paper
      elevation={1}
      sx={{
        border: 'solid 1px #D7D7D7',
        padding: '18px 15px',
      }}
    >
      <Box>
        <Box sx={{display: 'grid', gridTemplateColumns: '0.2fr 1fr auto', gap: '10px'}}>
          <Box>
            <TextField fullWidth select onChange={handleChangeRouteReason} label={'Change reservation reason'}>
              {reservationRefusalReasons
                .sort((a, b) => {
                  const first = handleTranslationObject(i18n, a.description || []).toLocaleUpperCase();
                  const last = handleTranslationObject(i18n, b.description || []).toLocaleUpperCase();
                  return first < last ? -1 : 1;
                })
                .map(el => {
                  return (
                    <MenuItem key={el.id} value={el.id || ''}>
                      {handleTranslationObject(i18n, el.description || [])}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Box>
          <Box>
            <TextField
              fullWidth
              placeholder="Add a note"
              value={refuseComment}
              onChange={handleChangeRouteDataComment}
            ></TextField>
          </Box>
          <Box sx={{display: 'grid', placeItems: 'center'}}>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                color: 'rgba(187, 0, 0, 1)',
                borderColor: 'rgba(187, 0, 0, 1)',
                height: '32px',
                minWidth: '138px',
                width: 'auto',
                fontSize: '14px',
                textTransform: 'none',
              }}
              disabled={!isSubmittable()}
              startIcon={<AltRouteIcon />}
              onClick={() => {
                handleChangeRoute();
              }}
            >
              {t('pmr.change-route')}
            </Button>
          </Box>
        </Box>
      </Box>
      {redirectUrl.length > 0 && <Navigate to={redirectUrl} />}
    </Paper>
  );
};
