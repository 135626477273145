import {useTranslation} from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import './ButtonLoading.scss';
import React from 'react';

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  loadingData: boolean;
  fixed: boolean;
  icon: boolean;
  children: React.ReactNode;
};

export default function ButtonLoading({onClick, loadingData = false, fixed = true, icon = true, children}: Props) {
  const {t} = useTranslation();
  return (
    <LoadingButton
      loading={loadingData}
      loadingPosition="start"
      startIcon={icon && <AddIcon />}
      onClick={onClick}
      variant="contained"
      sx={{textTransform: 'capitalize'}}
      className={`button-loading ${fixed ? 'button-loading-fixed' : ''}`}
    >
      {t('footer.create')}
      {children}
    </LoadingButton>
  );
}
