import React from 'react';

import {useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {Grid, Typography} from '@mui/material';
import {InlineText, FullDetailsButton} from './Banner.styles';
import NotesIcon from '@mui/icons-material/Notes';

import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {SettingsContext} from 'src/context/settings/settings.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {ReservationTypeDto} from 'src/services/models';

export const Banner: React.FC<{mission: MissionDetailDto}> = ({mission}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {setPrevRoute} = React.useContext(SettingsContext);

  const {variables} = React.useContext(EnvironmentContext);

  return (
    <Grid container>
      <Grid item xs={8}>
        <h3>
          {mission.missionType === MissionTypeDto.Stickering
            ? t('mission-details-v2.banner.mission-detail-stickering')
            : t('mission-details-v2.banner.mission-details')}
        </h3>
      </Grid>
      <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
        <FullDetailsButton
          startIcon={<NotesIcon />}
          variant="outlined"
          onClick={() => {
            setPrevRoute(location.pathname);
            navigate(
              variables.USE_PMR_3 ? `/trip-details/${mission.reservationId}` : `/trip-details/${mission.reservationId}`,
            );
          }}
        >
          {t('mission-details-v2.banner.details-button')}
        </FullDetailsButton>
      </Grid>
      <Grid item xs={12} mt={'8px'}>
        <Typography>
          <Grid container>
            <Grid item xs={6}>
              <InlineText>
                <b>{mission.status}</b>
              </InlineText>
            </Grid>
            <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
              <InlineText>
                <b>{mission.owner?.name}</b>
              </InlineText>
            </Grid>
          </Grid>
          <InlineText>
            <b>N</b>° {mission.reservationDisplayId}
          </InlineText>
          <InlineText>
            <b>{mission.startDate ? moment(mission.startDate).format('DD/MM/yyyy') : ''}</b>
          </InlineText>
        </Typography>
      </Grid>
    </Grid>
  );
};
