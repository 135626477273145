import declineIcon from '../../../components/images/ic_decline.svg';
import {useTranslation} from 'react-i18next';
import './cancelButton.scss';

const DialogCancelButton = ({handleClick}: {handleClick: React.MouseEventHandler<HTMLButtonElement>}) => {
  const {t} = useTranslation();
  return (
    <button style={{backgroundColor: 'white', border: 'none'}} onClick={handleClick}>
      <img className="cancel-button-icon" src={declineIcon}></img>
      <span className="cancel-button-text">{t('filter-section.filter-cancel')}</span>
    </button>
  );
};
export default DialogCancelButton;
