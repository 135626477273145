import axios, {AxiosError} from 'axios';
import {ChatMessageDtoPaginatedListResponseDto} from 'src/services/models/chat-message-dto-paginated-list-response-dto';
import { EvacuationDto } from 'src/services/models/evacuation-dto';
import {ExternalCatalogDto} from 'src/services/models/external-catalog-dto';
import {GetMessagesRequestDto} from 'src/services/models/get-messages-request-dto';
import {IncidentDetailsDto} from 'src/services/models/incident-details-dto';
import {IncidentDtoPaginatedListResponseDto} from 'src/services/models/incident-dto-paginated-list-response-dto';
import {SaveIncidentDto} from 'src/services/models/save-incident-dto';
import {SearchIncidentsRequestDto} from 'src/services/models/search-incidents-request-dto';
import {SendMessageRequestDto} from 'src/services/models/send-message-request-dto';

import {v4 as uuidv4} from 'uuid';

export const getCOIReferenceData = async (baseUrl: string): Promise<ExternalCatalogDto[]> => {
  try {
    const coiReferenceData = await axios.get<ExternalCatalogDto[]>(`${baseUrl}/api/reference/catalogs`);
    return coiReferenceData.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const getIncidentList = async (
  baseUrl: string,
  params: SearchIncidentsRequestDto,
): Promise<IncidentDtoPaginatedListResponseDto> => {
  try {
    const incidentList = await axios.post<IncidentDtoPaginatedListResponseDto>(`${baseUrl}/api/incidents`, params);
    return incidentList.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const createIncident = async (baseUrl: string, params: SaveIncidentDto) => {
  const id = uuidv4();

  try {
    await axios.post(`${baseUrl}/api/incidents/${id}`, params);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const getIncident = async (baseUrl: string, id: string) => {
  try {
    const incident = await axios.get<IncidentDetailsDto>(`${baseUrl}/api/incidents/${id}`);
    return incident.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const getIncidentsPhotos = async (baseUrl: string, missionId: string, photoId: string) => {
  try {
    const photo = await axios.get(`${baseUrl}/api/incidents/missions/${missionId}/photos/${photoId}`, {
      responseType: 'blob',
    });
    return photo.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const closeIncident = async (baseUrl: string, id: string, report: string) => {
  try {
    await axios.post(`${baseUrl}/api/incidents/${id}/close`, {report});
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const saveEvacuationMethod = async (baseUrl: string, id: string, vehicles: EvacuationDto[]) => {
  try {
    await axios.post(`${baseUrl}/api/incidents/missions/evacuation`, { id, vehicles });
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const getChats = async (baseUrl: string, body: GetMessagesRequestDto) => {
  try {
    const chats = await axios.post<ChatMessageDtoPaginatedListResponseDto>(
      encodeURI(`${baseUrl}/api/chat/messages?pageSize=10`),
      body,
    );
    return chats.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const sendChats = async (baseUrl: string, body: SendMessageRequestDto) => {
  try {
    const chats = await axios.post(encodeURI(`${baseUrl}/api/chat/messages/send`), body);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};
