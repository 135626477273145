import React from 'react';
import { useTranslation } from 'react-i18next';
import {Box, CircularProgress, Typography} from '@mui/material';
import moment from 'moment';

import useHandleCall from 'src/hooks/useHandleCall';
import {IncidentDtoPaginatedListResponseDto} from 'src/services/models/incident-dto-paginated-list-response-dto';
import {getIncidentList} from './data';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import {CoiIncidentList} from './coiComponents/coiOverviewComponents/coiIncidentList/coiIncidentList';
import { CoiIncidentDetail } from './coiComponents/coiIncidentDetail/coiIncidentDetail';
import { DistrictContext } from 'src/context/district/district.store';
import coiBg from './COI_BG.svg';

export type CoiOverviewStore = {
  incidents?: IncidentDtoPaginatedListResponseDto;
  incidentListController: {
    changeDate: (direction: 'forwards' | 'backwards') => string;
    refetchData: (date: string, district: string, moreResultsToken: string | null | undefined) => Promise<void>;
    isToday: () => boolean;
  };
  date: string;
};

export default function CoiOverview({isFollowing}: {isFollowing: boolean}) {
  const [incidents, setIncidents] = React.useState<undefined | IncidentDtoPaginatedListResponseDto>(undefined);
  const {executeCall, loadingCall} = useHandleCall<IncidentDtoPaginatedListResponseDto>();
  const [selectedIncident, setSelectedIncident] = React.useState<string | null>(null);
  const { selectedDistrict } = React.useContext(DistrictContext);

  //ENV
  const {variables} = React.useContext(EnvironmentContext);

  //Incident list keys
  const [incidentListDate, setIncidentListDate] = React.useState<string>(moment().format('YYYY-MM-DD'));
  const district = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT) || '';

  //Cotroller for all incidentlist functions
  const incidentListController = {
    changeDate: (direction: 'forwards' | 'backwards') => {
      const newDate =
        direction === 'backwards'
          ? moment(incidentListDate).subtract(1, 'days').format('YYYY-MM-DD')
          : moment(incidentListDate)
              .add(incidentListController.isToday() ? 0 : 1, 'days')
              .format('YYYY-MM-DD');
      setIncidentListDate(newDate);
      return newDate;
    },
    isToday: () => {
      return moment(incidentListDate).isSame(moment(), 'day');
    },
    refetchData: async (date: string, district: string, moreResultsToken: string | null | undefined) => {
      const result = await executeCall(
        getIncidentList(variables.BASE_ENDPOINT, {
          date: date,
          district: district,
          followingOnly: isFollowing,
          moreResultsToken: moreResultsToken,
        }),
      );

      if (result) {
        setIncidents(result);
      }
    },
  };

  const intialize = async () => {
    incidentListController.refetchData(incidentListDate, district, null);
  };

  React.useEffect(() => {
    intialize();
  }, [selectedDistrict]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1.2fr 4fr',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
      }}
    >
      <CoiIncidentList incidents={incidents} incidentListController={incidentListController} date={incidentListDate} handleSelectIncident={setSelectedIncident} />
      {loadingCall && <LoadingIncidents />}
      {incidents === undefined || (incidents.totalItems === 0 && <NoSelectedIncident />)}
      {!!selectedIncident ? <CoiIncidentDetail incidentId={selectedIncident} handleCloseIncidentCallback={() => intialize()} /> : <NoSelectedIncident />}
    </Box>
  );
}

const LoadingIncidents = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
        placeItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

const NoSelectedIncident = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
        placeItems: 'center',
      }}
    >
      <img src={coiBg} style={{ width: '120px', height: '80px', marginBottom: '30px' }} alt="no incident selected" />
      <Typography sx={{ fontWeight: 400, fontSize: '20px', fontFamily: "Arial", marginBottom: '10px' }}>{t('coi.incident-select-incident')}</Typography>
      <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px', fontWeight: 400, fontFamily: 'Arial' }}>{t('coi.incident-no-incident-selected')}</Typography>
    </Box>
  );
};
