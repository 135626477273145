//@ts-nocheck
import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {Modal, Button} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import confirmationIcon from 'src/components/images/ic_confirmation-blue.svg';

import './deleteMission.scss';

import {CacheContext} from 'src/context/cache/cache.store';
import {ModelError, ReasonDto, ReasonTypeDto} from 'src/services/models';
import {MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import deleteMissionFromBackend from './deleteMission.service';
import {AxiosError} from 'axios';
import {NetworkContext} from 'src/context/network/network.store';

const LoadingIcon = <LoadingOutlined style={{fontSize: 24}} spin />;

const DeleteMission = ({
  isVisible,
  canceledModal,
  isMultiple,
}: {
  isVisible: boolean;
  canceledModal: () => void;
  isMultiple: boolean;
}) => {
  const {t, i18n} = useTranslation();

  //@ts-ignore
  const {reasons}: {reasons: ReasonDto[]} = React.useContext(CacheContext);
  const {variables} = React.useContext(EnvironmentContext);
  const {selectedMission, clearOnDelete} = React.useContext(MissionContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);

  const [loading, setLoading] = React.useState(false);

  const [filteredReasons, setFilteredReasons] = React.useState<ReasonDto[]>([]);

  //Form variables
  const [deleteReasonDesc, setDeleteReasonDesc] = useState(t('coor-role.deleteModal.select-option'));
  const [isDeleteReservation, setIsDeleteReservation] = useState(false);
  const [deleteReasonId, setDeleteReasonId] = useState<string | undefined>(undefined);

  //Control variable
  const [isDisabled, setIsDisabled] = useState(true);

  const getReasonFromType = () => {
    if (selectedMission) {
      const filteredOnReservationType = reasons.filter(
        el => selectedMission.reservationType === (el.reservationType ? el.reservationType : ''),
      );
      const filteredOnType = filteredOnReservationType.filter(el => el.type === ReasonTypeDto.Delete);
      setFilteredReasons(filteredOnType);
    }
  };

  const cancelDelete = () => {
    canceledModal();
  };

  const deleteMission = async () => {
    setLoading(true);
    setIsDisabled(true);
    deleteMissionFromBackend(variables.BASE_ENDPOINT, {
      withReservation: isDeleteReservation,
      reasonId: deleteReasonId,
      missionId: selectedMission.id,
      reservationType: selectedMission.reservationType,
    })
      .then(_res => {
        setLoading(false);
        clearOnDelete();
        canceledModal();
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          validateNetworkCall(err.response.status, err.response.data as ModelError);
          setLoading(false);
          canceledModal();
        }
      });
    setLoading(false);
  };

  const handleDealeReasonOnChange = ({target: {value}}: {target: {value: any}}) => {
    let id: undefined | string = undefined;
    filteredReasons.forEach(reason => {
      reason.description?.forEach(desc => {
        if (desc.text === value) {
          id = reason.id;
        }
      });
    });
    if (id !== undefined) {
      setDeleteReasonDesc(value);
      setDeleteReasonId(id);
      setIsDisabled(false);
    } else {
      setDeleteReasonDesc(value);
      setDeleteReasonId(undefined);
      setIsDisabled(true);
    }
    //get id from description
  };

  const handleIsDeleteReservation = () => {
    setIsDeleteReservation(!isDeleteReservation);
  };

  const DeleteConfirmationIconText = (
    <>
      <img src={confirmationIcon} alt="confirmation" />
      <span>{t('settings.modal.confirmation-header')}</span>
    </>
  );

  React.useEffect(() => {
    getReasonFromType();
  }, []);

  return (
    <Modal
      width={480}
      title={DeleteConfirmationIconText}
      open={isVisible}
      onOk={deleteMission}
      onCancel={cancelDelete}
      footer={[
        <Button key="submit" type="primary" className="delete-task" onClick={deleteMission} disabled={isDisabled}>
          {t('coor-role.deleteModal.deleteButton')}
        </Button>,
        <Button type="text" key="back" className="complete-task" onClick={cancelDelete}>
          {t('coor-role.deleteModal.cancelButton')}
        </Button>,
      ]}
    >
      {loading ? (
        <LoadingIcon />
      ) : (
        <>
          <p>{t('coor-role.deleteModal.body-msg')}</p>
          <div className="confirmation-row">
            <select value={deleteReasonDesc} className="deleteReason-dropdown" onChange={handleDealeReasonOnChange}>
              <option value="Select delete reason">{t('coor-role.deleteModal.select-option')}</option>
              {filteredReasons?.map(item => (
                <option key={item.id} value={handleTranslationObject(i18n, item.description)}>
                  {handleTranslationObject(i18n, item.description)}
                </option>
              ))}
            </select>
          </div>
          <div className="reservation-section">
            <div className="reservation-row">
              <span className="reser-left">{t('coor-role.deleteModal.reservation-msg')}</span>
              <span className="reser-right">
                <input
                  type="checkbox"
                  disabled={isMultiple || isDisabled}
                  className="checkbox-filter"
                  checked={isDeleteReservation}
                  onChange={handleIsDeleteReservation}
                />
              </span>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default DeleteMission;
