//@ts-nocheck
import React from 'react';

import Box from '@mui/material/Box';
import {CategoryDivider} from 'src/components/coordinator/CategoryDivider/CategoryDivider';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import RouteIcon from 'src/components/icons/Route';
import PersonIcon from 'src/components/icons/Person';
import {TextInput} from 'src/components/inputs/TextInput/TextInput';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import PmrBlueIcon from '../../../components/images/ic_exchange-blue.svg';
import PmrVoyageursIcon from '../../../components/images/ic_colleague_3x-blue.svg';
import {styled} from '@mui/material/styles';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {CacheContext} from 'src/context/cache/cache.store';
import {getDisabilityFromId} from 'src/utils/getDisabilityFromId';
import {DisabilityDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {GroupReservationContext} from '../Reservation/GroupReservation.store';
import {GroupDto} from 'src/services/models/group-dto';
import moment from 'moment';
import {MissionContext} from '../MissionOverview/missions.store';

const NoteArea = styled(TextareaAutosize)({
  border: '2px solid #D7D7D7',
  borderRadius: 3,
  width: '100%',
});

const loadingIcon = <LoadingOutlined style={{fontSize: 48}} spin />;

export default function GroupReservationDetails() {
  const {groupMissionDetail, loadingMissionDetails} = React.useContext(GroupReservationContext);
  const {ageCategories, disabilities} = React.useContext(CacheContext);
  const {clearMissions} = React.useContext(MissionContext);

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  React.useEffect(() => {
    clearMissions();
  }, []);

  const showExtraInfo = (group: GroupDto[], isAgeCategories) => {
    const handicapDetailsList = group.map(el => {
      if (el.quantity !== 0 && el.quantity !== null) {
        return (
          <Box sx={{display: 'flex', gap: '15px'}}>
            <Box sx={{flex: 1}}>
              <TextInput
                id={`id-${el.quantity}-${el.typeId}`}
                style={{color: '#000'}}
                defaultValue={el.quantity ?? 0}
                disabled
              />
            </Box>
            <Box sx={{flex: 1}}>
              <TextInput
                id={`id-${el.typeId}-${el.quantity}`}
                style={{color: '#000'}}
                defaultValue={handleTranslationObject(
                  i18n,
                  getDisabilityFromId(
                    isAgeCategories ? (ageCategories as DisabilityDto[]) : (disabilities as DisabilityDto[]),
                    el.typeId,
                  )?.description || [],
                )}
                disabled
              />
            </Box>
          </Box>
        );
      }
    });
    return (
      <>
        {handicapDetailsList && (
          <Box sx={{display: 'flex', gap: '15px'}}>
            <Box sx={{flex: 1}}>
              <span>{t('mission-details.show-pmr-data.label-quantity')} </span>
            </Box>
            <Box sx={{flex: 1}}>
              <span>
                {isAgeCategories
                  ? t('create-prm-mission.age-cat')
                  : t('mission-details.show-pmr-data.label-type-person')}{' '}
              </span>
            </Box>
          </Box>
        )}
        {handicapDetailsList}
      </>
    );
  };

  return (
    <>
      <div style={{padding: '20px'}}>
        <Button variant="text" startIcon={<ArrowBackIosNewIcon />} onClick={() => history.goBack()}>
          {t('back-btn')}
        </Button>
      </div>
      {!loadingMissionDetails && groupMissionDetail ? (
        <Paper sx={{pt: 1, pr: 2, pl: 2}} elevation={3}>
          <Stack sx={{p: 2, pb: 9}} spacing={2}>
            <Box sx={{width: '49%'}}>
              <TextInput
                style={{color: '#000'}}
                label={t('reservation.form.number.label')}
                defaultValue={groupMissionDetail.id}
                disabled
              />
            </Box>
            {groupMissionDetail.travels?.map(travel => {
              return (
                <>
                  <CategoryDivider icon={<RouteIcon color="#25336C" />} title="Itinerary" style={{fontSize: '1rem'}} />
                  {travel.journeys.map(journey => {
                    const formattedDepartureTime = journey.departureTime?.toString().split('+');
                    return (
                      <Stack spacing={2}>
                        <Box sx={{width: '49%'}}>
                          <TextInput
                            style={{color: '#000'}}
                            label="Train number"
                            defaultValue={journey.trainNumber}
                            disabled
                          />
                        </Box>
                        <Box sx={{display: 'flex', gap: '50px'}}>
                          <Box sx={{flex: 1}}>
                            <TextInput
                              style={{color: '#000'}}
                              label="Departure Station"
                              defaultValue={journey.departureName}
                              disabled
                            />
                          </Box>
                          <Box sx={{flex: 1}}>
                            <TextInput
                              style={{color: '#000'}}
                              label="Departure date"
                              defaultValue={moment(formattedDepartureTime[0])
                                .utcOffset(formattedDepartureTime[1])
                                .format('ddd MMM DD YYYY HH:MM:SS')}
                              disabled
                            />
                          </Box>
                        </Box>
                        <Box>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="help"
                              defaultValue="need help"
                              name="radio-buttons-group"
                              style={{display: 'flex', flexDirection: ' row'}}
                            >
                              <FormControlLabel
                                value="need help"
                                checked={journey.withDepartureAssistance}
                                defaultValue="need help"
                                control={<Radio />}
                                label={t('mission-details.label-need-for-assistance')}
                                size="small"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{display: 'flex', gap: '35px'}}>
                          <Box sx={{width: '49%'}}>
                            <TextInput
                              style={{color: '#000'}}
                              label="Car number"
                              defaultValue={journey.doorNumber || 0}
                              disabled
                            />
                          </Box>
                        </Box>

                        <Box sx={{width: '49%'}}>
                          <TextInput
                            style={{color: '#000'}}
                            label="Arrival Station"
                            defaultValue={journey.arrivalName}
                            disabled
                          />
                        </Box>
                        <Box>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="help"
                              defaultValue="need help"
                              name="radio-buttons-group"
                              style={{display: 'flex', flexDirection: ' row'}}
                            >
                              <FormControlLabel
                                value="need help"
                                checked={journey.withArrivalAssistance}
                                defaultValue="need help"
                                control={<Radio />}
                                label={t('mission-details.label-need-for-assistance')}
                                size="small"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Stack>
                    );
                  })}
                  {travel.traveler.ageCategories.length > 0 && (
                    <>
                      <CategoryDivider
                        icon={<img src={PmrVoyageursIcon} alt="icon" />}
                        title={t('reservation.detail.groupInformation')}
                        style={{fontSize: '1rem'}}
                      />
                      {showExtraInfo(travel.traveler.ageCategories, true)}
                    </>
                  )}
                  {travel.traveler?.disableds.length > 0 && (
                    <>
                      <CategoryDivider
                        icon={<img src={PmrBlueIcon} alt="icon" />}
                        title={t('mission-details.category-type-traveler')}
                        style={{fontSize: '1rem'}}
                      />
                      {showExtraInfo(travel.traveler?.disableds, false)}
                    </>
                  )}
                </>
              );
            })}

            {/*
            {false && (
              <>
                <CategoryDivider
                  icon={<CollegueIcon color="#25336C" />}
                  title={t('reservation.detail.groupInformation')}
                  style={{fontSize: '1rem'}}
                />
                {showGROBO()}
              </>
            )}
            {groupMissionDetail.traveler?.disableds?.length > 0 && (
              <>
                <CategoryDivider
                  icon={<img src={PmrBlueIcon} alt="icon" />}
                  title={t('mission-details.category-type-traveler')}
                  style={{fontSize: '1rem'}}
                />
                {showPMR()}
              </>
            )}
            */}

            <CategoryDivider icon={<PersonIcon color="#25336C" />} title="Client" style={{fontSize: '1rem'}} />
            <Stack spacing={2}>
              <Box sx={{display: 'flex', gap: '50px'}}>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label="Name"
                    defaultValue={groupMissionDetail.client?.firstName}
                    disabled
                  />
                </Box>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label="Last Name"
                    defaultValue={groupMissionDetail.client?.lastName}
                    disabled
                  />
                </Box>
              </Box>
              <Box sx={{display: 'flex', gap: '50px'}}>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label="Phone"
                    defaultValue={groupMissionDetail.client?.phoneNumber}
                    disabled
                  />
                </Box>
                <Box sx={{flex: 1}}>
                  <TextInput
                    style={{color: '#000'}}
                    label="Language"
                    defaultValue={groupMissionDetail.client?.language}
                    disabled
                  />
                </Box>
              </Box>
              <Box>
                <TextInput
                  style={{color: '#000'}}
                  label="E-mail"
                  defaultValue={groupMissionDetail.client?.email}
                  disabled
                />
              </Box>
            </Stack>
          </Stack>
        </Paper>
      ) : (
        <Box sx={{display: 'flex', placeItems: 'center', width: '100%', height: '80%'}}>
          <Spin style={{color: '#25336c', width: '100%'}} indicator={loadingIcon} />
        </Box>
      )}
    </>
  );
}
