import React from 'react';
import {Button} from 'antd';
import {SelectMenu, SelectMenuItem} from 'src/components/coordinator/SidebarMenu/Menu/Menu.styles';
import {useTranslation} from 'react-i18next';
import Icon from 'src/components/icons/Dicos.svg';
import HeaderTitle from 'src/components/Header/HeaderTitle/HeaderTitle';
import i18next from 'i18next';

interface ILoginView {
  handleLogin: () => void;
  loginPossible: boolean;
}

export const LoginView: React.FC<ILoginView> = ({handleLogin, loginPossible}) => {
  const [language, setLanguage] = React.useState(localStorage.getItem('i18nextLng') || 'Fr');

  const getLangLabel = () => {
    let lang = t('choose-language.language-en');
    switch (language) {
      case 'En':
        lang = t('choose-language.language-en');

        break;
      case 'Nl':
        lang = t('choose-language.language-nl');

        break;
      case 'Fr':
        lang = t('choose-language.language-fr');

        break;

      default:
        break;
    }
    return <>{lang}</>;
  };

  React.useEffect(() => {
    const localLanguage = localStorage.getItem('i18nextLng');
    setLanguage(localLanguage || 'Fr');
  }, []);

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value.toString() || 'Fr');
    localStorage.setItem('language', e.target.value || 'Fr');
    i18next.changeLanguage(e.target.value || 'Fr');
  };

  const {t} = useTranslation();

  return (
    <React.Suspense fallback={<div className="centeredContainer">Loading</div>}>
      <header className="header-container">
        <div className="header-row">
          <HeaderTitle pageheading={t('login_page_banner')} />
          <SelectMenu
            disableUnderline
            variant="standard"
            value={language}
            label={() => getLangLabel()}
            onChange={e => {
              handleLanguageChange(e);
            }}
            defaultValue={localStorage.getItem('i18nextLng')}
            sx={{
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
              color: 'white',
            }}
          >
            <SelectMenuItem className="list-item" value="En">
              {' '}
              {t('choose-language.language-en')}{' '}
            </SelectMenuItem>
            <SelectMenuItem className="list-item" value="Nl">
              {' '}
              {t('choose-language.language-nl')}{' '}
            </SelectMenuItem>
            <SelectMenuItem className="list-item" value="Fr">
              {' '}
              {t('choose-language.language-fr')}{' '}
            </SelectMenuItem>
          </SelectMenu>
        </div>
      </header>
      <div className="centeredContainer">
        <div className="centeredBlock small">
          <img className="stateImage" src={Icon} alt="Login" />
          <h1 data-testid="login_page_title">{t('login_page_title')}</h1>
          <p>{t('login_page_text')}</p>
          <Button
            className="primaryButton full"
            disabled={!loginPossible}
            onClick={() => {
              handleLogin();
            }}
          >
            {t('login_page_button')}
          </Button>
        </div>
      </div>
    </React.Suspense>
  );
};
