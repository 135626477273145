import { FC } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CoiMissionImageDialogProps {
  imgUrl: string;
  isOpen: boolean;
  handleClose: () => void;
  isLoading: boolean;
}

export const CoiMissionImageDialog: FC<CoiMissionImageDialogProps> = ({ imgUrl, isOpen, handleClose, isLoading }) => {
  return (
    <Dialog maxWidth="md" fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end', borderBottom: '1px solid #B0BEC5', padding: '10px 20px' }}>
        <CloseIcon sx={{ fontSize: '28px', color: 'black', cursor: 'pointer' }} onClick={handleClose} />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', marginTop: '20px' }}>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <img src={imgUrl} style={{ maxWidth: '100%', maxHeight: '100%' }} />
        )}
      </DialogContent>
    </Dialog>
  );
};
