import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

const expections = ['details', 'reservation', 'change-route', 'create-incident'];

export const useAutoRedirect = <T extends {[key: string]: {[key: string]: string}}>(routes: T, replaceBy: string) => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    //Exceptions
    if (
      location.pathname.includes(expections[0]) ||
      location.pathname.includes(expections[1]) ||
      location.pathname.includes(expections[2]) ||
      location.pathname.includes(expections[3])
    ) {
      return;
    }

    const flattenedRoutes = Object.values(routes)
      .map(el => {
        let map = Object.values(el).map(r => r);
        return map;
      })
      .flat();
    if (!flattenedRoutes.includes(location.pathname.replace('/', ''))) {
      navigate(`/${replaceBy}`);
    }
  }, [location]);
};
