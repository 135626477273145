import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button
} from '@mui/material';

import DirectionsRailwayOutlinedIcon from '@mui/icons-material/DirectionsRailwayOutlined';
import DirectionsBusOutlinedIcon from '@mui/icons-material/DirectionsBusOutlined';
import LocalTaxiOutlinedIcon from '@mui/icons-material/LocalTaxiOutlined';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

import { EvacuationDto } from 'src/services/models/evacuation-dto';
import { EvacuationVehicleTypeDto } from 'src/services/models/evacuation-vehicle-type-dto';

export const CoiEvacuationMethods = ({
  evacuationsTrains,
  evacuationsBuses,
  evacuationsTaxis,
  evacuationsFoot,
  evacuationsEmergencyVehicle,
  handleOpenEvacuationModal
}: {
  evacuationsTrains: EvacuationDto[] | undefined;
  evacuationsBuses: EvacuationDto[] | undefined;
  evacuationsTaxis: EvacuationDto[] | undefined;
  evacuationsFoot: EvacuationDto[] | undefined;
  evacuationsEmergencyVehicle: EvacuationDto[] | undefined;
  handleOpenEvacuationModal: (evacuation: EvacuationDto) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{
      borderRadius: '4px',
      padding: '15px 20px',
    }}>
      <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
        {t('coi.incident-evacuation-methods')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '14px' }}>
          <DirectionsRailwayOutlinedIcon sx={{fontSize: '18px', color: 'black'}} />
          {t('coi.incident-trains')} ({evacuationsTrains?.length || 0})
        </Typography>
        {!!evacuationsTrains?.length ? (
          <CoiEvacuationMethodTable items={evacuationsTrains} type={EvacuationVehicleTypeDto.Train} handleEditEvacuation={handleOpenEvacuationModal} />
        ) : null}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '14px' }}>
          <DirectionsBusOutlinedIcon sx={{fontSize: '18px', color: 'black'}} />
          {t('coi.incident-buses')} ({evacuationsBuses?.length || 0})
        </Typography>
        {!!evacuationsBuses?.length ? (
          <CoiEvacuationMethodTable items={evacuationsBuses} type={EvacuationVehicleTypeDto.Bus} handleEditEvacuation={handleOpenEvacuationModal} />
        ) : null}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '14px' }}>
          <LocalTaxiOutlinedIcon sx={{fontSize: '18px', color: 'black'}} />
          {t('coi.incident-taxis')} ({evacuationsTaxis?.length})
        </Typography>
        {!!evacuationsTaxis?.length ? (
          <CoiEvacuationMethodTable items={evacuationsTaxis} type={EvacuationVehicleTypeDto.Taxi} handleEditEvacuation={handleOpenEvacuationModal} />
        ) : null}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '14px' }}>
          <DirectionsWalkIcon sx={{fontSize: '18px', color: 'black'}} />
          {t('coi.incident-foot')} ({evacuationsFoot?.length})
        </Typography>
        {!!evacuationsFoot?.length ? (
          <CoiEvacuationMethodTable items={evacuationsFoot} type={EvacuationVehicleTypeDto.Foot} handleEditEvacuation={handleOpenEvacuationModal} />
        ) : null}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '14px' }}>
          <AirportShuttleIcon sx={{fontSize: '18px', color: 'black'}} />
          {t('coi.incident-emergency-vehicle')} ({evacuationsEmergencyVehicle?.length})
        </Typography>
        {!!evacuationsEmergencyVehicle?.length ? (
          <CoiEvacuationMethodTable items={evacuationsEmergencyVehicle} type={EvacuationVehicleTypeDto.EmergencyVehicle} handleEditEvacuation={handleOpenEvacuationModal} />
        ) : null}
      </Box>
    </Box>
  )
};


const CoiEvacuationMethodTable = ({ items, type, handleEditEvacuation }: { items: EvacuationDto[], type: EvacuationVehicleTypeDto, handleEditEvacuation: (evacuation: EvacuationDto) => void }) => {
  const { t } = useTranslation();
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: '40%', fontWeight: 700, textWrap: 'nowrap' }}>{t('coi.incident-number')}</TableCell>
          <TableCell sx={{ fontWeight: 700, textWrap: 'nowrap' }}>{t('coi.incident-arrived')}</TableCell>
          <TableCell sx={{ fontWeight: 700, textWrap: 'nowrap' }}>{t('coi.incident-departure-hour')}</TableCell>
          <TableCell sx={{ fontWeight: 700, textWrap: 'nowrap' }}>{t('coi.incident-passengers')}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(evacuation => (
          <TableRow key={`evacuation-${type}-${evacuation.transportId}`}>
            <TableCell>{evacuation.transportId}</TableCell>
            <TableCell>{moment(evacuation.arrivalTime).format('HHumm') !== 'Invalid date' ? moment(evacuation.arrivalTime).format('HHumm') : ''}</TableCell>
            <TableCell>{moment(evacuation.departureTime).format('HHumm') !== 'Invalid date' ? moment(evacuation.departureTime).format('HHumm') : ''}</TableCell>
            <TableCell>{evacuation.numberOfPassengers}</TableCell>
            <TableCell align='right' sx={{ width: '25px' }}>
              <Button disabled={!evacuation.transportId} sx={{
                display: 'flex',
                width: '25px',
                border: 'none',
                backgroundColor: 'transparent',
                padding: 0,
                color: !evacuation.transportId ? '#C4C4C4' : '#0069B4'
              }} onClick={() => handleEditEvacuation(evacuation)}>
                <CreateOutlinedIcon />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
