import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import Paragraph from 'antd/lib/typography/Paragraph';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';




export const NotFoundDialog: React.FC<{open: boolean, close: React.Dispatch<boolean>}> = ({open, close}) => {
    return(
        <Dialog
            open={open}
            data-testid='not-found-snackbar'
        >
            <div style={{display: 'grid', gridTemplateRows: 'auto 1fr auto', padding: '8px'}}>
                <DialogTitle style={{padding: '8px'}}>Server error response 404</DialogTitle>
                <Paragraph
                    style={{padding: '8px'}}
                >
                    The requested resource could not be found. Please refresh the page and try again.
                </Paragraph>
                <Button data-testid='close-button' variant="contained" onClick={() => close(false)}>Close</Button>
            </div>
        </Dialog>
    )
}