import React from 'react';
import {getIncidentList} from 'src/scenes/coi/data';
import {EnvironmentContext} from '../environment/environment.store';
import {SettingsContext} from '../settings/settings.store';
import {IncidentDtoPaginatedListResponseDto} from 'src/services/models/incident-dto-paginated-list-response-dto';
import {SearchIncidentsRequestDto} from 'src/services/models/search-incidents-request-dto';
import {localStorageKeys} from 'src/utils/localStorageKeys';
import useHandleCall from 'src/hooks/useHandleCall';
import moment from 'moment';

export type COIStore = {
  allIncidents: IncidentDtoPaginatedListResponseDto | undefined;
  followingIncidents: IncidentDtoPaginatedListResponseDto | undefined;
};

const CoiIncidentsContext = React.createContext<COIStore>({} as COIStore);

const CoiIncidentProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {variables} = React.useContext(EnvironmentContext);

  const {COI_FEATURE_FLAG} = React.useContext(SettingsContext);

  //call validation
  const {executeCall} = useHandleCall<IncidentDtoPaginatedListResponseDto>();

  //district
  const districtId = localStorage.getItem(localStorageKeys.SELECTED_DISTRICT);

  //IncidentList
  const [allIncidents, setAllIncidents] = React.useState<IncidentDtoPaginatedListResponseDto | undefined>(undefined);
  const [followingIncidents, setFollowingIncidents] = React.useState<IncidentDtoPaginatedListResponseDto | undefined>(
    undefined,
  );

  const init = async () => {
    if (districtId) {
      const inidentsRequestForm: SearchIncidentsRequestDto = {
        date: moment().format('YYYY-MM-DD'),
        district: districtId,
        followingOnly: false,
      };

      const incidentRequestFormFollowing = inidentsRequestForm;
      incidentRequestFormFollowing.followingOnly = true;

      const allIncidentsData = await executeCall(getIncidentList(variables.BASE_ENDPOINT, inidentsRequestForm));
      const followingIncidentsData = await executeCall(
        getIncidentList(variables.BASE_ENDPOINT, incidentRequestFormFollowing),
      );

      setAllIncidents(allIncidentsData as IncidentDtoPaginatedListResponseDto);
      setFollowingIncidents(followingIncidentsData as IncidentDtoPaginatedListResponseDto);
    }
  };

  React.useEffect(() => {
    if (COI_FEATURE_FLAG) init();
  }, []);

  return (
    <CoiIncidentsContext.Provider value={{allIncidents, followingIncidents}}>{children}</CoiIncidentsContext.Provider>
  );
};

export const useCoiIncidentsContext = () => {
  const coiStore = React.useContext(CoiIncidentsContext);
  return coiStore;
};

export default CoiIncidentProvider;
