import {FC, useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Box,
  Typography,
  Button,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
} from '@mui/material';

import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {EnvironmentContext} from 'src/context/environment/environment.store';
import {getIncident, getIncidentsPhotos, saveEvacuationMethod} from 'src/scenes/coi/data';
import useHandleCall from 'src/hooks/useHandleCall';
import {IncidentDetailsDto} from 'src/services/models/incident-details-dto';
import {CoiChatView} from '../coiChatComponents/coiChatView';
import { PresenceTypeDto } from 'src/services/models/presence-type-dto';
import { FoodTypeDto } from 'src/services/models/food-type-dto';
import { EvacuationVehicleTypeDto } from 'src/services/models/evacuation-vehicle-type-dto';
import { CoiMissionDetail } from './coiMissionDetail';
import { CoiCloseIncidentDialog } from '../coiCloseIncidentDialog/coiCloseIncidentDialog';
import { closeIncident } from 'src/scenes/coi/data';
import { TrainDetailsDto } from 'src/services/models/train-details-dto';
import { IncidentStatusDto } from 'src/services/models/incident-status-dto';
import { IncidentMissionStatusDto } from 'src/services/models/incident-mission-status-dto';
import { CoiMissionImageDialog } from '../coiMissionImageDialog/coiMissionImageDialog';
import { useCoiCreateIncidentContext } from 'src/context/coi/coi.createIncident.store';
import { handleTranslationObject } from 'src/utils/handleTranslationObject';
import { FoodDto } from 'src/services/models/food-dto';
import { PresenceDto } from 'src/services/models/presence-dto';
import { AuthContext } from 'src/context/authentication/store.auth';
// import { CoiAssignCoiModal, CoiTargetType, TargetType } from '../coiCreateIncidentComponents/coiAssignModal';
import { DistrictContext } from 'src/context/district/district.store';
import { EvacuationDto } from 'src/services/models/evacuation-dto';
import { CoiEvacuationMethodsDialog } from '../coiEvacuationMethodDialog/coiEvacuationMethodDialog';
import { LocationDetailsDto } from 'src/services/models/location-details-dto';

interface CoiIncidentDetailProps {
  incidentId: string;
  handleCloseIncidentCallback: () => void;
}

export const CoiIncidentDetail: FC<CoiIncidentDetailProps> = ({incidentId, handleCloseIncidentCallback, ...props}) => {
  const { userData } = useContext(AuthContext);
  const { selectedDistrict } = useContext(DistrictContext);
  const { t, i18n } = useTranslation();
  const {executeCall, loadingCall, errorCall} = useHandleCall();
  const coiStore = useCoiCreateIncidentContext();
  const {variables} = useContext(EnvironmentContext);
  const [incident, setIncident] = useState<IncidentDetailsDto>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [isPhotoDialogOpen, setIsPhotoDialogOpen] = useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = useState<any>('');
  const [isEvacuationModalOpen, setIsEvacuationModalOpen] = useState<boolean>(false);
  const [selectedMission, setSelectedMission] = useState<LocationDetailsDto>();
  const [evacuation, setEvacuation] = useState<EvacuationDto>();
  const [isEvacuationSnackbarOpen, setIsEvacuationSnackbarOpen] = useState<boolean>(false);

  // COI Assignment Dialog
  // const [isAssignCoiDialogOpen, setIsAssignCoiDialogOpen] = useState<boolean>(false);
  // const [coiTarget, setCoiTarget] = useState<CoiTargetType | undefined>(undefined);
  // const [assignIndex, setAssigIndex] = useState<number | null>(null);

  const getIncidentById = async () => {
    const data = await executeCall(getIncident(variables.BASE_ENDPOINT, incidentId));
    if (!data) return;
    setIncident(data);
  };

  const getPhotoUrl = async (missionId: string, photoId: string) => {
    const data = await executeCall(getIncidentsPhotos(variables.BASE_ENDPOINT, missionId, photoId));
    if (data) {
      const dataUrl = URL.createObjectURL(data as Blob);
      setPhotoUrl(dataUrl);
    }
  }

  useEffect(() => {
    if (!incidentId) return;
    getIncidentById();
  }, [incidentId]);

  useEffect(() => {
    setIncident(undefined);
  }, [selectedDistrict]);

  if (loadingCall && !incident)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        Loading...
      </Box>
    );

    const handleCloseIncident = async (report: string) => {
      await executeCall(closeIncident(variables.BASE_ENDPOINT, incident?.id || '', report));
      setIsDialogOpen(false);
      if (!errorCall) {
        setIsSnackbarOpen(true);
        getIncidentById();
        handleCloseIncidentCallback();
      }
    }

  const handleOpenPhoto = (missionId:string, photoId: string) => {
    getPhotoUrl(missionId, photoId);
    setIsPhotoDialogOpen(true);
  }

  const handleClosePhotoDialog = () => {
    setIsPhotoDialogOpen(false);
    setPhotoUrl('');
  }

  const trains = incident?.locations?.filter(location => location.type === 'Train') || [];
  const ptcars = incident?.locations?.filter(location => location.type === 'Ptcar') || [];
  const addresses = incident?.locations?.filter(location => location.type === 'Address') || [];
  const coiArrived = incident?.locations?.filter(location => location.coiArrived?.arrivedAtExactLocation) || [];
  const locationsWithPhotos = incident?.locations?.filter(location => !!location?.photos) || [];
  const trainInDistress = incident?.locations?.filter(location => location.type === 'Train' && location.trainInDistress?.isTrainInDistress) || [];
  const evacuations = incident?.locations?.filter(location => location.evacuation?.length) || [];
  const locationsWidthFood = incident?.locations?.filter(location => location.food?.length) || [];

  const foodCounterByType = (type: FoodTypeDto) => {
    let temp = 0;
    locationsWidthFood.forEach(loc => loc.food?.forEach((food: FoodDto) => {
      if (food.type === type && !!food.quantity) {
        temp += food.quantity
      }
    }));
    return temp;
  }

  const presencesCounterByType = (type: PresenceTypeDto) => {
    let temp = 0;
    incident?.locations?.forEach(loc => loc.presences?.forEach((presence: PresenceDto) => {
      if (presence.type === type && !!presence.arrivalAt) {
        temp ++;
      }
    }));
    return temp;
  }

  const translationByCatalogAndSubCatalog = () => {
    const catalog = coiStore.referenceData.find(d => d.id === incident?.catalogId);
    if (catalog) {
      const subCatalog = catalog.subCatalogs.find(d => d.id === incident?.subCatalogId);
      if (subCatalog) {
        return `${handleTranslationObject(i18n, catalog.names)} ${handleTranslationObject(i18n, subCatalog.names)}`;
      }
    }
    return '';
  };

  const checkIfIsCancellable = () => {
    return incident?.status === IncidentStatusDto.Finalized ||
      !!incident?.locations?.filter(l => l.status !== IncidentMissionStatusDto.Completed)?.length ||
      userData?.b_employeeid !== incident?.employeeId;
  }

  const handleOpenEvacuationModal = (mission: LocationDetailsDto, evacuation: EvacuationDto) => {
    setSelectedMission(mission);
    setEvacuation(evacuation);
    setIsEvacuationModalOpen(true);
  }

  const handleCloseEvacuationModal = () => {
    setIsEvacuationModalOpen(false);
    setSelectedMission(undefined);
    setEvacuation(undefined);
  }

  const handleSaveEvacuationMethod = (values: EvacuationDto) => {
    if (selectedMission && selectedMission.evacuation) {
      const temp = [...selectedMission.evacuation].map(e => e.transportId === values.transportId ? { ...e, ...values } : e);
      saveEvacuationMethod(variables.BASE_ENDPOINT, selectedMission.missionId || '', temp)
      .then(() => {
        setIsEvacuationSnackbarOpen(true);
      })
      .catch(error => {
        console.log("🚀 ~ error:", error);
      })
      .finally(() => {
        handleCloseEvacuationModal();
      });
    }
  }

  // const handleCoiAssignDialogTarget = (target: CoiTargetType | undefined, targetIndex: number | null) => {
  //   if (target === undefined) {
  //     setCoiTarget(undefined);
  //     setIsAssignCoiDialogOpen(false);
  //     setAssigIndex(targetIndex);
  //   } else {
  //     setCoiTarget(target);
  //     setIsAssignCoiDialogOpen(true);
  //     setAssigIndex(targetIndex);
  //   }
  // };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        padding: '25px',
        width: '100%',
        height: 'calc(100vh - 64px)'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography sx={{fontWeight: 'bold', fontSize: '28px', marginBottom: '10px'}}>
            {incident?.type} {translationByCatalogAndSubCatalog()}
          </Typography>
          <Box sx={{display: 'flex', gap: '10px'}}>
            {incident?.createdAt ? (
              <Typography sx={{border: '2px solid black', borderRadius: '4px', padding: '2px 4px', fontSize: '14px'}}>
                {moment(incident.createdAt).format('DD.MM.yyyy')}
              </Typography>
            ) : null}
            <Typography sx={{fontSize: '18px'}}>{t('coi.incident-coordinator')} - {incident?.employeeName} - {incident?.displayId}</Typography>
          </Box>
        </Box>
        <Box sx={{display: 'flex', gap: '10px'}}>
          <Button
            disabled
            sx={{border: '2px solid #0069B4', height: '48px', width: '197px', fontFamily: 'Arial', fontWeight: 700}}
            variant="outlined"
          >
            <FlagOutlinedIcon sx={{ fontSize: '24px' }} />
            <Typography sx={{textTransform: 'none', marginLeft: '8px', fontWeight: 700}}>{t('coi.follow-incident')}</Typography>
          </Button>
          <Button
            disabled={checkIfIsCancellable()}
            sx={{border: '2px solid #0069B4', height: '48px', width: '197px', fontFamily: 'Arial', fontWeight: 700}}
            variant="outlined"
            onClick={() => setIsDialogOpen(true)}
          >
            <CheckCircleOutlineOutlinedIcon sx={{ fontSize: '24px' }} />
            <Typography sx={{textTransform: 'none', marginLeft: '8px', fontWeight: 700}}>{t('coi.close-incident')}</Typography>
          </Button>
        </Box>
      </Box>
      <Grid container sx={{ height: '100%', marginTop: '20px', overflowY: 'auto', }}>
        <Grid item xs={7} sx={{ padding: '0px 20px 20px 0', overflowY: 'auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Box sx={{
                border: '1px solid rgba(0, 105, 180, 0.3)',
                borderRadius: '4px',
                marginBottom: '10px',
                padding: '15px 20px',
              }}>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>
                  {t('coi.incident-presences')}
                </Typography>
                <Table size='small'>
                  <TableBody>
                    {!!coiArrived.length ? (
                      <TableRow sx={{ padding: '10px 0px' }}>
                        <TableCell sx={{ fontWeight: 'bold' }}>COI</TableCell>
                        <TableCell align='right'>{coiArrived.length}</TableCell>
                      </TableRow>
                    ) : null}
                    {Object.values(PresenceTypeDto).map(presenceType => (
                      <TableRow key={presenceType}>
                        <TableCell sx={{ fontWeight: 'bold' }}>{presenceType}</TableCell>
                        <TableCell align='right'>{presencesCounterByType(presenceType)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{
                border: '1px solid rgba(0, 105, 180, 0.3)',
                borderRadius: '4px',
                marginBottom: '10px',
                padding: '15px 20px',
              }}>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>{t('coi.incident-photos')}</Typography>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
                  {locationsWithPhotos.map(location => (location.photos?.map(photo =>
                    photo?.thumbnail?.base64Content ? (
                      <Button
                        key={`${location.missionId}-${photo.id}`}
                        sx={{border: 'none', backgroundColor: 'transparent'}}
                        onClick={() => photo?.id && location.missionId && handleOpenPhoto(location.missionId, photo.id)}
                      >
                        <img src={`data:image/jpeg;base64,${photo.thumbnail.base64Content}`} alt={`photo-${photo.id || ''}`} width='50px' />
                      </Button>
                    ) : null)))}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Box sx={{
                border: '1px solid rgba(0, 105, 180, 0.3)',
                borderRadius: '4px',
                marginBottom: '10px',
                padding: '15px 20px',
              }}>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>{t('coi.incident-impacted-trains')}</Typography>
                <Table size='small'>
                  <TableBody>
                    {trainInDistress.map((location: TrainDetailsDto) => (
                      <TableRow key={`location-${location.id}`} sx={{ padding: '10px 0px' }}>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                        {location.trainNumber ? (
                          <a
                            target="_blank"
                            href={`${variables.ATMS_BASE_URL}/trains/${location.trainNumber}/${location.departureDate}`}
                          >
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: '16px',
                                color: '#0069B4',
                                border: '1px solid #707070',
                                padding: '2px 4px',
                                borderRadius: '4px',
                                width: 'fit-content'
                              }}
                            >
                              {location.trainNumber}
                            </Typography>
                          </a>
                        ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{
                border: '1px solid rgba(0, 105, 180, 0.3)',
                borderRadius: '4px',
                marginBottom: '10px',
                padding: '15px 20px',
              }}>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>{t('coi.incident-evacuation')}</Typography>
                <Table size='small'>
                  <TableBody>
                    {Object.values(EvacuationVehicleTypeDto).map(type => (
                      <TableRow key={`evacuation-${type}`}>
                        <TableCell>{type}</TableCell>
                        <TableCell align='right'>{evacuations.reduce((acc, location) => acc + (location.evacuation?.filter(evacuation => evacuation.type === type).length || 0), 0)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{
                border: '1px solid rgba(0, 105, 180, 0.3)',
                borderRadius: '4px',
                marginBottom: '10px',
                padding: '15px 20px',
              }}>
                <Typography sx={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>{t('coi.incident-food')}</Typography>
                <Table size='small'>
                  <TableBody>
                    {Object.values(FoodTypeDto).sort().map(type => (
                      <TableRow key={`food-${type}`}>
                        <TableCell sx={{ fontWeight: 'bold' }}>{type}</TableCell>
                        <TableCell align='right'>{foodCounterByType(type)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
          {!!trains.length ? (
            <CoiMissionDetail
              locations={trains}
              title={t('coi.incident-impacted-trains')}
              handleOpenPhoto={handleOpenPhoto}
              handleOpenEvacuationModal={handleOpenEvacuationModal}
              // handleOpenCoiTarget={(targetIndex: number) => handleCoiAssignDialogTarget({ type: TargetType.PT_CAR, payload: undefined }, targetIndex)}
            />
          ) : null}
          {!!ptcars.length ? (
            <CoiMissionDetail
              locations={ptcars}
              title={t('coi.incident-impacted-ptcars')}
              handleOpenPhoto={handleOpenPhoto}
              handleOpenEvacuationModal={handleOpenEvacuationModal}
              // handleOpenCoiTarget={(targetIndex: number) => handleCoiAssignDialogTarget({ type: TargetType.PT_CAR, payload: undefined }, targetIndex)}
            />
          ) : null}
          {!!addresses.length ? (
            <CoiMissionDetail
              locations={addresses}
              title={t('coi.incident-impacted-locations')}
              handleOpenPhoto={handleOpenPhoto}
              handleOpenEvacuationModal={handleOpenEvacuationModal}
              // handleOpenCoiTarget={(targetIndex: number) => handleCoiAssignDialogTarget({ type: TargetType.PT_CAR, payload: undefined }, targetIndex)}
            />
          ) : null}
        </Grid>
        <Grid item xs={5}>
          <CoiChatView incident={incident} />
        </Grid>
      </Grid>
      <CoiCloseIncidentDialog
        isOpen={isDialogOpen}
        handleSubmit={handleCloseIncident}
        handleClose={() => setIsDialogOpen(false)}
        subtitle={`${t("coi.incident-coordinator")} - ${incident?.employeeName}`}
      />
      <CoiMissionImageDialog
        imgUrl={photoUrl}
        isOpen={isPhotoDialogOpen}
        handleClose={handleClosePhotoDialog}
        isLoading={loadingCall}
      />
      <CoiEvacuationMethodsDialog
        isOpen={isEvacuationModalOpen && evacuation !== undefined}
        evacuation={evacuation}
        handleSubmit={handleSaveEvacuationMethod}
        handleClose={handleCloseEvacuationModal}
      />
      {/* <CoiAssignCoiModal
        open={isAssignCoiDialogOpen && coiTarget !== undefined}
        onClose={() => handleCoiAssignDialogTarget(undefined, null)}
        assignIndex={assignIndex}
        target={coiTarget}
        {...props}
      /> */}
      <Snackbar
        open={isSnackbarOpen}
        key='close-incident-snackbar'
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        message={t('coi.incident-closed')}
        sx={{
          backgroundColor: 'white',
          borderRadius: '4px',
          minWidth: '350px',
          border: '1px solid #D7D7D7',
          padding: '20px',
          boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.2)',
          display: 'flex',
          justifyContent: 'flex-start'
        }}
        >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <CheckCircleOutlineIcon sx={{ fontSize: '28px', color: 'green', marginRight: '10px' }} />
          <Typography sx={{ fontSize: '16px', fontFamily: 'Arial' }}>{t('coi.incident-closed')}</Typography>
        </Box>
      </Snackbar>
      <Snackbar
        open={isEvacuationSnackbarOpen}
        key='close-evacuation-snackbar'
        autoHideDuration={4000}
        onClose={() => setIsEvacuationSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        message={t('coi.evacuation-method-saved')}
        sx={{
          backgroundColor: 'white',
          borderRadius: '4px',
          minWidth: '350px',
          border: '1px solid #D7D7D7',
          padding: '20px',
          boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.2)',
          display: 'flex',
          justifyContent: 'flex-start'
        }}
        >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <CheckCircleOutlineIcon sx={{ fontSize: '28px', color: 'green', marginRight: '10px' }} />
          <Typography sx={{ fontSize: '16px', fontFamily: 'Arial' }}>{t('coi.evacuation-method-saved')}</Typography>
        </Box>
      </Snackbar>
    </Box>
  );
};
