import * as React from 'react';
import {ModelError} from 'src/services/models';
import {AuthContext} from '../authentication/store.auth';
import {ErrorDialog, NotFoundDialog, TimeOutDialog, Succes} from './components';

type INetworkError = 200 | 204 | 400 | 401 | 403 | 404 | 408 | 500 | number;

type INetworkStore = {
  validateNetworkCall: (error: INetworkError, description?: ModelError) => void;
};

export const NetworkContext = React.createContext({} as INetworkStore);

const NetworkProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [hasError, setHasError] = React.useState(false);
  const [hasNotFound, setHasNotFound] = React.useState(false);
  const [hasTimeOut, setHasTimeOut] = React.useState(false);
  const [hasSucces, setHasSucess] = React.useState(false);

  const [errorWithValidationErrors, setErrorWithValidationForms] = React.useState<ModelError>({} as ModelError);

  const {logOut} = React.useContext(AuthContext);

  const clearErrors = () => {
    setHasError(false);
    setHasNotFound(false);
    setHasSucess(false);
    setHasTimeOut(false);
  };

  const validateNetworkCall = (error: INetworkError, description?: ModelError) => {
    if (error == 200 || error == 204) {
      clearErrors();
      setHasSucess(true);
    } else if (error == 403 || error == 401) {
      clearErrors();
      logOut();
    } else if (error == 400) {
      clearErrors();
      if (description?.message) {
        setErrorWithValidationForms(description);
      } else {
        setErrorWithValidationForms({code: '400', message: ''});
      }
      setHasError(true);
    } else if (error == 408) {
      clearErrors();
      setHasTimeOut(true);
    } else if (error == 404) {
      clearErrors();
      setHasNotFound(true);
    } else if (error == 500) {
      clearErrors();
      if (description) {
        setErrorWithValidationForms(description);
      } else {
        setErrorWithValidationForms({code: '500', message: 'NO ERROR MESSAGE RETURNED FROM SERVER'});
      }

      setHasError(true);
    }
  };

  return (
    <NetworkContext.Provider value={{validateNetworkCall}}>
      {hasError ? <ErrorDialog open={hasError} close={setHasError} error={errorWithValidationErrors} /> : null}
      {hasNotFound ? <NotFoundDialog open={hasNotFound} close={setHasNotFound} /> : null}
      {hasTimeOut ? <TimeOutDialog open={hasTimeOut} close={setHasTimeOut} /> : null}
      {children}
      <Succes open={hasSucces} close={setHasSucess} />
    </NetworkContext.Provider>
  );
};

export default NetworkProvider;
