import './DialogOkButton.scss';
const DialogOkButton = ({
  title,
  onClick,
}: {
  title: string | number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button className="dialog-ok-text" onClick={onClick}>
      {title}
    </button>
  );
};
export default DialogOkButton;
