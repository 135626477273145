import React from 'react'

const ColleagueIcon = () => (
    <svg id="ic_colleague_3x" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect id="Rectangle_1982" data-name="Rectangle 1982" width="24" height="24" fill="none"/>
        <path id="Path_5727" data-name="Path 5727" d="M15.84,14.64a2.33,2.33,0,0,0-.32-.31,3.6,3.6,0,0,0-1.09-.67,3.4,3.4,0,0,0-1.06-.23,2.83,2.83,0,0,0,.81-.57,2.772,2.772,0,1,0-3.92,0,2.83,2.83,0,0,0,.81.57,3.65,3.65,0,0,0-2.31,1.05A3.7,3.7,0,0,0,8,15.64a3.5,3.5,0,0,0-.29,1.42v2.77h9.06V17.06a3.6,3.6,0,0,0-.24-1.32,3.73,3.73,0,0,0-.69-1.1Zm-4.5-.09H13.1a2.4,2.4,0,0,1,1.78.73,2.44,2.44,0,0,1,.74,1.78V18.7H8.82V17.06a2.49,2.49,0,0,1,.2-1,2.44,2.44,0,0,1,.55-.8,2.61,2.61,0,0,1,.81-.54,2.45,2.45,0,0,1,.96-.17Zm-.27-2.5a1.55,1.55,0,0,1-.49-1.15,1.53,1.53,0,0,1,.49-1.16,1.58,1.58,0,0,1,1.15-.48,1.64,1.64,0,0,1,1.64,1.64,1.58,1.58,0,0,1-.48,1.15,1.61,1.61,0,0,1-2.31,0Z" fill="#333"/>
        <path id="Path_5728" data-name="Path 5728" d="M21.84,10.79a3.77,3.77,0,0,0-.78-1.15,3.82,3.82,0,0,0-1.16-.78,3.5,3.5,0,0,0-.9-.25,2.2,2.2,0,0,0,.67-.47,2.28,2.28,0,0,0,.66-1.64,2.3,2.3,0,0,0-.66-1.65,2.34,2.34,0,0,0-4,1.65,2.26,2.26,0,0,0,.68,1.64,2.47,2.47,0,0,0,.67.47,3.37,3.37,0,0,0-1.29.48h-.06v1.45l.21-.21A2.28,2.28,0,0,1,17.6,9.7h.88a2.44,2.44,0,0,1,1.78.74A2.4,2.4,0,0,1,21,12.22V13H17.5v1.13h4.62V12.22a3.58,3.58,0,0,0-.28-1.43Zm-5-4.29a1.2,1.2,0,0,1,2-.85,1.12,1.12,0,0,1,.35.85,1.2,1.2,0,0,1-2,.84,1.15,1.15,0,0,1-.35-.84Z" fill="#333"/>
        <path id="Path_5729" data-name="Path 5729" d="M3,12.22a2.4,2.4,0,0,1,.73-1.78A2.44,2.44,0,0,1,5.52,9.7H6.4a2.28,2.28,0,0,1,1.68.66l.21.21V9.12H8.23a3.37,3.37,0,0,0-1.29-.48,2.47,2.47,0,0,0,.67-.47,2.33,2.33,0,0,0,0-3.29A2.32,2.32,0,0,0,3.64,6.5,2.28,2.28,0,0,0,4.3,8.14a2.2,2.2,0,0,0,.7.47,3.5,3.5,0,0,0-.87.25,3.82,3.82,0,0,0-1.16.78,3.77,3.77,0,0,0-.78,1.15,3.58,3.58,0,0,0-.29,1.43v1.89H6.5V13H3ZM5.12,5.65A1.2,1.2,0,0,1,6.81,7.34,1.12,1.12,0,0,1,6,7.69,1.2,1.2,0,0,1,4.77,6.5a1.12,1.12,0,0,1,.35-.85Z" fill="#333"/>
    </svg>
)

export default ColleagueIcon