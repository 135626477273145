//@ts-nocheck
import React from 'react';
import {JourneyItem} from 'src/scenes/coordinator/MissionDetailsNew/components/Journey/JourneyItem';
import {getTime} from 'src/scenes/coordinator/MissionDetailsNew/components/Journey/util';
import {ReservationTypeDto} from 'src/services/models';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {ReservationStatusDto} from 'src/services/models/reservation-status-dto';

import {ReservationJourneyDto} from 'src/services/models/reservation-journey-dto';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {Box, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {StatusItem} from 'src/components/coordinator/MissionItem/StatusItem';
import {GroupNameChip} from 'src/scenes/coordinator/MissionDetailsNew/components/Chips';
import TrainIcon from 'src/icons/TrainIcon';
import PrintStickerIcon from 'src/components/icons/PrintStickerIcon';
import DoorIcon from 'src/icons/DoorIcon';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';
import {MissionDto} from 'src/services/models/mission-dto';
import {DisabledJourneyDto} from 'src/services/models/disabled-journey-dto';
import {JourneyDto} from 'src/services/models/journey-dto';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';

export const ExtendedJourneyItemGroup: React.FC<{
  reservation: ReservationDto | undefined;
  journ: ReservationJourneyDto | DisabledJourneyDto | JourneyDto;
  variant: ReservationTypeDto | undefined;
  journey: ReservationJourneyDto[];
  status: MissionStatusDto | ReservationStatusDto | undefined;
  ind: number;
  type: MissionTypeDto | ReservationTypeDto | undefined;
  hasWheelChairDep: boolean;
  hasWheelChairArr: boolean;
}> = ({journ, variant, journey, ind, type, reservation, hasWheelChairArr, hasWheelChairDep}) => {
  const {t} = useTranslation();

  //@ts-ignore
  const missionForJourney: Array<MissionDto> = reservation.missions.filter((el: any) => el.journeyId === journ.id);

  const departureMission = missionForJourney.find(el => el.missionType === MissionTypeDto.Departure);
  const arrivalMission = missionForJourney.find(el => el.missionType === MissionTypeDto.Arrival);
  const getLocation = () => journ.coachNumber || '';

  const isTaxi = journey?.transportType === TransportTypeDto.Taxi;

  return (
    <>
      <JourneyItem
        hasStickerFinished={false}
        isSticker={false}
        isEndOfLine={false}
        isFullStop={true}
        location={departureMission?.coachNumber && departureMission?.coachNumber.replaceAll(',', ' ')}
        requiresAssistance={departureMission.withDepartureAssistance}
        stationName={journ.departureName || ''}
        time={getTime(journ.departureTime)}
        trainNumber={journ.trainNumber}
        wheelchair={hasWheelChairDep}
        isCompleteOverview={true}
        isGrobo={variant === ReservationTypeDto.Group || type === MissionTypeDto.Stickering}
        isExtended={true}
        door={departureMission?.doorNumber}
        isTaxi={isTaxi}
      />
      {departureMission && (
        <Box
          sx={{
            backgroundColor: '#F7F7F7',
            height: '70px',
            width: '90%',
            marginLeft: '10%',
            marginBottom: '15px',
            padding: '15px',
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={{fontWeight: 'bold'}}>
                {departureMission.owner && departureMission.owner?.name
                  ? `${t('home.coordinator.assigned-to')} ${departureMission.owner && departureMission.owner?.name}`
                  : `${t('not-assigned')}`}
              </Typography>
              {departureMission.status && <StatusItem status={departureMission.status as MissionStatusDto} />}
            </Grid>
            <Grid item xs={6}>
              <Grid container alignItems={'center'} justifyContent={'flex-end'} gap={1}>
                <Grid item width={'auto'}>
                  {type === MissionTypeDto.Stickering ? <PrintStickerIcon /> : null}
                </Grid>
                <Grid item width={'auto'}>
                  <TrainIcon />
                  {departureMission.coachNumber && departureMission.coachNumber.replaceAll(',', ' ')}
                </Grid>
                <Grid item width={'auto'}></Grid>
                <DoorIcon />
                {departureMission.doorNumber && <Box sx={{fontWeight: 'bold'}}>{missionForJourney[1]?.doorNumber}</Box>}
                <Grid item xs={4}>
                  <GroupNameChip groupName={reservation?.client?.description || ''} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      {
        <JourneyItem
          hasStickerFinished={false}
          isSticker={false}
          isEndOfLine={journey.length - 1 - ind === 0}
          isFullStop={journey.length - 1 - ind === 0}
          location={arrivalMission?.coachNumber && arrivalMission?.coachNumber.replaceAll(',', ' ')}
          requiresAssistance={arrivalMission?.withArrivalAssistance}
          stationName={journ.arrivalName || ''}
          time={getTime(journ.arrivalTime)}
          trainNumber={''}
          wheelchair={hasWheelChairArr}
          isCompleteOverview={true}
          isGrobo={variant === ReservationTypeDto.Group || type === MissionTypeDto.Stickering}
          isExtended={true}
          door={arrivalMission?.doorNumber}
          isTaxi={isTaxi}
        />
      }
      {arrivalMission && (
        <Box
          sx={{
            backgroundColor: '#F7F7F7',
            height: '70px',
            width: '90%',
            marginLeft: '10%',
            marginBottom: '15px',
            padding: '15px',
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={{fontWeight: 'bold'}}>
                {arrivalMission?.owner && arrivalMission?.owner?.name
                  ? `${t('home.coordinator.assigned-to')} ${arrivalMission?.owner && arrivalMission?.owner?.name}`
                  : `${t('not-assigned')}`}
              </Typography>
              {arrivalMission?.status && <StatusItem status={arrivalMission?.status as MissionStatusDto} />}
            </Grid>
            <Grid item xs={6}>
              <Grid container alignItems={'center'} justifyContent={'flex-end'} gap={1}>
                <Grid item width={'auto'}>
                  {type === MissionTypeDto.Stickering ? <PrintStickerIcon /> : null}
                </Grid>
                <Grid item width={'auto'}>
                  <TrainIcon />
                  {arrivalMission?.coachNumber && arrivalMission?.coachNumber.replaceAll(',', ' ')}
                </Grid>
                <Grid item width={'auto'}></Grid>
                <DoorIcon />
                {arrivalMission?.doorNumber && <Box sx={{fontWeight: 'bold'}}>{arrivalMission?.doorNumber}</Box>}
                <Grid item xs={4}>
                  <GroupNameChip groupName={reservation?.client?.description || ''} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

const forLastJourney: React.FC<{
  reservation: ReservationDto | undefined;
  journ: ReservationJourneyDto | DisabledJourneyDto | JourneyDto;
  variant: ReservationTypeDto | undefined;
  journey: ReservationJourneyDto[];
  status: MissionStatusDto | ReservationStatusDto | undefined;
  ind: number;
  type: MissionTypeDto | ReservationTypeDto | undefined;
  hasWheelChairDep: boolean;
  hasWheelChairArr: boolean;
}> = ({journ, variant, journey, ind, type, reservation, hasWheelChairArr, hasWheelChairDep}) => {
  return (
    <>
      <JourneyItem
        hasStickerFinished={false}
        isSticker={false}
        isEndOfLine={false}
        isFullStop={true}
        location={departureMission?.coachNumber && departureMission?.coachNumber.replaceAll(',', ' ')}
        requiresAssistance={departureMission.withDepartureAssistance}
        stationName={journ.departureName || ''}
        time={getTime(journ.departureTime)}
        trainNumber={journ.trainNumber}
        wheelchair={hasWheelChairDep}
        isCompleteOverview={true}
        isGrobo={variant === ReservationTypeDto.Group || type === MissionTypeDto.Stickering}
        isExtended={true}
        door={departureMission?.doorNumber}
        isTaxi={isTaxi}
      />
      {departureMission && (
        <Box
          sx={{
            backgroundColor: '#F7F7F7',
            height: '70px',
            width: '90%',
            marginLeft: '10%',
            marginBottom: '15px',
            padding: '15px',
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={{fontWeight: 'bold'}}>
                {departureMission.owner && departureMission.owner?.name
                  ? `${t('home.coordinator.assigned-to')} ${departureMission.owner && departureMission.owner?.name}`
                  : `${t('not-assigned')}`}
              </Typography>
              {departureMission.status && <StatusItem status={departureMission.status as MissionStatusDto} />}
            </Grid>
            <Grid item xs={6}>
              <Grid container alignItems={'center'} justifyContent={'flex-end'} gap={1}>
                <Grid item width={'auto'}>
                  {type === MissionTypeDto.Stickering ? <PrintStickerIcon /> : null}
                </Grid>
                <Grid item width={'auto'}>
                  <TrainIcon />
                  {departureMission.coachNumber && departureMission.coachNumber.replaceAll(',', ' ')}
                </Grid>
                <Grid item width={'auto'}></Grid>
                <DoorIcon />
                {departureMission.doorNumber && <Box sx={{fontWeight: 'bold'}}>{missionForJourney[1]?.doorNumber}</Box>}
                <Grid item xs={4}>
                  <GroupNameChip groupName={reservation?.client?.description || ''} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      {
        <JourneyItem
          hasStickerFinished={false}
          isSticker={false}
          isEndOfLine={journey.length - 1 - ind === 0}
          isFullStop={journey.length - 1 - ind === 0}
          location={arrivalMission?.coachNumber && arrivalMission?.coachNumber.replaceAll(',', ' ')}
          requiresAssistance={arrivalMission?.withArrivalAssistance}
          stationName={journ.arrivalName || ''}
          time={getTime(journ.arrivalTime)}
          trainNumber={''}
          wheelchair={hasWheelChairArr}
          isCompleteOverview={true}
          isGrobo={variant === ReservationTypeDto.Group || type === MissionTypeDto.Stickering}
          isExtended={true}
          door={arrivalMission?.doorNumber}
          isTaxi={isTaxi}
        />
      }
      {arrivalMission && (
        <Box
          sx={{
            backgroundColor: '#F7F7F7',
            height: '70px',
            width: '90%',
            marginLeft: '10%',
            marginBottom: '15px',
            padding: '15px',
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={{fontWeight: 'bold'}}>
                {arrivalMission?.owner && arrivalMission?.owner?.name
                  ? `${t('home.coordinator.assigned-to')} ${arrivalMission?.owner && arrivalMission?.owner?.name}`
                  : `${t('not-assigned')}`}
              </Typography>
              {arrivalMission?.status && <StatusItem status={arrivalMission?.status as MissionStatusDto} />}
            </Grid>
            <Grid item xs={6}>
              <Grid container alignItems={'center'} justifyContent={'flex-end'} gap={1}>
                <Grid item width={'auto'}>
                  {type === MissionTypeDto.Stickering ? <PrintStickerIcon /> : null}
                </Grid>
                <Grid item width={'auto'}>
                  <TrainIcon />
                  {arrivalMission?.coachNumber && arrivalMission?.coachNumber.replaceAll(',', ' ')}
                </Grid>
                <Grid item width={'auto'}></Grid>
                <DoorIcon />
                {arrivalMission?.doorNumber && <Box sx={{fontWeight: 'bold'}}>{arrivalMission?.doorNumber}</Box>}
                <Grid item xs={4}>
                  <GroupNameChip groupName={reservation?.client?.description || ''} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
