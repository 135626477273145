import {Box, Divider, Grid, Paper} from '@mui/material';
import React from 'react';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';

import {useTranslation} from 'react-i18next';

//Components
import {TravelerCombinationInformation} from './TravelerCombinationInformation';
import {TripTravelerCombinationInformation} from 'src/scenes/coordinator/TripDetailsNew/components/Details/TripTravelerCombinationInformation';
import {TravelersAssistanceInformation} from './TravelerAssistanceInformation';
import {TravelerInformationBanner} from './TravelerInformationBanner';
import {ReservationTypeDto} from 'src/services/models';
import {CastBanner} from './CastBanner';
import {TrainMemberInformation} from './TrainMemberInformation';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {ReservationTravelDto} from 'src/services/models/reservation-travel-dto';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';
import {CarIcon} from 'src/scenes/change-route/NewRoutePlanner/NewRoutePlanner';

//Traveler assistance information

export const OverviewOfTravelers: React.FC<{
  mission: MissionDetailDto;
  reservationType: ReservationTypeDto;
  isReservation: boolean;
  reservation?: ReservationDto;
}> = ({mission, reservationType, isReservation, reservation}) => {
  const {t} = useTranslation();

  //@ts-ignore
  const isTaxi = mission.journey?.transportType === TransportTypeDto.Taxi;

  if (isReservation && reservation !== undefined) {
    return (
      <Paper
        elevation={1}
        sx={{
          borderRadius: '12px',
          padding: '24px',
          border: 'solid 1px #D7D7D7',
        }}
      >
        <Grid container>
          <TravelerInformationBanner mission={reservation} />
          {reservationType !== ReservationTypeDto.Disabled ? (
            <TripTravelerCombinationInformation
              t={t}
              travel={reservation.travels ? reservation.travels[0] : ({} as ReservationTravelDto)}
            />
          ) : null}

          <TravelersAssistanceInformation
            t={t}
            travel={reservation.travels ? reservation.travels[0] : ({} as ReservationTravelDto)}
          />
        </Grid>
      </Paper>
    );
  }

  const showTitle = () => {
    if (mission.driver || mission.trainManager) {
      if (
        mission.driver?.phoneNumber ||
        mission.driver?.name ||
        mission.trainManager?.name ||
        mission.trainManager?.phoneNumber
      ) {
        return <CastBanner />;
      }
    }

    return null;
  };

  return (
    <Paper
      elevation={1}
      sx={{
        borderRadius: '12px',
        padding: '24px',
        border: 'solid 1px #D7D7D7',
      }}
    >
      {isTaxi && (
        <>
          <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '25px', marginBottom: '25px'}}>
            <Box
              sx={{
                display: 'grid',
                padding: '10px',
                gap: '20px',
                gridTemplateColumns: '40px 1fr',
                backgroundColor: '#F9F1E7',
                height: '72px',
                borderRadius: '8px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  placeItems: 'center',
                  backgroundColor: 'white',
                  width: '40px',
                  height: '40px',
                }}
              >
                <CarIcon />
              </Box>
              <Box>
                <p style={{margin: '0px'}}>
                  <span style={{color: '#C67E1E', fontWeight: 'bold'}}>{mission.journey?.transportId}</span>
                </p>
                {/*@ts-ignore */}
                <p style={{margin: '0px'}}>{mission.journey.transportPhoneNumber}</p>
              </Box>
            </Box>
            <Box sx={{display: 'grid', gridTemplateRows: 'auto auto'}}>
              <Box>
                <p>
                  <b>Departure Info</b>
                </p>
                {/*@ts-ignore */}

                <p>{mission.journey.departureInfo}</p>
              </Box>
              <Box>
                <p>
                  <b>Arrival Info</b>
                </p>
                {/*@ts-ignore */}

                <p>{mission.journey.arrivalInfo}</p>
              </Box>
            </Box>
          </Box>
          <Divider sx={{width: '100%'}} />
        </>
      )}
      <Grid container>
        {showTitle()}
        <TrainMemberInformation driver={mission.driver} manager={mission.trainManager} />
        <TravelerInformationBanner mission={mission} />

        {reservationType !== ReservationTypeDto.Disabled ? (
          <TravelerCombinationInformation mission={mission} t={t} />
        ) : null}
        <TravelersAssistanceInformation mission={mission} t={t} />
      </Grid>
    </Paper>
  );
};
