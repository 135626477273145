import { AxiosError } from 'axios';
import * as React from 'react';
import { CacheContext } from 'src/context/cache/cache.store';
import { EnvironmentContext } from 'src/context/environment/environment.store';
import { NetworkContext } from 'src/context/network/network.store';
import { StationPresencesDto } from 'src/services/models/station-presences-dto';
import { fetchCheckinOverview } from './checkinOverview.service';
import { formControlReducer, initialFormControlState, IFormControlActions, FormControlActionsType } from './formControlDispatch';

interface IOverviewCheckinStore {
    STATE: {
        formControls: typeof initialFormControlState,
        results: Array<StationPresencesDto>
    }
    METHODS: {
        setFormControls:  React.Dispatch<IFormControlActions>,
        getCheckinOverview: (formVariables: typeof initialFormControlState) => void,
    }
}

export const OverviewCheckinContext = React.createContext({} as IOverviewCheckinStore)

const OverViewCheckinProvider: React.FC<{children: React.ReactNode}> = ({children}) => {

    const [results, setResults] = React.useState([] as Array<StationPresencesDto>)

    const [formControls, setFormControls] = React.useReducer(formControlReducer, initialFormControlState)

    const {variables} = React.useContext(EnvironmentContext)
    const {stations} = React.useContext(CacheContext)
    const {validateNetworkCall} = React.useContext(NetworkContext)

    const getCheckinOverview = (formVariables: typeof initialFormControlState) => {
        setFormControls({type: FormControlActionsType.LOADING, payload: true})
        fetchCheckinOverview(variables.BASE_ENDPOINT, formVariables, stations).then(res => {
            if(res){
                setResults(res)
                setFormControls({type: FormControlActionsType.LOADING, payload: false})
                setFormControls({type: FormControlActionsType.FIRST_SEARCH, payload: false})
                 
            }
        }).catch((err: AxiosError) => {
            if(err.response && err.response.data){
                validateNetworkCall(err.response?.status, err.response?.data)
            } else {
                validateNetworkCall(404)
            }
            setFormControls({type: FormControlActionsType.LOADING, payload: false})
        })
    }

    React.useEffect(() => {
        //@ts-ignore
        const initialStation = stations.sort((a,b) => a.name[2].text < b.name[2].text ? -1 : 1)[0].name[2].text
        setFormControls({type: FormControlActionsType.STATION_FILTER, payload: initialStation})
    },[])
    const STATE = {
        formControls,
        results,
    }

    const METHODS = {
        setFormControls,
        getCheckinOverview,
    }

    return(
        <OverviewCheckinContext.Provider value={{STATE, METHODS}}>{children}</OverviewCheckinContext.Provider>
    )
}

export default OverViewCheckinProvider;