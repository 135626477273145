// @ts-nocheck
import React, {useState} from 'react';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';

import pmrBlueIcon from '../../../components/images/ic_exchange-blue.svg';
import pmrWhiteIcon from '../../../components/images/ic_exchange-white.svg';
import groboBlueIcon from '../../../components/images/ic_colleague-blue.svg';
import groboWhiteIcon from '../../../components/images/ic_colleague-white.svg';
import DialogCancelButton from '../../../components/buttons/DialogCancelButton';
import DialogOkButton from '../../../components/buttons/DialogOkButton';
import './ReservationModel.scss';
import {ReservationTypeDto} from 'src/services/models';
import {MissionTypeDto} from 'src/services/models/mission-type-dto';
import {MissionStatusDto} from 'src/services/models/mission-status-dto';
import {IMissionFilter} from '../MissionOverview/missions.store';
import PrintStickerIcon from 'src/components/icons/PrintStickerIcon';
import TrainSticker from 'src/components/icons/TrainSticker';

const ReservationFilterModel = ({
  clickedFilterChoosen,
  filterClosed,
  show,
  selectedTab,
  onResetFilter,
  handleChangeFilter,
  activeFilter,
}) => {
  const [isButtonActive, setisButtonActive] = useState(() => {
    if (activeFilter.reservationType === ReservationTypeDto.Group) {
      return 2;
    } else if (activeFilter.reservationType === ReservationTypeDto.Disabled) {
      return 3;
    } else if (activeFilter.reservationType === MissionTypeDto.Stickering) return 1;
  });
  const [isCompleted, setIsCompleted] = useState<boolean>(activeFilter.completed === MissionStatusDto.Completed);
  const [isStarted, setIsStarted] = useState<boolean>(activeFilter.started === MissionStatusDto.Started);
  const [isCancelled, setIsCancelled] = useState<boolean>(activeFilter.cancelled === MissionStatusDto.Stopped);
  const [isFullAssistance, setIsFullAssistance] = useState<boolean>(activeFilter.fullAssistance);
  const [isLightAssistance, setIsLightAssistance] = useState<boolean>(activeFilter.lightAssistance);

  const handleButtonActive = (reservationType: ReservationTypeDto) => {
    if (reservationType === ReservationTypeDto.Group) {
      setisButtonActive(3);
    }
    if (reservationType === ReservationTypeDto.Disabled) {
      setisButtonActive(2);
    }
    if (reservationType === MissionTypeDto.Stickering) {
      setisButtonActive(4);
    }
    if (reservationType === undefined) {
      setisButtonActive(1);
    }
  };

  const filterModalStatus = show ? 'modal d-block' : 'modal d-none';
  if (
    i18next.language === 'en-US' ||
    i18next.language === undefined ||
    localStorage.getItem('language') === undefined
  ) {
    i18next.language = 'En';
  }

  const {t} = useTranslation();

  const handleCancel = () => {
    filterClosed();
  };

  const handleSumbit = () => {
    let reserv = undefined;
    if (isButtonActive === 2) {
      reserv = ReservationTypeDto.Disabled;
    } else if (isButtonActive === 3) {
      reserv = ReservationTypeDto.Group;
    } else if (isButtonActive === 4) {
      reserv = MissionTypeDto.Stickering;
    }

    const formatFilter: IMissionFilter = {
      completed: isCompleted ? MissionStatusDto.Completed : undefined,
      reservationType: reserv,
      stickerMissions: undefined,
      started: isStarted ? MissionStatusDto.Started : undefined,
      cancelled: isCancelled ? MissionStatusDto.Stopped : undefined,
      fullAssistance: isFullAssistance,
      lightAssistance: isLightAssistance
    };
    handleChangeFilter(formatFilter);
  };

  React.useEffect(() => {
    handleButtonActive(activeFilter.reservationType);
    setIsCompleted(activeFilter.completed === MissionStatusDto.Completed);
    setIsStarted(activeFilter.started === MissionStatusDto.Started);
    setIsCancelled(activeFilter.cancelled === MissionStatusDto.Stopped);
    setIsFullAssistance(activeFilter.fullAssistance);
    setIsLightAssistance(activeFilter.lightAssistance);
  }, [show]);

  return (
    <span className="coordinator-modal">
      <div className={filterModalStatus} style={{zIndex: 99}}>
        <div className="modal-container modal-header-container modal-filter-class">
          <div className="modal-header coordinator-modal-header">
            <span className="coordinatior-region-main-header">{t('filter-section.filter-header-title')}</span>
            <button type="button" className="coordinatior-modal-reset-button" onClick={onResetFilter}>
              {t('filter-section.filter-reset')}
            </button>
          </div>
          <div className="modal-body modal-station-body filter-body">
            <div className="filter-cat">
              <button
                type="button"
                className={
                  isButtonActive === 1
                    ? 'filter-active-panel filter-left-radius'
                    : 'filter-main-panel filter-left-radius'
                }
                onClick={() => handleButtonActive(undefined)}
              >
                {t('filter-section.all-mission')}
              </button>
              <button
                type="button"
                className={isButtonActive === 3 ? 'filter-active-panel' : 'filter-main-panel'}
                onClick={() => handleButtonActive(ReservationTypeDto.Group)}
              >
                <img src={isButtonActive === 3 ? groboWhiteIcon : groboBlueIcon} className="img-style" alt="GROBO" />
              </button>
              <button
                type="button"
                className={
                  isButtonActive === 2
                    ? 'filter-active-panel filter-right-radius'
                    : 'filter-main-panel filter-right-radius'
                }
                onClick={() => handleButtonActive(ReservationTypeDto.Disabled)}
              >
                <img src={isButtonActive === 2 ? pmrWhiteIcon : pmrBlueIcon} className="img-style" alt="PMR" />
              </button>
              <button
                type="button"
                className={
                  isButtonActive === 4
                    ? 'filter-active-sticker filter-right-radius'
                    : 'filter-main-panel filter-right-radius'
                }
                onClick={() => handleButtonActive(MissionTypeDto.Stickering)}
              >
                <TrainSticker />
              </button>
            </div>
            <div className="selected-cat-label">
              {isButtonActive === 1 && t('filter-section.cat-label.cat-all')}
              {isButtonActive === 2 && t('filter-section.cat-label.cat-pmr')}
              {isButtonActive === 3 && t('filter-section.cat-label.cat-grobo')}
              {isButtonActive === 4 && t('filter.sticker')}
            </div>
            <div className="assign-panel">
              <div className="child-assign-panel">
                <span>
                  <input
                    type="checkbox"
                    className="checkbox-filter"
                    checked={!isCompleted}
                    onChange={() => setIsCompleted(prev => !prev)}
                  />
                </span>
                <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.completed')}</span>
              </div>
            </div>
            <div className="assign-panel">
              <div className="child-assign-panel">
                <span>
                  <input
                    type="checkbox"
                    className="checkbox-filter"
                    checked={!isStarted}
                    onChange={() => setIsStarted(prev => !prev)}
                  />
                </span>
                <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.started')}</span>
              </div>
            </div>
            <div className="assign-panel">
              <div className="child-assign-panel">
                <span>
                  <input
                    type="checkbox"
                    className="checkbox-filter"
                    checked={!isCancelled}
                    onChange={() => setIsCancelled(prev => !prev)}
                  />
                </span>
                <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.cancelled')}</span>
              </div>
            </div>
            <div className="assign-panel">
              <div className="child-assign-panel">
                <span>
                  <input
                    type="checkbox"
                    className="checkbox-filter"
                    checked={isFullAssistance}
                    onChange={() => {
                      setIsFullAssistance(prev => !prev);
                    }}
                  />
                </span>
                <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.pbm-full-assistance')}</span>
              </div>
            </div>
            <div className="assign-panel">
              <div className="child-assign-panel">
                <span>
                  <input
                    type="checkbox"
                    className="checkbox-filter"
                    checked={isLightAssistance}
                    onChange={() => {
                      setIsLightAssistance(prev => !prev);
                    }}
                  />
                </span>
                <span style={{color: 'black', paddingLeft: '15px'}}>{t('filter.pbm-light-assistance')}</span>
              </div>
            </div>
          </div>
          <div style={{position: 'relative', float: 'right', right: '18px', bottom: '12px'}}>
            <DialogCancelButton handleClick={handleCancel} />

            <DialogOkButton
              disabled={selectedTab === '1'}
              onClick={handleSumbit}
              title={t('filter-section.filter-choose')}
            ></DialogOkButton>
          </div>
        </div>
      </div>
    </span>
  );
};

export default ReservationFilterModel;
