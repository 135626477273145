import {Reducer} from 'react';
import {IKeys} from './settings.store';

interface State {
  EXTERNAL_LINK_SUPPORT_SERVICE_NOW: string;
  SUSPENSIONS_FEATURE_FLAG: boolean;
  MISSIONS_FETCH_PAST_IN_DAYS: number;
  MISSIONS_FETCH_FUTURE_IN_DAYS: number;
  COI_FEATURE_FLAG: boolean;
  EXTERNAL_LINK_ATMS: string;
  RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME: number;
  RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME: number;
  RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME: number;
  RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME: number;
}

export const intialReducerState: State = {
  EXTERNAL_LINK_SUPPORT_SERVICE_NOW: '',
  SUSPENSIONS_FEATURE_FLAG: false,
  MISSIONS_FETCH_FUTURE_IN_DAYS: 1,
  MISSIONS_FETCH_PAST_IN_DAYS: 1,
  COI_FEATURE_FLAG: false,
  EXTERNAL_LINK_ATMS: 'https://atms.acc-intern-belgiantrain.be',
  RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME: 0,
  RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME: 0,
  RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME: 0,
  RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME: 0,
};

interface ServiceAction {
  type: IKeys.EXTERNAL_LINK_SUPPORT_SERVICE_NOW;
  payload: string;
}

interface SuspensionAction {
  type: IKeys.SUSPENSIONS_FEATURE_FLAG;
  payload: boolean;
}

interface FetchFutureAction {
  type: IKeys.MISSIONS_FETCH_FUTURE_IN_DAYS;
  payload: number;
}

interface FetchPastAction {
  type: IKeys.MISSIONS_FETCH_PAST_IN_DAYS;
  payload: number;
}

interface CoiFeatureFlagAction {
  type: IKeys.COI_FEATURE_FLAG;
  payload: boolean;
}

interface AtmsLinkAction {
  type: IKeys.EXTERNAL_LINK_ATMS;
  payload: string;
}

interface TransferTimeActions {
  type: 'FULLMIN' | 'FULLMAX' | 'LIGHTMIN' | 'LIGHTMAX';
  payload: number;
}

type ReducerAction =
  | ServiceAction
  | SuspensionAction
  | FetchFutureAction
  | FetchPastAction
  | CoiFeatureFlagAction
  | AtmsLinkAction
  | TransferTimeActions;

export const settingsReducer: Reducer<State, ReducerAction> = (state, action) => {
  switch (action.type) {
    case IKeys.EXTERNAL_LINK_SUPPORT_SERVICE_NOW:
      return {...state, EXTERNAL_LINK_SUPPORT_SERVICE_NOW: action.payload};
    case IKeys.SUSPENSIONS_FEATURE_FLAG:
      return {...state, SUSPENSIONS_FEATURE_FLAG: action.payload};
    case IKeys.MISSIONS_FETCH_FUTURE_IN_DAYS:
      return {...state, MISSIONS_FETCH_FUTURE_IN_DAYS: action.payload};
    case IKeys.MISSIONS_FETCH_PAST_IN_DAYS:
      return {...state, MISSIONS_FETCH_PAST_IN_DAYS: action.payload};
    case IKeys.COI_FEATURE_FLAG:
      return {...state, COI_FEATURE_FLAG: action.payload};
    case IKeys.EXTERNAL_LINK_ATMS:
      return {...state, EXTERNAL_LINK_ATMS: action.payload};
    case 'FULLMIN':
      return {...state, RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME: action.payload};
    case 'FULLMAX':
      return {...state, RESERVATIONS_FULL_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME: action.payload};
    case 'LIGHTMIN':
      return {...state, RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MINIMUM_TRANSFER_TIME: action.payload};
    case 'LIGHTMAX':
      return {...state, RESERVATIONS_LIGHT_ALTERNATIVE_ROUTE_MAXIMUM_TRANSFER_TIME: action.payload};
    default:
      return state;
  }
};
