import i18next from 'i18next';
import './DialogModal.scss';
import ButtonLoading from '../buttons/ButtonLoading/ButtonLoading';

type DialogModalProps = {
  title?: string | JSX.Element;
  message?: string;
  show: boolean;
  handleOkClick: (...args: any) => void;
  handleCloseClick: () => void;
  buttonOk: React.ReactNode;
  className: string;
  children: React.ReactNode;
  multipleMessages: boolean;
  buttonCancel?: React.ReactNode;
  loadingData: boolean;
};

const cancelButton = () => i18next.t('create-prm-mission.modal.btn-cancel');

const DialogModal = ({
  title,
  message,
  show,
  handleOkClick,
  handleCloseClick,
  buttonOk,
  className,
  children,
  multipleMessages,
  buttonCancel = cancelButton(),
  loadingData,
}: DialogModalProps) => {
  const filterModalStatus = show ? 'modal d-block' : 'modal d-none';

  return (
    <span className={className}>
      <div className={filterModalStatus}>
        <div className="modal-container modal-header-container modal-filter-class">
          <div className="modal-header modal-station-header">
            <span className="dialog-title">{title}</span>
          </div>

          {!multipleMessages && (
            <div className="dialog-header">
              <span>{message}</span>
            </div>
          )}
          {multipleMessages && <div className="dialog-header">{children}</div>}
          <div className="modal-footer modal-station-footer">
            <div>
              <ButtonLoading onClick={handleOkClick} fixed={false} loadingData={loadingData} icon={false}>
                {buttonOk}
              </ButtonLoading>
            </div>
            <div>
              <button type="button" className="btn btn-common btn-without-bg" onClick={handleCloseClick}>
                {buttonCancel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};
export default DialogModal;
