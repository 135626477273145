import React, {useContext, useState} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentLocationProps} from './coiCreateIncidentOverview';
import {CacheContext} from 'src/context/cache/cache.store';
import {DistrictDto, LocalizedTextDto, ModelError, StationDto} from 'src/services/models';
import {CoiCreateIncidentLocationActionTypes, StationWithMainLocationDto, TrainWithMainLocationDto} from './reducer';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';
import {getStationPresences} from '../../coiUtils/coiGetPresences';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NetworkContext} from 'src/context/network/network.store';
import {AxiosError} from 'axios';
import {CoiAssignAssistButon, CoiAssignCoiButton, IOSSwitch} from '../common';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
export enum TargetType {
  PT_CAR = 'PT_CAR',
  TRAIN = 'TRAIN',
  ADDRESS = 'ADDRESS',
}

export type CoiTargetType =
  | {type: TargetType.PT_CAR; payload: StationWithMainLocationDto}
  | {
      type: TargetType.TRAIN;
      payload: {indOfTrain: number; train: TrainWithMainLocationDto};
    }
  | {type: TargetType.ADDRESS};

export interface CoiAssignCoiModalProps extends CoiCreateIncidentLocationProps {
  open: boolean;
  onClose: () => void;
  target: CoiTargetType;
  assignIndex: number | null;
}

export const CoiAssignCoiModal = ({open, onClose, target, assignIndex, ...rest}: CoiAssignCoiModalProps) => {
  const {i18n, t} = useTranslation();
  const {districts, stations} = useContext(CacheContext);

  const {variables} = React.useContext(EnvironmentContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);

  const [selectedDistrict, setSelectedDistrict] = useState<string>((districts as DistrictDto[])[0].id || '');
  const [selectedStation, setSelectedStation] = useState<string>(
    (stations as StationDto[])
      .sort((a, b) =>
        handleTranslationObject(i18n, a.name as LocalizedTextDto[]).localeCompare(
          handleTranslationObject(i18n, b.name as LocalizedTextDto[]),
        ),
      )
      .find(el => el.districtId === ((districts as DistrictDto[])[0].id || ''))?.id || '',
  );

  const [COIS, setCOIS] = React.useState<StationPresenceDto[] | undefined>(undefined);

  const [isSearchingCois, setIsSearchingCois] = React.useState(false);

  const [coiOnly, setCoiOnly] = React.useState(false);

  const handleDistrictChange = (val: string) => {
    const districtId = val;
    setSelectedDistrict(districtId || '');
    setSelectedStation((stations as StationDto[]).find(el => el.districtId === districtId)?.id || '');
  };

  const handleStationChange = (val: string) => {
    setSelectedStation((val as string) || '');
  };

  const handleSearch = async () => {
    setIsSearchingCois(true);
    if (!selectedStation) {
      return setIsSearchingCois(false);
    }

    await getStationPresences(variables.BASE_ENDPOINT, {stationIds: [selectedStation]})
      .then(res => {
        if (res) {
          setCOIS(res);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          validateNetworkCall(error.response.status, error.response.data as ModelError);
        }
        return undefined;
      })
      .finally(() => {
        setIsSearchingCois(false);
      });
  };

  const handleCoiOnly = () => {
    setCoiOnly(prev => !prev);
  };

  const handleAssignCoi = (employeeId: StationPresenceDto) => {
    if (employeeId === undefined) {
      throw new Error('Employee is undefined');
    }
    if (target.type === TargetType.TRAIN && assignIndex !== null) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_COI_TRAIN,
        payload: {coi: employeeId, pos: assignIndex},
      });
    } else if (target.type === TargetType.PT_CAR) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_COI_PTCAR,
        payload: employeeId,
      });
    } else if (target.type === TargetType.ADDRESS) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_COI_ADDRESS,
        payload: employeeId,
      });
    }
    //onClose();
  };

  const handleAssignAssist = (assistId: StationPresenceDto) => {
    if (assistId === undefined) {
      throw new Error('Employee is undefined');
    }
    if (target.type === TargetType.TRAIN && assignIndex !== null) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_ASSIST_TRAIN,
        payload: {id: assistId, pos: assignIndex},
      });
    } else if (target.type === TargetType.PT_CAR) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_ASSIST_PTCAR,
        payload: assistId,
      });
    } else if (target.type === TargetType.ADDRESS) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.ADD_ASSIST_ADDRESS,
        payload: assistId,
      });
    }
    //onClose();
  };

  const handleUnassignCoi = () => {
    if (target.type === TargetType.TRAIN && assignIndex !== null) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_COI_TRAIN,
        payload: {pos: assignIndex},
      });
    } else if (target.type === TargetType.PT_CAR) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_COI_PTCAR,
      });
    } else if (target.type === TargetType.ADDRESS) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_COI_ADDRESS,
      });
    }
    //onClose();
  };

  const handleUnassignAssist = (id: string) => {
    if (target.type === TargetType.TRAIN && assignIndex !== null) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_TRAIN,
        payload: {pos: assignIndex, id: id},
      });
    } else if (target.type === TargetType.PT_CAR) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_PTCAR,
        payload: id,
      });
    } else if (target.type === TargetType.ADDRESS) {
      rest.coiCreateIncidentLocationDispatch({
        type: CoiCreateIncidentLocationActionTypes.REMOVE_ASSIST_ADDRESS,
        payload: id,
      });
    }
    //onClose();
  };

  const coiText = {
    assignIncident: t('coi.assign-incident'),
    sations: t('pmr.search.stations'),
    search: t('pmr.search.search'),
    availaible: t('coi.select-available'),
    coiOnlt: t('coi.coi-only'),
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" disableEscapeKeyDown>
      <DialogTitle
        sx={{
          borderBottom: 'solid 1px grey',
          paddingTop: '25px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>{coiText.assignIncident}</h3>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: 'auto auto',
            gap: '25px',
            padding: '25px 0px',
          }}
        >
          <h4>{coiText.sations}</h4>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '20% 1fr 20%',
              gap: '15px',
            }}
          >
            <Select value={selectedDistrict} onChange={e => handleDistrictChange(e.target.value)}>
              {(districts as DistrictDto[]).map(el => (
                <MenuItem key={el.id} value={el.id || ''}>
                  {handleTranslationObject(i18n, el.name as LocalizedTextDto[])}
                </MenuItem>
              ))}
            </Select>

            <Autocomplete
              value={(stations as StationDto[]).find(el => el.id === selectedStation) || null}
              onChange={(_e, newValue) => handleStationChange(newValue?.id || '')}
              options={
                selectedDistrict
                  ? (stations as StationDto[])
                      .filter(el => el.districtId === selectedDistrict)
                      .sort((a, b) =>
                        handleTranslationObject(i18n, a.name as LocalizedTextDto[]).localeCompare(
                          handleTranslationObject(i18n, b.name as LocalizedTextDto[]),
                        ),
                      )
                  : []
              }
              getOptionLabel={option => handleTranslationObject(i18n, option.name as LocalizedTextDto[])}
              renderInput={params => <TextField {...params} label={t('select.station')} />}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />

            <Button
              variant="outlined"
              disabled={selectedDistrict.length === 0 || selectedStation.length === 0}
              onClick={handleSearch}
            >
              {coiText.search}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: 'auto auto',
            gap: '25px',
            padding: '25px 0px',
          }}
        >
          <Box sx={{display: 'grid', gridTemplateColumns: '1fr auto'}}>
            <h4>{coiText.availaible}</h4>
            <Box>
              <span style={{fontWeight: 'bold'}}>COI </span>{' '}
              <IOSSwitch
                checked={coiOnly}
                onChange={handleCoiOnly}
                disabled={COIS?.length === 0 || COIS === undefined}
              />
            </Box>
          </Box>
          <Box sx={{minHeight: '250px'}}>
            {isSearchingCois && <SearchingForCois />}
            {!isSearchingCois && COIS === undefined && <SearchForCois />}
            {!isSearchingCois && COIS !== undefined && COIS.length === 0 && <NoCoisFound />}
            {!isSearchingCois && COIS !== undefined && COIS.length > 0 && (
              <CoiList
                cois={coiOnly ? COIS.filter(c => c.isCoi) : COIS}
                handleAssignCoi={handleAssignCoi}
                handleAssignAssist={handleAssignAssist}
                assignIndex={assignIndex}
                target={target}
                handleUnassignAssist={handleUnassignAssist}
                handleUnassignCoi={handleUnassignCoi}
                {...rest}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const SearchingForCois = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'grid',
        placeItems: 'center',
        backgroundColor: '#EFF4F9',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

const SearchForCois = () => {
  return (
    <Box sx={{width: '100%', height: '100%', display: 'grid', placeItems: 'center', backgroundColor: '#EFF4F9'}}>
      <Box sx={{fontWeight: 'bold'}}>Search to find COI's</Box>
    </Box>
  );
};

const NoCoisFound = () => {
  return (
    <Box sx={{width: '100%', height: '100%', display: 'grid', placeItems: 'center', backgroundColor: '#EFF4F9'}}>
      <Box sx={{fontWeight: 'bold'}}>No COI's found at that station</Box>
    </Box>
  );
};

interface CoiListProps extends CoiCreateIncidentLocationProps {
  cois: StationPresenceDto[];
  handleAssignCoi: (id: StationPresenceDto) => void;
  handleAssignAssist: (id: StationPresenceDto) => void;
  handleUnassignCoi: () => void;
  handleUnassignAssist: (id: string) => void;
  assignIndex: number | null;
  target: CoiTargetType;
}

const CoiList = ({
  cois,
  handleAssignCoi,
  handleAssignAssist,
  handleUnassignAssist,
  handleUnassignCoi,
  assignIndex,
  target,
  ...rest
}: CoiListProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateRows: `repeat(${cois.length}, 60px)`,
        gap: '15px',
      }}
    >
      {cois.map(coi => (
        <CoiCOISItem
          key={coi.employeeId}
          coi={coi}
          handleAssignCoi={handleAssignCoi}
          handleAssignAssist={handleAssignAssist}
          assignIndex={assignIndex}
          target={target}
          handleUnassignAssist={handleUnassignAssist}
          handleUnassignCoi={handleUnassignCoi}
          {...rest}
        />
      ))}
    </Box>
  );
};

interface CoiCOISItemProps extends CoiCreateIncidentLocationProps {
  coi: StationPresenceDto;
  handleAssignCoi: (id: StationPresenceDto) => void;
  handleAssignAssist: (id: StationPresenceDto) => void;
  handleUnassignCoi: () => void;
  handleUnassignAssist: (id: string) => void;
  assignIndex: number | null;
  target: CoiTargetType;
}
const CoiCOISItem = ({
  coi,
  handleAssignCoi,
  handleAssignAssist,
  handleUnassignAssist,
  handleUnassignCoi,
  assignIndex,
  target,
  ...rest
}: CoiCOISItemProps) => {
  const {i18n} = useTranslation();

  const hasCurrentCoiBeenAssigned = () => {
    if (target.type === TargetType.TRAIN) {
      if (assignIndex !== null) {
        const existingCoi = rest.coiCreateIncidentLocationState.trains[assignIndex].assignedCoi;
        return existingCoi !== undefined && existingCoi.employeeId === coi.employeeId;
      }
      return true;
    } else {
      const existingCoi = rest.coiCreateIncidentLocationState.ptcar?.assignedCoi;
      return existingCoi !== undefined && existingCoi.employeeId === coi.employeeId;
    }
  };

  const isCurrentCoiAssistant = () => {
    const {type} = target;
    const {employeeId} = coi;
    const {trains, ptcar} = rest.coiCreateIncidentLocationState;

    if (type === TargetType.TRAIN) {
      if (assignIndex !== null) {
        const existingAssistant = trains[assignIndex]?.assistants;
        const assistanceIdMap = existingAssistant?.map(el => el.employeeId);
        return !!(
          assistanceIdMap &&
          assistanceIdMap.length > 0 &&
          employeeId &&
          assistanceIdMap.includes(coi.employeeId)
        );
      }
      return true;
    } else {
      const existingAssistant = ptcar?.assistants;
      const assistanceIdMap = existingAssistant?.map(el => el.employeeId);

      return !!(
        existingAssistant &&
        employeeId &&
        assistanceIdMap &&
        assistanceIdMap.length > 0 &&
        assistanceIdMap.includes(coi.employeeId)
      );
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#EFF4F9',
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr) auto  1fr repeat(2,auto)',
        gap: '20px',
        padding: '5px 12px',
        alignItems: 'center',
      }}
    >
      <span style={{fontWeight: 'bold', justifySelf: 'start'}}>{coi.name}</span>
      <span style={{justifySelf: 'start'}}>{coi.stationName}</span>
      <span style={{justifySelf: 'start'}}>{handleTranslationObject(i18n, coi.role as LocalizedTextDto[])}</span>
      <span style={{display: 'flex', gap: '5px'}}>
        <LocalPhoneOutlinedIcon color={'info'} />
        <a href={`dica:${coi.phoneNumber?.replace('+', '000')}`} style={{justifySelf: 'end', textDecoration: 'none'}}>
          {coi.phoneNumber}
        </a>
      </span>
      <CoiAssignCoiButton
        disabled={isCurrentCoiAssistant()}
        style={{justifySelf: 'center'}}
        onClick={() => {
          if (hasCurrentCoiBeenAssigned()) {
            handleUnassignCoi();
          } else if (coi) {
            handleAssignCoi(coi);
          }
        }}
        isCoi={hasCurrentCoiBeenAssigned()}
      >
        Assign COI
      </CoiAssignCoiButton>
      <CoiAssignAssistButon
        disabled={hasCurrentCoiBeenAssigned()}
        onClick={() => {
          if (isCurrentCoiAssistant()) {
            handleUnassignAssist(coi.employeeId || '');
          } else if (coi) {
            handleAssignAssist(coi);
          }
        }}
        style={{justifySelf: 'center'}}
        isAssistant={isCurrentCoiAssistant()}
      >
        Assign Assist
      </CoiAssignAssistButon>
    </Box>
  );
};
