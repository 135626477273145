import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentLocationProps} from '../coiCreateIncidentLocation';
import {CoiAssignCoiModal, CoiTargetType} from '../coiAssignModal';
import React from 'react';
import {TrainListElement} from './TrainList';
import {PtCarElement} from './PtCar';

export const TrainAndPtCarOverview = (props: CoiCreateIncidentLocationProps) => {
  const {t} = useTranslation();

  const [coiTarget, setCoiTarget] = React.useState<CoiTargetType | undefined>(undefined);
  const [isCoiModalVisible, setIsCoiModalVisble] = React.useState(false);
  const [assignIndex, setAssigIndex] = React.useState<number | null>(null);

  const handleCoiAssignModalTarget = (target: CoiTargetType | undefined, targetIndex: number | null) => {
    if (target === undefined) {
      setCoiTarget(undefined);
      setIsCoiModalVisble(false);
      setAssigIndex(targetIndex);
    } else {
      setCoiTarget(target);
      setIsCoiModalVisble(true);
      setAssigIndex(targetIndex);
    }
  };

  return (
    <div style={{padding: '24px'}}>
      {coiTarget !== undefined && (
        <CoiAssignCoiModal
          handleCommentModal={() => {}}
          assignIndex={assignIndex}
          target={coiTarget}
          open={isCoiModalVisible && coiTarget !== undefined}
          onClose={() => handleCoiAssignModalTarget(undefined, null)}
          {...props}
        />
      )}
      <p style={{margin: '0', fontWeight: 'bold', fontSize: '22px', marginBottom: '12px'}}>
        {props.coiCreateIncidentLocationState.trains.length > 1 ? t('coi.multiple-trains') : t('coi.trains')}
      </p>
      <div
        style={{
          padding: '10px',
          paddingLeft: '15px',
          display: 'grid',
          gridTemplateColumns: 'repeat(2,15%) repeat(2, 160px) auto',
          gap: '10px',
        }}
      >
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coi.main-incident')}</span>
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('reservation.form.train.label')}</span>
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coor-role.assignModal.from')}</span>
        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coor-role.assignModal.to')}</span>
      </div>
      <div style={{display: 'grid', gap: '16px'}}>
        {props.coiCreateIncidentLocationState.trains.map((el, ind) => (
          <TrainListElement
            props={props}
            train={el}
            key={ind}
            ind={ind}
            handleCoiAssignModalTarget={handleCoiAssignModalTarget}
          />
        ))}
      </div>
      {props.coiCreateIncidentLocationState.ptcar && (
        <>
          <p style={{margin: '0', fontWeight: 'bold', fontSize: '22px', marginBottom: '12px', marginTop: '24px'}}>
            {t('coi.ptcar')}
          </p>
          <div
            style={{
              padding: '10px',
              display: 'grid',
              paddingLeft: '15px',
              gridTemplateColumns: 'repeat(2,15%) repeat(2, 160px) auto auto',
              gap: '10px',
            }}
          >
            <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coi.main-incident')}</span>
            <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coi.location')}</span>
            <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{t('coi.total-tracks')}</span>
          </div>
          <PtCarElement props={props} handleCoiAssignModalTarget={handleCoiAssignModalTarget} />
        </>
      )}
    </div>
  );
};
