import {Autocomplete, TextField} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import {LocalizedTextDto, StationDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';

function StationAutoComplete({handleArrivalChange}: {handleArrivalChange: (a: string, b: string) => void}) {
  const {stations} = React.useContext(CacheContext);
  const {t, i18n} = useTranslation();

  return (
    <Autocomplete
      renderInput={args => <TextField label={t('create-mission.arrival')} {...args} />}
      placeholder={t('create-mission.arrival')}
      getOptionLabel={option => option.label}
      options={(stations as unknown as StationDto[]).map(el => ({
        label: handleTranslationObject(i18n, el.name as LocalizedTextDto[]),
        id: el.id,
      }))}
      onChange={(_e, val) => handleArrivalChange(val?.id || '', val?.label || '')}
    ></Autocomplete>
  );
}

export default StationAutoComplete;
