// @ts-nocheck
import React from 'react';
import {ButtonOutlined, ButtonFilled, ButtonText, ModalView} from 'src/components';
import {useState} from 'react';
import Box from '@mui/material/Box';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';
import {SettingsContext} from 'src/context/settings/settings.store';
import {EnvironmentContext} from 'src/context/environment/environment.store';

type Props = {
  isReservation?: boolean;
  isPrintSticker?: boolean;
  isDetailPrint?: boolean;
  isPmr?: boolean;
  isGrobo?: boolean;
  isCheckIn?: boolean;
  isCheckOut?: boolean;
  isOpen?: boolean;
  ModalViewComponent?: any;
  loading?: boolean;
  isCreateMissionModalOpen?: boolean;
  onSubmit?: any;
  setMissionModal?: any;
  hasStickers?: boolean;
  loadingPrintMissions?: boolean;
  activeReservation?: boolean;
};

export function BottomNavigation({
  isReservation,
  isPmr,
  isPrintSticker,
  isDetailPrint,
  isCheckIn,
  isCheckOut,
  isOpen,
  ModalViewComponent,
  loading,
  isCreateMissionModalOpen,
  onSubmit,
  setMissionModal,
  hasStickers,
  loadingPrintMissions,
  activeReservation,
}: Props) {
  const {t} = useTranslation();
  const [isModalOneOpen, setModalOne] = useState(false);
  const {isMenuOpen} = React.useContext(SettingsContext);
  const {variables} = React.useContext(EnvironmentContext);

  const getWidth = () => {
    if (variables.USE_PMR_3) {
      return isMenuOpen ? 'calc(100vw - 360px)' : 'calc(100vw - 100px)';
    } else {
      return 'calc(100vw - 240px)';
    }
  };

  return (
    <>
      <Box
        sx={{
          width: getWidth(),
          padding: '10px 12px 10px 12px',
          backgroundColor: '#bfdaec',
          color: '#333333',
          textAlign: 'center',
          lineHeight: 'normal',
          position: 'fixed',
          bottom: 0,
          zIndex: 1,
          justifyContent: 'flex-end',
          display: 'flex',
          gap: '10px',
          right: 0,
        }}
      >
        {isPrintSticker && (
          <>
            <Button
              onClick={() => onSubmit(true)}
              disabled={hasStickers || loadingPrintMissions}
              className="filled-button-preview"
            >
              {' '}
              {'preview'}{' '}
            </Button>
            <Button
              onClick={() => onSubmit(false)}
              disabled={hasStickers || loadingPrintMissions}
              className="filled-button"
              type="primary"
            >
              {' '}
              {t('footer-coordinator.print')}{' '}
            </Button>
            {/* <ButtonOutlined label={t('footer-coordinator.reservation.add-note')} onClick={() => setModalOne(true)} /> */}
          </>
        )}
        {isReservation && (
          <>
            <ButtonFilled
              label={t('footer-coordinator.reservation.manage-assistant')}
              disabled={!activeReservation}
              onClick={() => {
                if (activeReservation) {
                  setMissionModal(true);
                }
              }}
            />
            {/* <ButtonOutlined label={t('footer-coordinator.reservation.add-note')} onClick={() => setModalOne(true)} /> */}
          </>
        )}
        {isDetailPrint && (
          <>
            <ButtonFilled label="Save assistance changes" onClick={() => setMissionModal(true)} />
            <ButtonText label="Return" onClick={() => setModalOne(true)} />
          </>
        )}
        {isPmr && (
          <>
            <ButtonFilled label={t('create-grobo-mission.modal.btn-create')} onClick={onSubmit} />
            <ButtonText label={t('create-grobo-mission.modal.btn-cancel')} />
          </>
        )}
        {isCheckIn && (
          <>
            <ButtonFilled label="Check-IN" onClick={() => setMissionModal(true)} />
            <ButtonText label="cancel" onClick={() => setModalOne(true)} />
          </>
        )}
        {isCheckOut && (
          <>
            <ButtonOutlined dangerType label="Check-OUT" onClick={() => setModalOne(true)} />
            <ButtonFilled label="Modifier check-IN" />
            <ButtonText label="cancel" onClick={() => setModalOne(true)} />
          </>
        )}
      </Box>

      <ModalView
        width={900}
        zIndex={99999}
        isVisible={isCreateMissionModalOpen}
        title={t('footer-coordinator.reservation.manage-assistant')}
        okTitle={t('reservation.change')}
        cancelTitle={t('filter-section.filter-cancel')}
        loading={loading}
        onOk={onSubmit}
        onCancel={() => setMissionModal(false)}
      >
        {ModalViewComponent}
      </ModalView>
      <ModalView
        isVisible={isModalOneOpen}
        title="Add  a note"
        okTitle="Send note"
        cancelTitle="Cancel"
        onOk={() => setModalOne(false)}
        onCancel={() => setModalOne(false)}
      >
        <p style={{fontFamily: '72, sans-serif', color: ' #666666', fontSize: 14}}>You can add a comment:</p>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          placeholder="Add a comment..!"
          style={{width: '100%'}}
        />
      </ModalView>
    </>
  );
}
