import React from 'react';
import {SvgIcon, Grid} from '@mui/material';
import AdultIcon from 'src/icons/AdultIcon';
import ChildIcon from 'src/icons/ChildIcon';
import ColleagueIcon from 'src/icons/ColleagueIcon';
import SeniorIcon from 'src/icons/SeniorIcon';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {getTotalAmountOfTravelers} from 'src/scenes/coordinator/MissionDetailsNew/components/OverviewOfTravelers/util';
import {ReservationTravelDto} from 'src/services/models/reservation-travel-dto';
import {TravelerDto} from 'src/services/models/traveler-dto';
import {CacheContext} from 'src/context/cache/cache.store';
import {LocalizedTextDto} from 'src/services/models';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';
import {useTranslation} from 'react-i18next';

const getSeniors = (t: TravelerDto) => {
  return t.ageCategories?.find(el => el.typeId?.includes('5'))?.quantity || null;
};

const getAdults = (t: TravelerDto) => {
  return t.ageCategories?.find(el => el.typeId?.includes('4'))?.quantity || null;
};

const getChildren = (t: TravelerDto) => {
  let total = 0;
  total = total + (t.ageCategories?.find(el => el.typeId?.includes('1'))?.quantity || 0);
  total = total + (t.ageCategories?.find(el => el.typeId?.includes('2'))?.quantity || 0);
  total = total + (t.ageCategories?.find(el => el.typeId?.includes('3'))?.quantity || 0);
  return total > 0 ? total : null;
};

export const TripTravelerCombinationInformation: React.FC<{
  travel: ReservationTravelDto;
  t: (arg0: string) => string;
}> = ({t, travel}) => {
  //@ts-ignore
  const {ageCategories}: {ageCategories: Array<{id: string; description: Array<LocalizedTextDto>}>} =
    React.useContext(CacheContext);
  const {i18n} = useTranslation();
  return (
    <Grid item xs={12} sx={{border: 'solid 1px #E8EAED', borderRadius: '5px'}} padding={'12px 16px'} mb={'12px'}>
      <Grid container>
        <Grid item xs={5}>
          <p>
            <b>{t('icons.amount-of-travelers')}</b>
          </p>
          <div>
            <SvgIcon>
              <ColleagueIcon />
            </SvgIcon>
            <b style={{paddingLeft: '5px'}}>{travel.traveler?.quantity}</b>
          </div>
        </Grid>
        <Grid item xs={7}>
          <p>
            <b>{t('mission-details-v2.traveler.type-of-travelers')}</b>
          </p>
          {travel.traveler?.ageCategories?.map(el => (
            <p>
              {handleTranslationObject(
                i18n,
                ageCategories.find(a => a.id === el.typeId)?.description as LocalizedTextDto[],
              )}
              :<b style={{marginRight: '5px'}}> {el.quantity}</b>
            </p>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
