// @ts-nocheck
import React, {useState} from 'react';
import i18next from 'i18next';
import filterIcon from '../../../components/images/ic_filter-blue.svg';
// import FilterModalWindow from './FilterModal';
import './ReservationFilter.scss';
import ReservationFilterModel from './ReservationFilterModel';
import {IMissionFilter, MissionContext, defaultMissionFilter} from '../MissionOverview/missions.store';
const ReservationFilter = () => {
  const [reservationFilterModal, setReservationFilterModal] = useState(false);

  if (
    i18next.language === 'en-US' ||
    i18next.language === undefined ||
    localStorage.getItem('language') === undefined
  ) {
    i18next.language = 'En';
  }

  const {handleFilterReset, handleFilterChange, missionFilter} = React.useContext(MissionContext);

  const toggleModal = () => {
    setReservationFilterModal(prev => !prev);
  };

  const handleResetClick = () => {
    handleFilterReset();
    toggleModal();
  };

  const handleChangeFilterVariables = (filter: IMissionFilter) => {
    handleFilterChange(filter);
    toggleModal();
  };

  return (
    <div style={{display: 'grid', placeItems: 'center', paddingTop: '5px'}}>
      <div>
        <span onClick={() => toggleModal()}>
          {JSON.stringify(missionFilter) === defaultMissionFilter ? (
            <img src={filterIcon} className="img-style" alt="img" />
          ) : (
            <div style={{position: 'relative'}}>
              <img src={filterIcon} className="img-style" alt="img" />
              <span className="orange-dot"></span>
            </div>
          )}
        </span>

        <ReservationFilterModel
          selectedTab={1}
          show={reservationFilterModal}
          filterClosed={toggleModal}
          onResetFilter={handleResetClick}
          handleChangeFilter={handleChangeFilterVariables}
          activeFilter={missionFilter}
        />
      </div>
    </div>
  );
};

export default ReservationFilter;
