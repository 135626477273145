import axios, {AxiosError} from 'axios';
import {OrganizationUnitDto} from 'src/services/models/organization-unit-dto';
import {StationPresenceDto} from 'src/services/models/station-presence-dto';

export const getPresences = async (baseUrl: string, stations: string[]) => {
  try {
    const incident = await axios.post<StationPresenceDto[]>(`${baseUrl}/api/v2/presences`, {
      stationIds: stations,
    });
    return incident.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};

export const getUnits = async (baseUrl: string) => {
  try {
    const units = await axios.get<OrganizationUnitDto[]>(`${baseUrl}/api/users/organization?includeBcs=true`);
    return units.data;
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};
