import axios, {AxiosError} from 'axios';
import {StationPresencesDto} from 'src/services/models/station-presences-dto';
import {initialFormControlState} from './formControlDispatch';

export const fetchCheckinOverview = async (
  baseEndpoint: string,
  formControls: typeof initialFormControlState,
  stations: object,
) => {
  let URL = `${baseEndpoint}/api/v2/presences/overview?date=${formControls.date}`;

  if (!formControls.viewAllStations) {
    //@ts-ignore
    const getStationUic = stations.find(el => {
      return el.name.find((el: {language: string; text: string}) => el.text === formControls.stationFilter);
    });
    URL = URL.concat('', `&stationId=${getStationUic.id}`);
  }
  if (formControls.withHistory) URL = URL.concat('', `&withHistory=${formControls.withHistory}`);

  try {
    //@ts-ignore
    const result = await axios.get<Array<StationPresencesDto>>(URL);
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error as AxiosError);
  }
};
