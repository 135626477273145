import React from 'react';

import {getBackGroundColor, getVerticalLine, hasAssistance} from './util';

import {TaxiChip, TrainNumberChip} from '../Chips';
import {FullCircle, SemiCirlce} from './styles';
import StickerOfIcon from 'src/icons/StickerOfIcon';
import StickerOn from 'src/icons/ic_sticker_on.png';
import TrainIcon from 'src/icons/TrainIcon';
import {Box, Grid} from '@mui/material';
import DoorIcon from 'src/icons/DoorIcon';
import {useTranslation} from 'react-i18next';

type Props = {
  time: string;
  isFullStop: boolean;
  isEndOfLine: boolean;
  stationName: string;
  trainNumber: string | number | undefined;
  requiresAssistance: boolean | undefined;
  location: string;
  isSticker: boolean;
  hasStickerFinished: boolean;
  isCompleteOverview: boolean;
  isGrobo: boolean;
  isExtended: boolean;
  door: string | null | undefined;
  isTaxi: boolean;
  wheelchair: boolean;
};

export const JourneyItem: React.FC<Props> = props => {
  const {t} = useTranslation();

  const onePointFiveOrTwo = props.isCompleteOverview ? 1.5 : 2;
  const threePointTwoOrTwoPointEight = props.isCompleteOverview ? 3.2 : 2.8;

  if (props.isSticker) {
    return (
      <Box
        width={'100%'}
        sx={{
          backgroundColor: `${getBackGroundColor(props.isFullStop, props.isSticker, props.isEndOfLine)}`,
          marginBottom: '15px',
          maxHeight: '35px',
          lineHeight: '35px',
        }}
      >
        <Grid container sx={{height: '100%', padding: '0px 8px', fontWeight: 'bold'}}>
          <Grid item width={'40px'}>
            {props.time}
          </Grid>
          <Grid item display={'flex'} justifyContent={'center'} position={'relative'} width={'70px'} paddingTop={'5px'}>
            {props.hasStickerFinished ? (
              <img src={StickerOn} style={{width: '24px', height: '24px', position: 'relative', zIndex: '10'}} />
            ) : (
              <StickerOfIcon />
            )}
            {getVerticalLine(props.isEndOfLine, props.isFullStop, props.isExtended)}
          </Grid>
          <Grid item xs={threePointTwoOrTwoPointEight} style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
            {props.stationName}
          </Grid>
          <Grid item xs={onePointFiveOrTwo} color={'#2A3870'}></Grid>
          <Grid item xs={onePointFiveOrTwo} color={'#2A3870'}></Grid>
          <Grid item xs={onePointFiveOrTwo} color={'#2A3870'}>
            <TrainIcon />
            {props.location}
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box
      width={'100%'}
      sx={{
        backgroundColor: `${getBackGroundColor(props.isFullStop, props.isSticker, props.isEndOfLine)}`,
        marginBottom: '15px',
        maxHeight: '35px',
        lineHeight: '35px',
      }}
    >
      <Grid container sx={{height: '100%', padding: '0px 8px', fontWeight: 'bold'}}>
        <Grid item width={'40px'}>
          {props.time}
        </Grid>
        <Grid item display={'flex'} justifyContent={'center'} position={'relative'} width={'70px'}>
          <div>
            {props.isFullStop ? <FullCircle /> : <SemiCirlce />}
            {getVerticalLine(props.isEndOfLine, props.isFullStop, props.isExtended)}
          </div>
        </Grid>
        <Grid item xs={threePointTwoOrTwoPointEight} style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
          {props.stationName}
        </Grid>
        <Grid item xs={onePointFiveOrTwo}>
          {props.isTaxi && !props.isEndOfLine ? (
            <TaxiChip />
          ) : props.trainNumber ? (
            <TrainNumberChip trainNumber={props.trainNumber.toString()} />
          ) : null}
        </Grid>
        <Grid item xs={onePointFiveOrTwo} sx={{fontWeight: `${props.requiresAssistance ? 'bold' : 'normal'}`}}>
          {t(hasAssistance(props.requiresAssistance))}
        </Grid>
        {props.isCompleteOverview ? (
          <Grid item xs={onePointFiveOrTwo} sx={{fontWeight: `${props.wheelchair ? 'bold' : 'normal'}`}}>
            {props.wheelchair ? t('check-in.btn-yes') : t('check-in.btn-no')}
          </Grid>
        ) : null}
        <Grid item xs={2} color={'#2A3870'}>
          {!props.isCompleteOverview && !props.isTaxi ? (
            <>
              <TrainIcon />
              {props.location}
              <DoorIcon />
              {props.door}
            </>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};
