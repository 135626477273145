import {useTranslation} from 'react-i18next';
import {CoiCreateIncidentStore} from '../../coi.createIncident';

import {useMemo} from 'react';
import {CoiCreateIncidentInformationListElements} from './coiCreateIncidentInformationListElements/index';

export interface CoiCreateIncidentLocationProps extends CoiCreateIncidentStore {
  handleCommentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CoiCreateIncidentOverview = ({
  coiCreateIncidentLocationState,
  ...rest
}: CoiCreateIncidentLocationProps) => {
  const {t} = useTranslation();

  const isEmpty = useMemo(() => {
    const noAddress = coiCreateIncidentLocationState.address === undefined;
    const noTrains = coiCreateIncidentLocationState.trains.length === 0;
    const noPtCar = coiCreateIncidentLocationState.ptcar === undefined;

    return noAddress && noPtCar && noTrains;
  }, [
    coiCreateIncidentLocationState.address,
    coiCreateIncidentLocationState.trains,
    coiCreateIncidentLocationState.ptcar,
  ]);

  const isWithAddress = useMemo(() => {
    const address =
      coiCreateIncidentLocationState.address !== undefined &&
      coiCreateIncidentLocationState.address &&
      coiCreateIncidentLocationState.address.address &&
      coiCreateIncidentLocationState.address.address.length > 0;
    return address;
  }, [coiCreateIncidentLocationState.address, coiCreateIncidentLocationState.trains]);

  return (
    <div style={{marginTop: '22px'}}>
      <p style={{margin: '0', fontWeight: 'bold', fontSize: '22px', marginBottom: '12px'}}>
        {t('coi.added-locations')}
      </p>
      <div style={{backgroundColor: 'rgba(239, 244, 249, 1)'}}>
        {isEmpty ? (
          <CoiCreateIncidentInformationListElements.Empty />
        ) : isWithAddress && coiCreateIncidentLocationState.address ? (
          <CoiCreateIncidentInformationListElements.Address
            coiCreateIncidentLocationState={coiCreateIncidentLocationState}
            {...rest}
          />
        ) : (
          <CoiCreateIncidentInformationListElements.TrainAndPtCar
            coiCreateIncidentLocationState={coiCreateIncidentLocationState}
            {...rest}
          />
        )}
      </div>
    </div>
  );
};
