import {TextField} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ReservationTypeDto} from 'src/services/models';
import {CarAndDoorProps} from '../types';

export const CarAndDoor: React.FC<CarAndDoorProps> = ({handleCarNumber, index, reservation, variant}) => {
  const {t} = useTranslation();

  return (
    <div
      style={{
        marginBottom: '10px',
        display: 'grid',
        gridTemplateColumns: `${variant === ReservationTypeDto.Disabled ? '1fr 1fr' : '1fr'}`,
        gridGap: '8px',
      }}
    >
      <TextField
        type="text"
        variant="outlined"
        label={t('create-grobo-mission.car-number')}
        inputProps={variant === ReservationTypeDto.Disabled ? {maxLength: 3} : {}}
        onChange={event => handleCarNumber(event.target.value)}
        value={reservation?.travel?.journeys[index].coachNumber || ''}
        error={
          variant !== ReservationTypeDto.Disabled && reservation?.travel?.journeys[index].coachNumber?.length === 0
        }
        color={reservation?.travel?.journeys[index].coachNumber?.length === 0 ? 'error' : 'success'}
      />
    </div>
  );
};
