import React, {useContext, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';

import './DateRange.scss';
import goLeftIcon from '../../components/images/ic_go-left-small-blue.svg';
import goRightIcon from '../../components/images/ic_go-right-small-blue.svg';
import {useTranslation} from 'react-i18next';
import {SettingsContext} from 'src/context/settings/settings.store';
import i18next from 'i18next';
import {MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';

function formatDateToLocales(date: any, localeType: any) {
  const dateString = date.locale(localeType.toLocaleLowerCase()).format('dddd D MMMM YYYY');
  return dateString.charAt(0).toLocaleUpperCase() + dateString.slice(1);
}

const DateRange = (props: {onLeftClicked: () => void; onRightClicked: () => void}) => {
  const {i18n} = useTranslation();
  const {MISSIONS_FETCH_FUTURE_IN_DAYS, MISSIONS_FETCH_PAST_IN_DAYS} = useContext(SettingsContext);

  const {loadingData, selectedDate, setSelectedDate} = React.useContext(MissionContext);

  const [dayCounter, setDayCounter] = React.useState(0);

  const [dateTitle, setDateTitle] = useState(formatDateToLocales(moment(), i18next.language));

  const [leftDisabled, setLeftDisabled] = useState(false);
  const [rightDisabled, setRightDisabled] = useState(false);

  const handleLeftClick = () => {
    setRightDisabled(false);
    const newDayCounter = dayCounter - 1;
    const currentDate = moment(selectedDate);
    if (newDayCounter > -MISSIONS_FETCH_PAST_IN_DAYS && newDayCounter !== 0) {
      setDateTitle(formatDateToLocales(currentDate.subtract(1, 'days'), i18next.language));
      setSelectedDate(moment(currentDate).format('yyyy-MM-DD'));
      setLeftDisabled(false);
    }

    if (newDayCounter == 0) {
      setDateTitle(formatDateToLocales(moment(), i18next.language));
      setSelectedDate(moment().format('yyyy-MM-DD'));
      setLeftDisabled(false);
    }

    if (newDayCounter == -MISSIONS_FETCH_PAST_IN_DAYS) {
      setDateTitle(formatDateToLocales(currentDate.subtract(1, 'days'), i18next.language));
      setSelectedDate(moment(currentDate).format('yyyy-MM-DD'));
      setLeftDisabled(true);
    }
    props.onLeftClicked();
    setDayCounter(newDayCounter);
  };
  const handleRightClick = () => {
    setLeftDisabled(false);
    const newDayCounter = dayCounter + 1;
    const currentDate = moment(selectedDate);
    if (newDayCounter < MISSIONS_FETCH_FUTURE_IN_DAYS && newDayCounter !== 0) {
      setDateTitle(formatDateToLocales(currentDate.add(1, 'days'), i18next.language));
      setSelectedDate(moment(currentDate).format('yyyy-MM-DD'));
      setRightDisabled(false);
    }

    if (newDayCounter == 0) {
      setDateTitle(formatDateToLocales(moment(), i18next.language));
      setSelectedDate(moment().format('yyyy-MM-DD'));
      setRightDisabled(false);
    }

    if (newDayCounter == MISSIONS_FETCH_FUTURE_IN_DAYS) {
      setDateTitle(formatDateToLocales(currentDate.add(1, 'days'), i18next.language));
      setSelectedDate(moment(currentDate).format('yyyy-MM-DD'));
      setRightDisabled(true);
    }
    props.onRightClicked();
    setDayCounter(newDayCounter);
  };

  React.useEffect(() => {
    setDateTitle(formatDateToLocales(moment(selectedDate), i18n.language));
  }, [i18n.language]);

  return (
    <>
      <div className="daterange-section">
        <div className="row-daterange">
          <div className={loadingData ? 'rcloading' : 'rc1'}>
            <span className="">
              <img
                src={goLeftIcon}
                onClick={() => {
                  !leftDisabled && handleLeftClick();
                }}
                className={loadingData ? 'img-style-loading' : 'img-style'}
                alt="img"
              />
            </span>
          </div>
          <div className="rc2">
            <span className="date-style">{dateTitle} </span>
          </div>
          <div className={loadingData ? 'rcloading' : 'rc3'}>
            <span className="">
              <img
                src={goRightIcon}
                onClick={() => {
                  !rightDisabled && handleRightClick();
                }}
                className={loadingData ? 'img-style-loading' : 'img-style'}
                alt="img"
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateRange;
