import {Box, MenuItem, Select, SelectChangeEvent, IconButton, TextField, Button} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {useContext, useState} from 'react';
import {IncidentDetailsDto} from 'src/services/models/incident-details-dto';
import {mapLocationsToMenuItems} from './util';
import SendIcon from '@mui/icons-material/Send';
import useHandleCall from 'src/hooks/useHandleCall';
import {sendChats} from '../../data';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import React from 'react';

export const ChatFooter: React.FC<{incident: IncidentDetailsDto; handleNewSearch: () => Promise<void>}> = ({
  incident,
  handleNewSearch,
}) => {
  const userRole = localStorage.getItem('user_role') || null;
  const [selectedLocation, setSelectedLocation] = useState<{value: string; text: string}>({value: '', text: ''});
  const [message, setMessage] = useState('');

  const {executeCall, loadingCall} = useHandleCall<void>();
  const {variables} = useContext(EnvironmentContext);

  const handleSelectChange = (event: SelectChangeEvent<string>, child: any) => {
    setSelectedLocation({value: event.target.value as string, text: child.props.children});
  };

  const handleClearSelection = () => {
    setSelectedLocation({value: '', text: ''});
  };

  const handleSendMessage = async () => {
    if (userRole === null) {
      return;
    }
    await executeCall(
      sendChats(variables.BASE_ENDPOINT, {
        incidentId: incident.id || '',
        locationId: selectedLocation.value || null,
        message: message,
        role: userRole,
      }),
    ).then(async () => {
      await handleNewSearch();
    });
    setMessage('');
    handleClearSelection();
  };
  const truncateText = (text: string | null | undefined, maxLength: number = 10) => {
    if (text === null || text === undefined) {
      return '';
    }
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  //Refs
  const selectViewRef = React.useRef<typeof Select>(null);

  return (
    <Box sx={{display: 'grid', gridTemplateRows: 'auto 1fr', gap: 2, padding: '8px', height: '100%'}}>
      {/* Select and Clear Button */}
      <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <Select
          style={{
            //@ts-ignore
            maxWidth: (selectViewRef && `${selectViewRef.current?.clientWidth}px`) || 'auto',
          }}
          ref={selectViewRef}
          disabled={loadingCall}
          sx={{backgroundColor: 'white', flex: 1}}
          value={selectedLocation.value}
          onChange={handleSelectChange}
          displayEmpty
          renderValue={() => (selectedLocation.text ? truncateText(selectedLocation.text, 25) : 'Assign*')}
        >
          <MenuItem disabled value="">
            Assign*
          </MenuItem>
          {incident.locations && mapLocationsToMenuItems(incident.locations)}
        </Select>

        <IconButton
          disabled={!selectedLocation.value}
          onClick={handleClearSelection}
          sx={{
            height: '100%',
            width: '40px',
            backgroundColor: 'none',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ClearIcon />
        </IconButton>
      </Box>

      {/* Text Area and Send Button */}
      {userRole !== null && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            height: '100%',
            backgroundColor: 'white',
            padding: '8px',
            gap: '8px',
          }}
        >
          <TextField
            disabled={loadingCall}
            sx={{
              flex: 1,
              backgroundColor: 'white',
              height: '100%',
              alignSelf: 'stretch',
              '& .MuiInputBase-root': {
                border: 'none',
                height: '100%',
                padding: 0, // Remove padding
                alignItems: 'flex-start', // Align text to the top
              },
              '& .MuiInputBase-input': {
                padding: '8px', // Add a little padding inside the input area
                boxSizing: 'border-box',
                border: 'none',
              },
            }}
            multiline
            maxRows={2}
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Type your message here..."
          />
          <Button
            disabled={message.length === 0 || loadingCall}
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            sx={{height: '100%'}}
          >
            <SendIcon />
          </Button>
        </Box>
      )}
      {userRole === null && (
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            height: '100%',
            backgroundColor: 'white',
            padding: '8px',
            gap: '8px',
          }}
        >
          You have to check-in before you can send messages
        </Box>
      )}
    </Box>
  );
};
