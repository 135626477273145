import {Box, CircularProgress} from '@mui/material';

import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {getMission} from '../Reservation/GroupReservations.service';
import {LoadingContainer} from './TripDetails.styles';

import {TripDetailsComponents} from './components';

export const TripDetailsNew: React.FC = () => {
  const {t} = useTranslation();
  const {id} = useParams<{id: string}>();
  const {variables} = React.useContext(EnvironmentContext);

  const [loadingData, setLoadingData] = React.useState(true);
  const [reservation, setReservation] = React.useState<ReservationDto | undefined>(undefined);

  React.useEffect(() => {
    getMission(variables.BASE_ENDPOINT, id || '').then(async res => {
      if (res) {
        setReservation(res);
        setLoadingData(false);
      } else {
        setLoadingData(false);
      }
    });
  }, []);

  if (loadingData) {
    return (
      <Box>
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      </Box>
    );
  }

  if (!reservation)
    return (
      <Box>
        {t('trip-details-v2.no-trip-found')}: {id}
      </Box>
    );

  return (
    reservation && (
      <Box
        sx={{
          height: '100vh',
        }}
      >
        <div style={{display: 'grid', gridTemplateColumns: '0.4fr 1fr', height: '100%'}}>
          <TripDetailsComponents.Info reservation={reservation} />
          <TripDetailsComponents.Details
            reservation={reservation}
            reservationType={reservation.type}
            variant={reservation.type}
            disabilities={undefined}
          />
        </div>
      </Box>
    )
  );
};
