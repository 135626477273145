// @ts-nocheck
import React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import {useNavigate, useLocation} from 'react-router-dom';
import {GroupReservationContext} from 'src/scenes/coordinator/Reservation/GroupReservation.store';
import {ListItem} from './GroupReservationBoxListItem';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {SettingsContext} from 'src/context/settings/settings.store';

export const GroupReservationBox = (props: MissionDetailDto) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {handleActiveMission, activeMissions} = React.useContext(GroupReservationContext);

  const {setPrevRoute} = React.useContext(SettingsContext);

  const formatStatusColor = (status: string) => {
    switch (status) {
      case 'New':
        return 'white';
      case 'Cancelled':
        return 'red';
      case 'Finalized':
        return '#66FF99';
      case 'Confirmed':
        return '#3C90EE';
      case 'Released':
        return 'purple';
      case 'On Hold':
        return 'grey';
      default:
        return '';
    }
  };

  const handleSelect = () => {
    setPrevRoute(location.pathname);
    navigate(`/trip-details/${props.id}`);
  };

  return (
    <Box
      sx={{
        borderTop: '1px solid #d7d7d7',
        width: '100%',
      }}
    >
      {' '}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          paddingTop: '5px',
        }}
      >
        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
          <Checkbox
            checked={activeMissions.find(el => el.id === props.id) ? true : false}
            sx={{padding: 0}}
            size="small"
            onClick={() => handleActiveMission(props.id)}
          />
          <span
            style={{cursor: 'pointer', textDecoration: 'underline', color: 'black'}}
            onClick={() => {
              handleSelect();
            }}
          >
            {props.id}
          </span>
          <span>-</span>
          <span> {props.client?.description} </span>
        </Box>
        <Box>
          <span
            style={{
              backgroundColor: formatStatusColor(props.status),
              color: 'black',
              padding: '2px 5px',
              borderRadius: '5px',
              fontWeight: 'bold',
            }}
          >
            {props.status}
          </span>
        </Box>
      </Box>
      {props.travels.map(travel => {
        return (
          <Box>
            <Divider textAlign="left">{travel.travelDate}</Divider>
            {travel.journeys.map((journey, ind) => {
              return (
                <ListItem
                  amountOfTravelers={travel.traveler.quantity}
                  trainNumber={journey.trainNumber}
                  departureName={journey.departureName}
                  departureTime={journey.departureTime}
                  arrivalName={journey.arrivalName}
                  arrivalTime={journey.arrivalTime}
                  departureAssistanceNeeded={journey.withDepartureAssistance}
                  arrivalAssistanceNeeded={journey.withArrivalAssistance}
                  key={ind}
                />
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
