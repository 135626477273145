import {Box, Grid, Stack, styled, IconButton} from '@mui/material';
import moment from 'moment';
import React from 'react';
import MissionDetailsComponents from 'src/scenes/coordinator/MissionDetailsNew/components';
import {ReservationTypeDto} from 'src/services/models';
import {MissionDetailDto} from 'src/services/models/mission-detail-dto';
import {ReservationDto} from 'src/services/models/reservation-dto';
import {useTranslation} from 'react-i18next';
import {ArrowBack} from '@mui/icons-material';
import {SettingsContext} from 'src/context/settings/settings.store';
import {Navigate} from 'react-router-dom';
import {AssistanceType} from 'src/services/models/assistance-type';
import FullAssistanceIcon from 'src/icons/FullAssistanceIcon';
import LightAssistanceIcon from 'src/icons/LightAssistanceIcon';

const InlineText = styled('p')({
  margin: 0,
});

const ReservationChip = styled('span')({
  fontSize: '14px',
  borderRadius: '8px',
  width: '85px',
  height: '32px',
  color: 'white',
  display: 'block',
  textAlign: 'center',
  fontWeight: 'bold',
  lineHeight: '32px',
});

const FA = <FullAssistanceIcon style={{color: '#C37913', scale: '1.5', width: '34px', height: '34px'}} />;
const LA = <LightAssistanceIcon style={{color: '#2B7C2B', scale: '1.5'}} />;

export const Info: React.FC<{reservation: ReservationDto}> = ({reservation}) => {
  const {t} = useTranslation();

  const isFullAssistance =
    reservation.travels &&
    (reservation.travels
      .map(el => el.traveler?.disableds?.map(d => d.assistanceType))
      .flat()
      .includes(AssistanceType.Full) as boolean);
  const [doRedirect, setDoRedirect] = React.useState(false);
  const {prevRoute, setDoResetOfMissions} = React.useContext(SettingsContext);

  //<Comment reservation={reservation} /> <- has been implemented but should not be seen

  const getIntitialMissionForStartDate = () => {
    const firstMission = (reservation.travels && reservation.travels[0].travelDate) || undefined;
    return firstMission;
  };

  return (
    <Stack sx={{pl: 4, pr: 4, pt: 4, pb: 9, mb: 2, backgroundColor: '#EFF4F9'}} spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs>
          <h3>{t('trip-details-v2.info.reservation-details')}</h3>
        </Grid>
        <Grid item xs={3} sx={{display: 'flex', gap: '15px'}}>
          <ReservationChip
            sx={{
              backgroundColor: reservation.type === ReservationTypeDto.Disabled ? '#FFD046' : '#00A8A9',
              color: reservation.type === ReservationTypeDto.Disabled ? 'black' : 'white',
            }}
          >
            {reservation.type === ReservationTypeDto.Disabled ? 'PMR' : 'GROBO'}
          </ReservationChip>
          {isFullAssistance ? FA : LA}
        </Grid>
      </Grid>
      <Box>
        {reservation.type === ReservationTypeDto.Group && (
          <InlineText>
            <b>{reservation.status}</b>
          </InlineText>
        )}
        <InlineText>
          <b>N</b>° {reservation.displayId}
        </InlineText>
        <InlineText>
          <b>{getIntitialMissionForStartDate() ? moment(getIntitialMissionForStartDate()).format('DD/MM/YYYY') : ''}</b>
        </InlineText>
      </Box>
      <MissionDetailsComponents.ClientInformation mission={reservation as MissionDetailDto} />
      <Box height={'100%'} sx={{position: 'relative'}}>
        <IconButton
          sx={{
            height: '40px',
            backgroundColor: '#0069B4',
            color: 'white',
            position: 'absolute',
            bottom: '0px',
          }}
          onClick={() => {
            setDoResetOfMissions(false);
            setDoRedirect(true);
          }}
        >
          <ArrowBack />
        </IconButton>
        {doRedirect && <Navigate to={prevRoute} />}
      </Box>
    </Stack>
  );
};
