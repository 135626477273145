import {useTranslation} from 'react-i18next';

export const SelectStationComponent = () => {
  const {t} = useTranslation();
  return (
    <div style={{display: 'grid', placeItems: 'center', height: 'calc(100vh - 64px)'}}>
      <p className="no-station-msg" style={{fontWeight: 'bold'}}>
        {t('home.select-station-msg')}
      </p>
    </div>
  );
};
