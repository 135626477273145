import React from 'react';
import {Dialog, DialogContent, DialogProps, DialogTitle} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface IModernModal extends DialogProps {
  open: boolean;
  onClose: () => void;
  modalTitle: string;
  children: React.ReactNode;
}

export const ModernModal = ({open, onClose, modalTitle, children, ...rest}: IModernModal) => {
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle
        sx={{
          paddingTop: '25px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #B0BEC5'
        }}
      >
        <h3>{modalTitle}</h3>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
