import {Box, Button, Container} from '@mui/material';
import * as React from 'react';
import {
  CreateGroupDto,
  CreateJourneyDto,
  CreateReservationDto,
  LanguageDto,
  ReservationTypeDto,
} from 'src/services/models';
import {Reservations} from './reservations';
import {CreateMissionProps} from './types';
import './CreateMission.scss';
import {useTranslation} from 'react-i18next';
import {Assistance} from './components/Assistance';
import {GroupComposition} from './components/GroupComposition';
import {Client} from './components/Client';
import {FormValidation} from './formValdition';
import {FormDialog} from './components/FormDialog';
import {Navigate, useLocation, redirect, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {EnvironmentContext} from 'src/context/environment/environment.store';
import {NetworkContext} from 'src/context/network/network.store';
import {createValidReservation} from './createValidReservation';
import {MissionContext} from 'src/scenes/coordinator/MissionOverview/missions.store';
import {SettingsContext} from 'src/context/settings/settings.store';
import {TransportTypeDto} from 'src/services/models/transport-type-dto';

const CreateMissionNew: React.FC<CreateMissionProps> = ({variant}) => {
  const default_reservation = {
    type: variant,
    travel: {
      journeys: [
        {
          withDepartureAssistance: true,
          withArrivalAssistance: false,
          coachNumber: '',
          transportType: TransportTypeDto.Train,
        } as CreateJourneyDto,
      ],
      traveler: {
        ageCategories: [{} as CreateGroupDto],
        disableds: [{} as CreateGroupDto],
      },
    },
    client: {
      description: '',
      email: '',
      firstName: '',
      language: '' as LanguageDto,
      lastName: '',
      phoneNumber: '',
    },
    remarks: '',
  };

  const {t} = useTranslation();

  const {variables} = React.useContext(EnvironmentContext);
  const {validateNetworkCall} = React.useContext(NetworkContext);

  const {prevRoute} = React.useContext(SettingsContext);

  const {clearMissions, refetch} = React.useContext(MissionContext);

  const [reservation, setReservation] = React.useState<CreateReservationDto>(
    default_reservation as CreateReservationDto,
  );
  const [allowSubmit, setAllowSubmit] = React.useState(false);
  const [showSubmitModal, setShowSubmitModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsSubmitting(true);
    const reservationToPost = createValidReservation(reservation);
    axios
      .post(`${variables.BASE_ENDPOINT}/api/reservations`, reservationToPost)
      .then(res => {
        if (res) {
          setIsSubmitting(false);
          refetch();
          navigate(prevRoute || '/');
        }
      })
      .catch(err => {
        setIsSubmitting(false);
        validateNetworkCall(err.response.status, err.response.data);
      });
  };

  const handleClose = () => {
    setShowSubmitModal(false);
  };

  React.useEffect(() => {
    if (FormValidation(variant, reservation)) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [reservation]);

  React.useEffect(() => {
    clearMissions();
    setReservation(default_reservation);
  }, [location.pathname]);

  return (
    <Box
      sx={{
        maxHeight: '100vh',
        paddingLeft: '0px',
        paddingRight: '0px',
        overflow: 'scroll !important',
      }}
    >
      <Box sx={{marginBottom: '200px'}}>
        <div className="create-mission-subheader">
          {variant === ReservationTypeDto.Group ? t('create-grobo-mission.title') : t('create-mission.title')}
        </div>
        <div>
          <div style={{margin: '20px'}}>
            <div>
              <div className="title-img" />
              <div className="mission-title">{t('create-mission.subtitle')}</div>
              <hr />
              <Reservations reservation={reservation} setReservation={setReservation} variant={variant} />
              {variant === ReservationTypeDto.Group && (
                <>
                  <hr />
                  <div className="type-vogayuers" />
                  <div className="mission-title">{t('reservation.detail.groupInformation')}</div>
                  <hr />
                  <br />
                  <GroupComposition reservation={reservation} setReservation={setReservation} variant={variant} />
                </>
              )}
              <hr />
              <div className="type-assistance" />
              <div className="mission-title">{t('create-mission.type')}</div>
              <hr />
              <Assistance reservation={reservation} setReservation={setReservation} variant={variant} />
              <hr />
              <div className="client-header" />
              <div className="mission-title">{t('create-mission.client')}</div>
              <hr />
              <br />
              <Client reservation={reservation} setReservation={setReservation} variant={variant} />
            </div>
          </div>
        </div>
      </Box>
      <Box
        className="bottom-nav"
        sx={{
          width: '100%',
          padding: '10px 12px',
          backgroundColor: 'rgb(191, 218, 236)',
          color: 'rgb(51, 51, 51)',
          textAlign: 'center',
          lineHeight: 'normal',
          position: 'fixed',
          bottom: '0px',
          zIndex: 1,
          justifyContent: 'flex-end',
          display: 'flex',
          gap: '10px',
          right: '0px',
        }}
      >
        <Button
          disabled={!allowSubmit}
          variant="contained"
          onClick={() => {
            setShowSubmitModal(true);
          }}
        >
          {t('create-prm-mission.modal.btn-create')}
        </Button>
        <Button
          onClick={() => {
            navigate(prevRoute || '/');
          }}
          variant="text"
          sx={{color: 'black'}}
        >
          {t('create-prm-mission.modal.btn-cancel')}
        </Button>
      </Box>
      <FormDialog
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isModalVisible={showSubmitModal}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
};

export default CreateMissionNew;
