import * as React from 'react';
import {valid} from './isValidIdToken';
import Login from 'src/scenes/coordinator/Login/Login';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import axios from 'axios';
import {EnvironmentContext} from '../environment/environment.store';
//@ts-ignore
import {decode} from 'jsonwebtoken';
import {localStorageKeys} from 'src/utils/localStorageKeys';

interface IUserData {
  b_employeeid: string;
  b_firstname: string;
  b_language: string;
  b_lastname: string;
  b_mail: string;
  sub: string;
}
interface IAuthContext {
  validUser: boolean;
  userData: IUserData;
  logOut: () => void;
}

export interface IPhoneId {
  userId: string;
  phoneNumber: string;
}

export const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [validUser, setValidUser] = React.useState(false);
  const [userData, setUserData] = React.useState<IUserData>({} as IUserData);

  const {variables} = React.useContext(EnvironmentContext);

  const logOut = () => {
    window.localStorage.removeItem(variables?.ID_KEY_PATH || '');
    window.localStorage.removeItem(variables?.ACCESS_KEY_PATH || '');
    window.location.replace('/');
    setValidUser(false);
  };

  React.useEffect(() => {
    if (variables?.ID_KEY_PATH.length > 0 && variables?.ACCESS_KEY_PATH.length > 0) {
      const id_key = window.localStorage.getItem(variables?.ID_KEY_PATH || '');
      const access_key = window.localStorage.getItem(variables?.ACCESS_KEY_PATH || '');

      if (id_key && access_key && valid(id_key, access_key)) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_key}`;
        setUserData(decode(id_key));
        setValidUser(true);

        //Check for telephonenumber
        const id = decode(id_key).b_employeeid as string;
        const activePhoneNumberWithId = localStorage.getItem(localStorageKeys.PHONE_NUMBER_WITH_ID);

        if (activePhoneNumberWithId === null) {
          localStorage.setItem(localStorageKeys.PHONE_NUMBER_WITH_ID, JSON.stringify({userId: id, phoneNumber: ''}));
        } else {
          const parsed = JSON.parse(activePhoneNumberWithId) as IPhoneId;

          if (parsed.userId !== id) {
            localStorage.setItem(localStorageKeys.PHONE_NUMBER_WITH_ID, JSON.stringify({userId: id, phoneNumber: ''}));
          }
        }
      } else if (window.location.pathname !== '/') {
        window.location.replace('/');
      }
    }
  }, [variables]);

  if (validUser) {
    return <AuthContext.Provider value={{validUser, userData, logOut}}>{children}</AuthContext.Provider>;
  } else {
    return (
      <BrowserRouter>
        <Routes>
          {/* @ts-ignore */}
          <Route path="/" element={<Login variables={variables} setValidUser={setValidUser} />} />
        </Routes>
      </BrowserRouter>
    );
  }
};

export default AuthProvider;
