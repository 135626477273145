import {Chip, ListItem, ListItemAvatar, ListItemText, Typography, Tooltip} from '@mui/material';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {ChatMessageDto} from 'src/services/models/chat-message-dto';
import {handleTranslationObject} from 'src/utils/handleTranslationObject';

export const ChatMessage: React.FC<{chat: ChatMessageDto}> = ({chat}) => {
  const {i18n} = useTranslation();

  // Function to truncate location to 10 characters
  const truncateLocation = (location: string) => {
    return location.length > 10 ? `${location.substring(0, 10)}...` : location;
  };

  return (
    <ListItem sx={{alignItems: 'flex-start'}}>
      <ListItemAvatar sx={{marginRight: 2}}>
        <Chip
          sx={{
            backgroundColor: '#25336C',
            color: 'white',
            borderRadius: '5px',
            position: 'relative',
            top: '50%',
            transform: 'translateY(0%)', // Ensures vertical center alignment
          }}
          label={moment(chat.createdAt).format('HH:mm')}
        />
      </ListItemAvatar>
      <ListItemText>
        <Typography sx={{fontWeight: 'bold', color: '#798c96'}}>
          <Tooltip title={chat.location || 'ALL'} arrow>
            <span>{chat.location === null ? 'ALL' : truncateLocation(chat.location || '')}</span>
          </Tooltip>{' '}
          - {handleTranslationObject(i18n, chat.roleName || [])} - {chat.employee?.name}
        </Typography>
        <Typography>{chat.message || ''}</Typography>
      </ListItemText>
    </ListItem>
  );
};
