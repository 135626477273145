//@ts-nocheck
import Checkbox from '@mui/material/Checkbox';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {CacheContext} from 'src/context/cache/cache.store';
import DateAndTimePicker from 'src/scenes/coordinator/DateAndTimePicker';
import {FormControlActionsType} from '../formControlDispatch';
import {OverviewCheckinContext} from '../OverviewCheckin.store';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import {Autocomplete, TextField} from '@mui/material';

export const FormControls: React.FC = () => {
  const {t} = useTranslation();

  const {STATE, METHODS} = React.useContext(OverviewCheckinContext);
  const {stations} = React.useContext(CacheContext);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '8px',
      }}
    >
      <div>
        <Title
          style={{
            color: '#25336c',
            fontSize: 16,
            fontFamily: '72',
            fontWeight: '700',
            marginTop: 20,
            width: '100%',
          }}
        >
          {t('check-in.station')}
        </Title>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 120px'}}>
          <Autocomplete
            disabled={STATE.formControls.viewAllStations || STATE.formControls.loadingData}
            disableClearable
            //@ts-ignore
            options={stations
              .sort((a, b) => (a.name[2].text < b.name[2].text ? -1 : 1))
              .map(el => ({value: el.name[2].text, label: el.name[2].text}))}
            value={STATE.formControls.stationFilter}
            //@ts-ignore
            defaultValue={stations.sort((a, b) => (a.name[2].text < b.name[2].text ? -1 : 1))[0].name[2].text}
            renderInput={args => <TextField {...args} />}
            placeholder={undefined}
            onChange={(e: any) => {
              METHODS.setFormControls({type: FormControlActionsType.STATION_FILTER, payload: e.target.innerHTML});
            }}
          />
          <div>
            <Checkbox
              checked={STATE.formControls.viewAllStations}
              disabled={STATE.formControls.loadingData}
              onChange={e => {
                METHODS.setFormControls({type: FormControlActionsType.VIEW_ALL, payload: e.target.checked});
              }}
            />
            {t('check-in.overview.all')}
          </div>
        </div>
      </div>
      <div>
        <Title
          style={{
            color: '#25336c',
            fontSize: 16,
            fontFamily: '72',
            fontWeight: '700',
            marginTop: 20,
            width: '100%',
          }}
        >
          {t('check-in.overview.date')}
        </Title>
        <DateAndTimePicker
          name="date"
          value={STATE.formControls.date}
          disabled={STATE.formControls.loadingData}
          change={(_key: any) => (value: any) => {
            if (value.length > 0) {
              METHODS.setFormControls({type: FormControlActionsType.DATE, payload: value});
            }
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <p>
          <Checkbox
            checked={STATE.formControls.viewOnlyActive}
            disabled={STATE.formControls.loadingData}
            onChange={e => {
              METHODS.setFormControls({type: FormControlActionsType.VIEW_ONLY_ACTIVE, payload: e.target.checked});
              METHODS.setFormControls({type: FormControlActionsType.WITH_HISTORY, payload: !e.target.checked});
            }}
          />
          {t('check-in.overview.only-active')}
        </p>
        <p>
          <Checkbox
            checked={STATE.formControls.hideTimeRegistrations}
            disabled={STATE.formControls.loadingData}
            onChange={e => {
              METHODS.setFormControls({type: FormControlActionsType.HIDE_TIME_REG, payload: e.target.checked});
            }}
          />
          {t('check-in.overview.hide-timestamps')}
        </p>
      </div>
      <div>
        <Button
          variant="outlined"
          onClick={() => METHODS.getCheckinOverview(STATE.formControls)}
          startIcon={<SearchIcon />}
          disabled={STATE.formControls.loadingData}
        >
          {t('check-in.overview.search')}
        </Button>
      </div>
    </div>
  );
};
