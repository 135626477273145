import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { ModernModal } from "src/components/ModernModal/ModernModal";
import { EvacuationDto } from "src/services/models/evacuation-dto";
import { EvacuationVehicleTypeDto } from "src/services/models/evacuation-vehicle-type-dto";

interface CoiEvacuationMethodsDialogProps {
  isOpen: boolean;
  evacuation: EvacuationDto | undefined;
  handleClose: () => void;
  handleSubmit: (values: EvacuationDto) => void;
}



export const CoiEvacuationMethodsDialog: FC<CoiEvacuationMethodsDialogProps> = ({ isOpen, evacuation, handleSubmit, handleClose }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({...evacuation});

  const handleCloseModal = () => {
    setForm({});
    handleClose();
  }

  const handleChangeInput = (name: string, value: string | number) => {
    setForm({...form, [name]: value});
  }

  const handleSubmitForm = () => {
    handleSubmit({ transportId: evacuation?.transportId, ...form });
  }

  return (
    <ModernModal open={isOpen} onClose={handleCloseModal} modalTitle={t("coi.incident-evacuation-methods")}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, paddingTop: '20px' }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="vehicle-type-label">{t("coi.incident-emergency-vehicle")}</InputLabel>
            <Select
              labelId="vehicle-type-label"
              sx={{ marginBottom: '10px' }}
              value={form.type}
              defaultValue={evacuation?.type}
              name="type"
              id="vehicle-type"
              label={t("coi.incident-emergency-vehicle")}
              onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
            >
              {Object.values(EvacuationVehicleTypeDto).map(type => (
                <MenuItem value={type} key={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="transportId"
            name="transportId"
            defaultValue={evacuation?.transportId}
            label={`${form.type || evacuation?.type} ID`}
            value={form.transportId}
            onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
          />
          <Typography sx={{ fontWeight: 'bold' }}>{t('coi.arrival')}</Typography>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            value={form.arrivalTime || evacuation?.arrivalTime}
            label={t("coi.arrival-time")}
            onChange={(e) => {
              if (e) {
                handleChangeInput("arrivalTime", e.toISOString())}
              }
            }
          />
          <Typography sx={{ fontWeight: 'bold' }}>{t('coi.incident-evacuation')}</Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              value={form.startTime || evacuation?.startTime}
              label={t("coi.start-time")}
              onChange={(e) => {
                if (e) {
                  handleChangeInput("startTime", e.toISOString())}
                }
              }
            />
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              value={form.endTime || evacuation?.endTime}
              label={t("coi.end-time")}
              onChange={(e) => {
                if (e) {
                  handleChangeInput("endTime", e.toISOString())}
                }
              }
            />
          </Box>
          <Typography sx={{ fontWeight: 'bold' }}>{t('coi.departure')}</Typography>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            value={form.departureTime || evacuation?.departureTime}
            label={t("coi.departure-time")}
            onChange={(e) => {
              if (e) {
                handleChangeInput("departureTime", e.toISOString())}
              }
            }
          />
          <TextField
            sx={{ marginTop: '10px' }}
            id="numberOfPassengers"
            type="number"
            name="numberOfPassengers"
            defaultValue={evacuation?.numberOfPassengers}
            value={form.numberOfPassengers}
            label={t("coi.number-of-passengers")}
            onChange={(e) => handleChangeInput(e.target.name, parseInt(e.target.value))}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", marginTop: '10px' }}>
          <Button sx={{ height: '50px', padding: '0 20px' }} onClick={handleCloseModal}>{t("station-section.btn-cancel")}</Button>
          <Button sx={{ height: '50px', padding: '0 20px' }} variant="contained" onClick={handleSubmitForm}>{t("filter-section.filter-update")}</Button>
        </Box>
      </Box>
    </ModernModal>
  )
}
